import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    isHighlighted: false,
    highlightItem: false,
    isOpen: false,
    slideIndex: false
};

const drawerReducer = (state = initialState, action) => {

    switch(action.type) {
        case actionTypes.HIGHLIGHT:
          return updateObject(state, 
            {
                isHighlighted: action.payload.isHighlighted, 
                highlightItem: action.payload.highlightItem
            });
        case actionTypes.TRIGGER_DRAWER:
            return updateObject(state, {isOpen: action.payload});
        case actionTypes.SET_SLIDE_INDEX:
            return updateObject(state, 
                {
                    slideIndex: action.payload.slideIndex
                });
        default:
          return state;
      }
    
};

export default drawerReducer;