import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';


const initialState = {
  isOpen: false,
};

const navReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.TRIGGER_NAV:
    return updateObject(state, { isOpen: action.payload });
    default:
    return state;
  }
};

export default navReducer;
