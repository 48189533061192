import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';

import styled from 'styled-components';

const StyledAboutWrapper = styled.div`
  max-width: 600px;
  position: relative;
  padding: 2rem 8rem 4rem;
  background: ${props => props.theme.colors.blackOverlayHeavy};
  margin: 0 auto;
  top: 0;

  ${props => props.theme.media.small`
    padding: 2rem 2rem 4rem;
    width: 80%;
  `}
  ${props => props.theme.media.medium`
    padding: 2rem 4rem 4rem;
    width: calc(80% - 8rem);
  `}
  ${props => props.theme.media.large`
    padding: 2rem 8rem 4rem;
    width: calc(80% - 16rem);
  `}

  h1 {
    color: ${props => props.theme.colors.white};
    line-height: 1;
    margin-top: 0;
  }

  p {
    color: ${props => props.theme.colors.white};

    ${props => props.theme.media.small`
      font-size: 14px;
    `}
    ${props => props.theme.media.medium`
      font-size: 16px;
    `}
    ${props => props.theme.media.large`
      font-size: 16px;
    `}
  }
`;

const StyledButton = styled.button`
  border:none;
  color: ${props => props.theme.colors.white};
  background: none;
  font-weight:bold;
  font-size: 2rem;
  cursor:pointer;
  position:absolute;

  ${props => props.theme.media.small`
    top: 1rem;
    right: 1rem;
  `};

  ${props => props.theme.media.medium`
    top: auto;
    right: 2rem;
  `};

  ${props => props.theme.media.large`
    top: auto;
    right: 2rem;
  `};
`;

class About extends Component {

  closeModalHandler = () => {
    this.props.onTriggerModal(false);
  }

  render () {
    return(
      <StyledAboutWrapper>
          <StyledButton onClick={this.closeModalHandler}>X</StyledButton>
          <h1>About This Project</h1>
	<p>What makes an effective issue advocacy campaign? This work is intended to answer this question and identify the factors that lead to success. The goal was to discern patterns in successful campaigns and identify best practices, trends, and lessons learned that could be adapted and applied to other issue areas. This research focused on eight recent campaigns from which common themes emerged.</p>
	<p>This website provides excerpts from each of the eight campaigns combined with what was considered their most impactful 'component' - the elements that together create a successful campaign. Those components identified in this report are vision, leadership, structure, timing, framing, adaptability, power and pressure. </p>
      </StyledAboutWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
      modal: state.modal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTriggerModal: (payload) => dispatch(actionCreators.triggerModal(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(About);
