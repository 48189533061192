/*
 * Link Replacement Functional Component
 * This Component replaces all content links with html links.
 */
import React, { Component } from 'react';
import reactStringReplace from 'react-string-replace';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import fonts from '../../styles/Fonts.js';
import { CONSTRUCTS } from '../../data/data';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';


const StyledTo = styled(Link)`
    color: ${props => props.textcolor === "true" ? props.theme.colors.white : props.theme.colors.black };
    margin: 0;
    transition: color 0.3s;
  
    &:hover,
    &:focus {
      color: ${props => props.textcolor === "true" ? props.theme.colors.yellow : props.theme.colors.blackHover};
    }
  
    &:active {
        color: ${props => props.textcolor === "true" ? props.theme.colors.yellowActive : props.theme.colors.blackActive};
    }
  `;

const StyledLink = styled.a`
  color: ${props => props.textcolor === "true" ? props.theme.colors.white : props.theme.colors.black };
  margin: 0;
  transition: color 0.3s;

  &:hover,
  &:focus {
    color: ${props => props.textcolor === "true" ? props.theme.colors.yellow : props.theme.colors.blackHover};
  }

  &:active {
      color: ${props => props.textcolor === "true" ? props.theme.colors.yellowActive : props.theme.colors.blackActive};
  }
`;

const StyledHighlightButton = styled.button`
    color: ${props => props.color};
    background: ${props => props.theme.colors.grayDark};
    border: none;
    color: white;
    font-size: 1.1rem;
    margin: 0;
    padding: .25rem .5rem .5rem;
    font-weight:bold;
    font-family: ${props => fonts.body};
    cursor: pointer;
    transition: all .3s ease-in-out;
    display:inline-block;

    &:hover {
        transform: scale(1.2);
    }

    ${({ highlighted }) => highlighted && `
        transform: scale(1.2);
    `}
`;


class LinkReplacements extends Component {
    render() {
        let originalText = this.props.text;
        let replacedText = originalText;
        let replacements = false;
        let i = 0;
        let color = this.props.textcolor === 'white' ? "true" : "false";
        var constructs = { ...CONSTRUCTS };

        if(this.props.replacements) {
            // Iterate over each paragraph, replacing all instances of each highlighted word
            // with the proper button version.
            Object.keys(this.props.replacements).forEach((replaceKey) => {
                replacedText = reactStringReplace(replacedText, replaceKey, () => (

                    this.props.replacements[replaceKey].new === true ?
                        <StyledLink key={i++} textcolor={color} href={this.props.replacements[replaceKey].link} target="_BLANK">{this.props.replacements[replaceKey].text}</StyledLink>
                        :
                        <StyledTo key={i++} textcolor={color} to={this.props.replacements[replaceKey].link}>{this.props.replacements[replaceKey].text}</StyledTo>
                ));
            });
            replacements = true;
        }

        if(this.props.highlights) {
            // Iterate over each paragraph, replacing all instances of each highlighted word
            // with the proper button version.
            Object.keys(this.props.highlights).forEach((replaceKey) => {
                var replaceOutput = replaceKey;
                replaceOutput = replaceOutput.replace('[[', '');
                replaceOutput = replaceOutput.replace(']]', '');

                replacedText = reactStringReplace(replacedText, replaceKey, () => (
                    <StyledHighlightButton 
                        highlighted={this.props.drawer.isHighlighted === true && this.props.drawer.highlightItem === this.props.highlights[replaceKey]} 
                        key={`${this.props.highlights[replaceKey]}_${Math.floor(Math.random() * 20)}`} 
                        onClick={
                            () => {
                                this.props.onTriggerDrawer(this.props.drawer.isOpen);
                                this.props.onHighlight({isHighlighted: !this.props.drawer.isHighlighted, highlightItem: this.props.highlights[replaceKey]});
                                this.props.onSetSlideIndex({slideIndex: constructs[this.props.highlights[replaceKey]].slideIndex});
                            }
                        }>
                        {replaceOutput}
                    </StyledHighlightButton>
                ));
            });

            replacements = true;
        }

        const outputText = (replacements === true) ? replacedText : originalText;

        return (
            Object.keys(outputText).map((key) => {
                return outputText[key]
            })
        )
    }
}

const mapStateToProps = state => {
    return {
        drawer: state.drawer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTriggerDrawer: (payload) => dispatch(actionCreators.triggerDrawer(!payload)),
        onHighlight: (payload) => dispatch(actionCreators.highlight(payload)),
        onSetSlideIndex: (payload) => dispatch(actionCreators.setSlideIndex(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkReplacements);
