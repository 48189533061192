import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import colors from '../../../styles/Colors';
import { Link } from 'react-router-dom';

const StyledButton = styled(Link)`
  width: 100%;
  max-width: ${props => {
    if (props.size === 'large') {
      return '200px';
    } else {
      return '150px';
    }
    }};
  display: block;
  padding: 0.5rem 2rem;
  background: none;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  backface-visibility: hidden;
  color: ${props => props.theme.colors.white};
  transition: background-color 0.3s, color 0.3s;
  text-transform: uppercase;
  outline: none;
  letter-spacing: 0.1rem;
  cursor: pointer;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  border: 0;
  text-decoration: none;

  span {
    top: 2px;
    position: relative; 
  }

  &:hover,
  &:focus {
    background: none;
    color: ${props => props.theme.colors.white};
  }


  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: inherit;
    z-index: -1;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1)
  }

  &::before {
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    opacity: 0.2;
    transform: scale3d(0.7, 1, 1);
    transition: transform 0.3s, opacity 0.3s, border 0.3s;
    background-color: ${props => props.color ? props.theme.colors[`${props.color}Light`] : props.theme.colors.redLight};
  }

  &::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale3d(1.1, 1, 1);
    transition: transform 0.3s, background-color 0.3s;
    background-color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.red};
  }

  &:hover::before {
    transform: scale3d(1, 1, 1);
    opacity: 1;
    background: none;
    border: 2px solid ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.red};
  }

  &:hover::after {
    transform: scale3d(1, 1, 1);
    background: none;
  }

  ${props => {
    if (props.type === 'ghost') {
      return `
      &::before {
        transform: scale3d(1, 1, 1);
        opacity: 0.2;
        background: none;
        transition: transform 0.3s, opacity 0.3s, border 0.3s;
        background-color: transparent;
      }

      &::after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale3d(1.1, 1, 1);
        transition: transform 0.3s, background-color 0.3s;
        background-color: transparent;
        border: 2px solid ${props.color ? props.theme.colors[props.color] : props.theme.colors.red};

      }

      &:hover::after
      &:focus::after {
        background-color: ${props.color ? props.theme.colors[props.color] : props.theme.colors.red};
      }

      &:hover::before,
      &:focus::before {
        top: -4px;
        bottom: -4px;
        left: -4px;
        right: -4px;
        opacity: 0.2;
        transform: scale3d(0.7, 1, 1);
        background-color: ${props.color ? props.theme.colors[`${props.color}Light`] : props.theme.colors.redLight};
        border: 0;
      }
      `;
    }
  }};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      background: none;
      color: ${props => props.theme.colors.white};
    }

    &:hover::before,
    &:hover::after {
      content: none;
    }
  }
`;

const StyledSpan = styled.span`
  backface-visibility: hidden;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-family: ${props => props.theme.fonts.header};
  display: block;
  transition: letter-spacing 0.3s, color 0.3s;
  text-rendering: optimizeLegibility;
`;

const buttonLink = (props) => {
  return(
    <StyledButton
      to={props.to}
      className={props.className}
      onClick={props.clicked}
      type={props.type ? props.type : 'fill'}
      size={props.size ? props.size : 'normal'}
      color={props.color}
      disabled={props.disabled}>
      <StyledSpan>
        {props.label}
      </StyledSpan>
    </StyledButton>
  );
}

buttonLink.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['ghost', 'fill']),
  size: PropTypes.oneOf(['normal', 'large']),
  color: PropTypes.oneOf(Object.keys(colors)),
};

export default buttonLink;
