/*
 * MenuToggle Functional Component
 * This component controls the toggle of the Menu State
 */
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const StyledButton = styled.button`
  border: none;
  background: none;
  top: 3px;
  position:relative;

  svg {
    height: 1.5rem !important;
    width: 1.5rem !important;
    transition: .3s all ease-in-out;
    cursor: pointer;
    
    path {
      transition: .3s all ease-in-out;
      fill: ${props => props.theme.colors.white};
    }
    &:hover path {
      fill: ${props => props.theme.colors.yellow};
    }
    &:active path, &.active path {
      fill: ${props => props.theme.colors.yellowActive};
    }

  }
`;

class MenuToggle extends Component {
  render() {
    return (
      <StyledButton>

        {this.props.menuToggle.menuToggle === "closed" ? 
          <FontAwesomeIcon icon={['fas', 'bars']} onClick={()=> this.props.onMenuToggle("open")} />
          :
          <FontAwesomeIcon icon={['fas', 'times']} onClick={()=> this.props.onMenuToggle("closed")} />
        }
      </StyledButton>
    )
  }
}

const mapStateToProps = state => {
  return {
    menuToggle: state.menuToggle,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onMenuToggle: (payload) => dispatch(actionCreators.menuToggle(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuToggle);