/*
 * App Colors
 */

const colors = {
    black: '#000000',
    blackHover: '#5c5c5c',
    blackLight: 'rgba(000, 000, 000, .4)',
    blackActive: '#6d6d6d',
    blackOverlay: 'rgba(000, 000, 000, 0.70)',
    blackOverlayHeavy: 'rgba(000, 000, 000, 0.85)',
    shadow: 'rgba(000, 000, 000, 0.75)',
    white: '#FFFFFF',
    whiteLight: '#f1eaea',
    gray: '#e3e3e3',
    grayHover: '#e4e4e4',
    grayActive: '#d7d7d7',
    grayDark: '#1C1C1C',
    red: '#E5414E',
    redTransparent: 'rgba(229, 65, 78, .8)',
    redLight: '#ec6771',
    green: '#21986A',
    greenLight: '#41d69d',
    greenTransparent: 'rgba(33,152,106, .8)',
    purple: '#961AC4',
    purpleLight: '#bc39ed',
    purpleTransparent: 'rgba(150, 26, 196, .8)',
    blue: '#2D9CBE',
    blueLight: '#4abbde',
    blueTransparent: 'rgba(45, 156, 190, .8)',
    yellow: '#E6A40F',
    yellowActive: '#F5C863',
    yellowLight: '#f6c557',
    yellowTransparent: 'rgba(230, 164, 15, .8)',
  }

  export default colors;
