/*
 * Constructs Stateful Component
 * This Component controls the home page and passes it's state down to the
 * components rendered on the page.
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { CONSTRUCTS } from '../../data/data';
import ConstructsCard from './ConstructsCard.js';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SliderArrow from '../../components/Arrow/SliderArrow.js';
import { Spring } from 'react-spring/renderprops';


const CONSTRUCT_DATA = Object.keys(CONSTRUCTS).map((key) => {
  return {
      id: CONSTRUCTS[key].id,
      key: key,
      title: CONSTRUCTS[key].title,
      content: CONSTRUCTS[key].content,
      button1Title: CONSTRUCTS[key].button1Title,
      button1Link: CONSTRUCTS[key].button1Link,
      button2Title: CONSTRUCTS[key].button2Title,
      button2Link: CONSTRUCTS[key].button2Link
  };
});

const StyledConstructsWrapper = styled.div`
  z-index: 3;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transform: translate3d(0, -100%, 0);
`;

const StyledConstructsInner = styled.div`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  width: 100%;
  display:flex;
  border-bottom: 2px solid ${props => props.theme.colors.white};
`;

const StyledMenuItemWrapper = styled.div`
  position: relative;
  display: flex;
  opacity: ${props => props.show ? '1' : '0'};
  flex-flow: row;
  justify-content: flex-end;
  max-width: ${props => props.theme.container};
  margin: auto;
  transition: ${props => props.show ? 'none' : 'opacity .3s ease-in-out'};
`;

const StyledMenuItemInner = styled.div`
  max-width: 150px;
  padding: 0.5rem;
  background-color: ${props => props.theme.colors.red};

  span::before,
  span::after {
    content: '';
    position: absolute;
    bottom: 0.1rem;
    width: 50%;
    height: 2px;
    margin: 5px 0 0;
    transition: all 0.4s ease-in-out;
    opacity: 1;
    color: ${props => props.theme.colors.yellowActive};
}  
  
  &:hover {
    span {
      transition: color 0.4s;
      color: ${props => props.theme.colors.yellow};

      &::before,
      &::after {
        content: '';
        position: absolute;
        bottom: 0.1rem;
        width: 50%;
        height: 2px;
        margin: 5px 0 0;
        transition: all 0.4s ease-in-out;
        opacity: 1;
        color: ${props => props.theme.colors.yellowActive};
      }
    }
    a:before {
      color: ${props => props.theme.colors.yellowActive};
      transition: color 0.4s;
    }
  }

  a:before {
    transform: rotate(315deg);
    color: ${props => props.theme.colors.yellowActive};
    top: calc(50% - 0.25rem);
  }
  span {
    color: ${props => props.theme.colors.yellowActive};
  }
`;

const StyledCarousel = styled.div`
  width: 100%;
  display: block;
  position: relative;
  margin: 0 auto;

  .slick-track {
    display:flex;
    flex-direction:row;
  }

  .slick-slide > div {
    border-right: 2px solid ${props => props.theme.colors.white};
  }
  .slick-slide:first-child > div {
    border-left: 2px solid ${props => props.theme.colors.white};
  }

  .slick-slide {
    height: 100%;
  }

  .slick-slider {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-flow: row wrap;

    ${props => props.theme.media.medium`
      height: 100%;
    `}

    ${props => props.theme.media.large`
      height: auto;
    `}
  }

  .slick-list {
    width: 100%;
  }

  .slick-prev,
  .slick-next {
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:10;
    top: 90%;

    ${props => props.theme.media.medium`
      top: 65%;
    `}

    ${props => props.theme.media.large`
      display: block;
      top: 75%;
    `}
  }

  .slick-disabled {
    opacity: 0;
  }

  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }

  .slick-prev::before,
  .slick-next::before {
    display: none;
    content: none;
  }

  .slick-dots {
    z-index: 2;
    position: relative;

    ${props => props.theme.media.large`
      bottom: 0;
    `}
  }

  .slick-dots li button {
    width: 15px;
    height: 15px;
  }

  .slick-dots li button::before {
    color: ${props => props.theme.colors.white};
    width: 15px;
    height: 15px;
    border: 1px solid ${props => props.theme.colors.white};
    border-radius: 50%;
    content: '';
    transition: border 0.3s, opacity 0.3s;
  }

  .slick-dots li button:hover::before,
  .slick-dots li button:focus::before {
    color: ${props => props.theme.colors.grayHover};
    border-color: ${props => props.theme.colors.grayHover};
  }

  .slick-dots li button:active::before {
    color: ${props => props.theme.colors.grayActive};
    border-color: ${props => props.theme.colors.grayActive};
  }

  .slick-dots li.slick-active button::before {
    color: ${props => props.theme.colors.green};
    border-color: ${props => props.theme.colors.green};
  }

  .slick-dots li.slick-active button:hover::before,
  .slick-dots li.slick-active button:active::before {
    color: ${props => props.theme.colors.greenHover};
    border-color: ${props => props.theme.colors.greenHover};
  }

  .slick-dots li.slick-active button:active::before {
    color: ${props => props.theme.colors.greenActive};
    border-color: ${props => props.theme.colors.greenActive};
  }
`;

const NextArrow = ( props ) => {
  const { className, onClick } = props;
  return(
    <SliderArrow
      className={className}
      clicked={onClick} />
  );
}

const PrevArrow = ( props ) => {
  const { className, onClick } = props;
  return(
      <SliderArrow
        className={className}
        clicked={onClick}
        direction={'prev'} />
  );
};

class Constructs extends Component {
  componentDidUpdate() {
    if(this.props.drawer.slideIndex !== false) {
      this.slider.slickGoTo(this.props.drawer.slideIndex)
    }
  }
  
  render() {
  
    const settings = {
      dots: false,
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      centerMode: false,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1550,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1150,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
  };
      
    return (
      <Spring 
        show={this.props.show}
        from={{
          transform: this.props.show ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)',
        }}
        to={{
          transform: this.props.show ? 'translate3d(0, 0, 0)' : 'translate3d(0, -100%, 0)',
        }}
      >
        {styles => (
          <StyledConstructsWrapper style={{...styles}}>
            <StyledConstructsInner>
              <StyledCarousel>
                <Slider {...settings} ref={slider => (this.slider = slider)}>
                  {CONSTRUCT_DATA.map((item) => (
                    <ConstructsCard
                      key={item.key}
                      title={item.title}
                      content={item.content}
                      button1Title={item.button1Title}
                      button1Link={item.button1Link}
                      button2Title={item.button2Title}
                      button2Link={item.button2Link} 
                      isHighlighted={this.props.drawer.highlightItem === item.key}
                      />
                  ))}
                </Slider>
              </StyledCarousel>
            </StyledConstructsInner>
            <StyledMenuItemWrapper show={this.props.show} >
              <StyledMenuItemInner>
                {this.props.children}
              </StyledMenuItemInner>
            </StyledMenuItemWrapper>
        </StyledConstructsWrapper>
      )}
      </Spring>
    );
  }
}

const mapStateToProps = state => {
  return {
      drawer: state.drawer
  };
};

export default connect(mapStateToProps)(Constructs);
