/*
 * Campaigns Stateful Component
 * This Component controls the home page and passes it's state down to the
 * components rendered on the page.
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import Button from '../../components/UI/Button/Button.js';
import DrawerContent from '../../components/RallyComponents/DrawerContent.js';
import {Spring, animated} from 'react-spring/renderprops';
import LinkReplacements from '../../components/RallyComponents/LinkReplacements.js';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { RALLY_COMPONENTS } from '../../data/data';
import colors from '../../styles/Colors.js';

const StyledRallyComponents = styled.div`
  ${props => {
    if (props.animationState === 'leave') {
      return `
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `;
    }
  }};
`;

const StyledContainer = styled.section`
  color: ${props => props.textColor};
`;

const StyledOuterSection = styled.div`
  background-color: ${props => props.backgroundColor};
  max-width: 50%;
  min-height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
`;

const StyledInnerSection = styled.div`
  height: 100%;
  position: relative;
  z-index: 5;
  min-height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  letter-spacing: 1.8px;
  line-height: 1.4;

  ${props => props.theme.media.medium`
    max-width: 100%;
    padding-bottom: 2rem;
  `}
  ${props => props.theme.media.large`
    max-width: 50%;
    height: ${props =>props.minHeight}px;
    padding-bottom: 1rem;
  `}
`;

const StyledInnerHalf = styled.div`
  width: calc(100% - 7rem);
  left: 0;
  position: relative;
  padding: 0 3rem 6rem 4rem;
`;

const StyledHeader = styled.h1`
  margin: 6rem 0 0 0;
`;

const StyledRallyComponentButton = styled.div`
  display:inline-block;
  margin-right: 2rem;
  margin-top: 1rem;

  button {
    max-width: 275px;
    color: white;

    &.active {
      color: ${props => props.textcolor};
      background-color: white;
      &:hover:before {
        top: 0;
      }
      &:after {
        background-color:white;
      }
    }
  }
`;

const StyledRallyComponentSlide = styled(animated.div)`
  border: 2px solid ${props => props.backgroundcolor};
  position: absolute;
  width: 100%;
  z-index: 1;
  padding: 2rem 2rem 3rem 3rem;
  letter-spacing: 1.8px;
  line-height: 1.4;

  h3 {
    margin-top: 0;
  }

  a {
    width: auto;
    display: inline-block;
    max-width: 100%;
  }

  p {
    font-size: 14px;
  }
  ${props => props.theme.media.small`
    max-width: calc(100% - 5rem - 4px);
    left: 0;
    transform:translate3d(0, -100%, 0);
    border-left: 2px solid ${props => props.backgroundcolor};
    top: 0;
  `}
  ${props => props.theme.media.medium`
    max-width: calc(100% - 5rem - 4px);
    left: 0;
    transform:translate3d(0, -100%, 0);
    border-left: 2px solid ${props => props.backgroundcolor};
    top: 0;
  `}
  ${props => props.theme.media.large`
    max-width: calc(50% - 7rem);
    left: 50%;
    transform:translate3d(-100%, 0, 0);
    border-left: 0;
    top: 3rem;
  `}
`;

class Components extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rallyComponentData: RALLY_COMPONENTS[window.location.pathname],
      drawerOpen: false,
      drawerOpen0: false,
      drawerOpen1: false,
      drawerToOpen: false,
      drawerSwap: false,
      startContainer: 0,
      minContainerHeight: 0
    }
  }

  setShow = (showId) => {
    var drawerData = {};
    
    if(this.state.drawerOpen === false || showId !== this.state.drawerToOpen) {
      drawerData.drawerSwap = (this.state.drawerOpen === true && showId !== this.state.drawerToOpen) ? true : false;
      drawerData.drawerOpen0 = (showId === 0) ? true : false;
      drawerData.drawerOpen1 = (showId === 1) ? true : false;
      drawerData.drawerOpen = true;
      drawerData.drawerToOpen = showId;
    } else {
        drawerData.drawerOpen = false;
        drawerData.drawerOpen0 = false;
        drawerData.drawerOpen1 = false;
        drawerData.drawerToOpen = false;
        drawerData.drawerSwap = false;
    }

    this.setState({
      drawerOpen: drawerData.drawerOpen,
      drawerOpen0: drawerData.drawerOpen0,
      drawerOpen1: drawerData.drawerOpen1,
      drawerToOpen: drawerData.drawerToOpen,
      drawerSwap: drawerData.drawerSwap
    })

    if(this.props.device !== 'desktop' && drawerData.drawerOpen === true) {
      const delayTime = drawerData.drawerSwap === true ? 1500 : 500;
      setTimeout(this.scrollToDrawer, delayTime);
    }
  }

  scrollToDrawer() {
    const height = document.getElementById('innerContainer').clientHeight;
    window.scrollTo({
      top: height,
      behavior: 'smooth',
    });

  }

  heightCalc = () => {

    let containerHeight = 0;
    let minContainerHeight = 0;

    if(!this.state.startContainer === 0) {
      containerHeight = this.outerSectionHeight.clientHeight;
    } else {
      containerHeight = this.state.startContainer;
    }
    
    let drawer1Height = this.drawer1Height.clientHeight;
    let drawer2Height = this.drawer2Height.clientHeight;

    minContainerHeight = (containerHeight > drawer1Height) ? containerHeight : drawer1Height;
    minContainerHeight = (minContainerHeight > drawer2Height) ? minContainerHeight : drawer2Height;

    this.setState({
      minContainerHeight: minContainerHeight + 75
    });    
  }

  componentDidMount() {
    this.heightCalc();

    window.addEventListener("resize", this.heightCalc);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.heightCalc);
  }

  render() {
    if(window.location.pathname === '/components') {
      return <Redirect to="/" component="Home" />
    }
    
      let i = 0;
      return (
        <StyledRallyComponents animationState={this.props.animationState}>          
          <Helmet title={`Component | ${this.state.rallyComponentData.title}`} />
          <StyledContainer
            color={this.state.rallyComponentData.color} textColor={this.state.rallyComponentData.textColor}
          >
            <StyledOuterSection backgroundColor={colors[this.state.rallyComponentData.color]}>
            </StyledOuterSection>
            <StyledInnerSection id="innerContainer" backgroundColor={colors[this.state.rallyComponentData.color]} minHeight={this.state.minContainerHeight} ref={ (outerSectionHeight) => this.outerSectionHeight = outerSectionHeight}>
              <StyledInnerHalf>
                <StyledHeader>{this.state.rallyComponentData.title}</StyledHeader>
                    {
                      this.state.rallyComponentData.content.map((item) => {
                        return <p key={i++}><LinkReplacements text={item} highlights={this.state.rallyComponentData.contentHighlights} replacements={this.state.rallyComponentData.contentLinks} textcolor={this.state.rallyComponentData.textColor} /></p>
                      })
                    }
                {this.state.rallyComponentData.sideContent.map((item) => (
                  <StyledRallyComponentButton 
                    key={item.id}                       
                    textcolor={colors[this.state.rallyComponentData.color]}
                  >
                    <Button
                      id={item.id}
                      label={item.title}
                      type="ghost"
                      color="white"
                      clicked={
                        () => {
                          if(this.props.device === 'desktop') {
                            window.scrollTo(0, 0);                            
                          }
                          this.setShow(item.id)
                        }
                      }
                      className={this.state.drawerToOpen === item.id ? 'active' : 'non-active'}
                    />
                  </StyledRallyComponentButton>
                ))}
              </StyledInnerHalf>
            </StyledInnerSection>

            <Spring 
              delay={
                this.state.drawerOpen === true && this.state.drawerToOpen === 0 && this.state.drawerSwap === true ? 1000 : 0
              }
              to={{
                transform: 
                  this.state.drawerOpen0 ?
                    'translate3d(0, 0, 0)'
                  :
                    this.props.device === 'desktop' ?
                      'translate3d(-100%, 0, 0)'
                    :
                      'translate3d(0, -100%, 0)',
                position: 
                  this.state.drawerOpen0 ?
                    this.props.device === 'desktop' ?
                      'absolute'
                    :
                      'relative'
                  :
                      'absolute'
              }}
              from={{
                transform: this.props.device === 'desktop' ? 'translate3d(-100%, 0, 0)' : 'translate3d(0, -100%, 0)',
                position: 'absolute'
              }}>
              {props => (
                <StyledRallyComponentSlide style={props} backgroundcolor={colors[this.state.rallyComponentData.color]} ref={ (drawer1Height) => this.drawer1Height = drawer1Height}>
                  <DrawerContent data={this.state.rallyComponentData.sideContent[0]} replacements={this.state.rallyComponentData.contentLinks} highlights={this.state.rallyComponentData.contentHighlights} />
                </StyledRallyComponentSlide>
              )}
            </Spring>
            <Spring 
              delay={
                this.state.drawerOpen === true && this.state.drawerToOpen === 1 && this.state.drawerSwap === true ? 1000 : 0
              }
              to={{
                transform: 
                  this.state.drawerOpen1 ?
                    'translate3d(0, 0, 0)'
                  :
                    this.props.device === 'desktop' ?
                      'translate3d(-100%, 0, 0)'
                    :
                      'translate3d(0, -100%, 0)',
                position: 
                this.state.drawerOpen1 ?
                  this.props.device === 'desktop' ?
                    'absolute'
                  :
                    'relative'
                :
                    'absolute'
              }}
              from={{
                transform: this.props.device === 'desktop' ? 'translate3d(-100%, 0, 0)' : 'translate3d(0, -100%, 0)',
                position: 'absolute'
              }}>
              {props => (
                  <StyledRallyComponentSlide style={props} backgroundcolor={colors[this.state.rallyComponentData.color]} ref={ (drawer2Height) => this.drawer2Height = drawer2Height}>
                    <DrawerContent data={this.state.rallyComponentData.sideContent[1]} replacements={this.state.rallyComponentData.contentLinks} highlights={this.state.rallyComponentData.contentHighlights}/>
                  </StyledRallyComponentSlide>   
              )}
            </Spring>
          </StyledContainer>
        </StyledRallyComponents>
      );
  }
}

function mapStateToProps(state) {
  return { device: state.device.deviceWindow };
}

export default connect(mapStateToProps)(Components)
