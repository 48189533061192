import React from 'react';
import styled from 'styled-components';

const StyledBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 400;
  display: inline;
  opacity: ${props => props.show ? 1 : 0};
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  transition: all 0.7s cubic-bezier(0.4, 0, 0.2, 1);
  top: calc(${props => props.scrollTop}px);

  ${props => props.theme.media.medium`
    top: calc(${props => props.scrollTop}px);
  `};

  ${props => props.theme.media.large`
    top: calc(${props => props.scrollTop}px);
  `};
`;

const backdrop = React.memo((props) => {
  return(
    <StyledBackdrop
      zindex={props.zindex}
      onClick={props.clicked}
      show={props.show}
      scrollTop={props.scrollTop}
      className={props.className} />
  );
});

export default backdrop;
