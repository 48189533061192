import React from 'react';
import Button from '../UI/Button/Button';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const StyledCTA = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 2rem 1rem 0 0;
  text-align: center;
  height: 266px;
  width: 266px;
  position: relative;
  overflow: hidden;
  padding: 0 1rem;

  button {
    color: ${props => props.theme.colors.white};
  }

`;

const StyledBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

const StyledContent = styled.div`
  background-color: ${props => props.theme.colors.blackOverlay};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
`;

const StyledTitle = styled.h3`
  color: ${props => props.theme.colors.white};
  width: 100%;
  margin: 0;
`;

const StyledDescription = styled.p`
  color: ${props => props.theme.colors.white};
  width: 100%;
  margin: 0 0 2rem 0;
`;
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 15}px,${y / 15}px,0)`;

const campaignBottomVideo = (props) => {

    const [offsetAnimation, set] = useSpring(() => ({
        xy: [0, 0],
        config: {
          mass: 10,
          tension: 550,
          friction: 140 }
        }));
        return(
        <StyledCTA
            onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
            onMouseOut={() => set({xy: [0, 0]})}
            style={{ transform: offsetAnimation.xy.interpolate(trans1) }}
        >
            <StyledBackgroundImage src={props.data.backgroundImage} />
            <StyledContent>
                <StyledTitle>{props.data.title}</StyledTitle>
                <StyledDescription>{props.data.description}</StyledDescription>
                <Button
                    label={props.data.buttonLabel}
                    clicked={() => props.clickedVideo(props.data.videoUrl)} />
            </StyledContent>
        </StyledCTA>
    );
}

export default campaignBottomVideo;
