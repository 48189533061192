import React from 'react';
import styled from 'styled-components';

const StyledCampaignHalfMedia = styled.div`
  width: 100%;
  margin: 1rem 0.5rem;
  position: relative;
  order: 2;

  ${props => props.theme.media.large`
    width: calc(50% - 4rem);
    margin: 2rem;
    order: ${props => props.position === 'left' ? '1' : '2'};
  `}
`;

const campaignHalfMedia = (props) => {
  return (
    <StyledCampaignHalfMedia position={props.position}>
      {props.children}
    </StyledCampaignHalfMedia>
  );
}

export default campaignHalfMedia;
