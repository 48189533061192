/*
 * Home Stateful Component
 * This Component controls the home page and passes it's state down to the
 * components rendered on the page.
 */

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { animated, Spring } from 'react-spring/renderprops';
import styled from 'styled-components';

import * as actionCreators from '../../store/actions';
import { CAMPAIGNS, HOME } from '../../data/data';
import CardNavigation from '../../components/CardNavigation/CardNavigation';
import VideoPanels from '../../components/Home/VideoPanels';
import Modal from '../../components/UI/Modal/Modal';
import VideoPlayer from '../../components/Video/VideoPlayer';

const CARD_DATA = Object.keys(CAMPAIGNS).map((key) => {
    return {
        id: CAMPAIGNS[key].id,
        title: CAMPAIGNS[key].title,
        color: CAMPAIGNS[key].color,
        description: CAMPAIGNS[key].cardDescription,
        image: CAMPAIGNS[key].backgroundImage,
        path: key,
    };
});

const StyledHome = styled.div`
  ${props => {
    if (props.animationState === 'leave') {
      return `
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `;
    }
  }};
`;

const StyledAnimatedHomeInner = styled(animated.div)`
  position: relative;
`;


class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoUrl: null,
    };
  }

  watchVideoHandler = (video) => {
    this.setState({
      videoUrl: video,
    });
    this.props.onTriggerModal("video");
  }

  closeModalHandler = () => {
    this.props.onTriggerModal(false);
  }

  render() {
    return (
        <StyledHome animationState={this.props.animationState}>
          <Helmet title={'Fighting for Change'} />
          <Modal show={this.props.modal.isOpen === "video"} modalClosed={this.closeModalHandler}>
            <VideoPlayer
              videoUrl={this.state.videoUrl}
              playing={this.props.modal.isOpen === "video"} />
          </Modal>
          <Spring
            native
            from={{ transform: 'translate3d(0, 100px, 0)', opacity: 0 }}
            to={{ transform: 'translate3d(0, 0px, 0)', opacity: 1 }}
            delay={500}>
            {styles => (
              <StyledAnimatedHomeInner style={styles}>
                <VideoPanels
                  data={HOME}
                  clickedVideo={this.watchVideoHandler} />
                <CardNavigation
                  data={CARD_DATA}
                  device={this.props.deviceWindow}
                />
              </StyledAnimatedHomeInner>
            )}
          </Spring>
        </StyledHome>
    )
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTriggerModal: (payload) => dispatch(actionCreators.triggerModal(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
