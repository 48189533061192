/*
 * BrowserWarning Functional Component
 * This component displays the browser warning container.
 */
import React from 'react';
import './BrowserWarning.css';

const browserWarning = ( props ) => {
  return(
    <div className="browser-warning">
      <div className="browser-content">
        <h1>Please update your browser for the best viewing experience.</h1>
        <ul>
          <li>
              <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" rel="noopener noreferrer" target="_BLANK">
                <img src={process.env.PUBLIC_URL + 'browsericons/edge_64x64.png'} alt="Edge" />
                Edge: 110+
              </a>
          </li>
          <li>
            <a href="https://www.mozilla.org/en-US/firefox/new/" rel="noopener noreferrer" target="_BLANK">
              <img src={process.env.PUBLIC_URL + 'browsericons/firefox_64x64.png'} alt="Firefox" />
              Firefox: 110+
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/downloads/safari" rel="noopener noreferrer" target="_BLANK">
              <img src={process.env.PUBLIC_URL + 'browsericons/safari_64x64.png'} alt="Safari" />
              Safari: 16+
            </a>
          </li>
          <li>
            <a href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_BLANK">
              <img src={process.env.PUBLIC_URL + 'browsericons/chrome_64x64.png'} alt="Chrome" />
              Chrome: 110+
            </a>
          </li>
          <li>
            <a href="https://www.opera.com/" rel="noopener noreferrer" target="_BLANK">
              <img src={process.env.PUBLIC_URL + 'browsericons/opera_64x64.png'} alt="Opera" />
              Opera: 95+
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default browserWarning;