const data = {
    Constructs: {
        dragonflyEffectModel: {
          title: 'Dragonfly Effect',
          content: 'The Dragonfly Effect is a newer model that pulls ideas from social media, marketing strategy, and consumer psychology to describe how campaigns can harness the power of social media and digital technologies. The four “wings” of the model are: Focus, Grab Attention, Engage, Take Action. When they are all working in tandem, they can create lasting social change. Barack Obama’s 2008 presidential campaign’s digital strategy is an example of this method in action.',
          button1Title: 'Fight for $15',
          button1Link: '/campaigns/fight-for-15',
          slideIndex: 0
        },
        framingTheory: {
          title: 'Framing',
          content: 'Framing is a theoretical approach used in sociology to help us analyze events and rationalize our interactions with the world. Framing is rooted in the idea that the meaning of objects, events, and experiences does not inherently exist and that it is all derived from cultural context. Thus, frames can help answer questions like, “What is being said? What does this mean? Why should I care?” And it focuses attention by clearly underlining what is relevant or irrelevant, ties elements together into a cohesive narrative, and changes the way that we understand things by putting them in context.',
          button1Title: 'Brexit',
          button1Link: '/campaigns/brexit',
          slideIndex: 1
        },
        politicalOpportunityTheory: {
          title: 'Political Opportunity',
          content: 'Political Process Theory, also known as Political Opportunity Theory, is the dominant paradigm in social movement research that scholars use to explain the success or failure of social movements. Its core tenet is that successful social movements have and take advantage of political opportunities, which are moments when the status quo political system experiences vulnerabilities. Opportunities can be created by increased political enfranchisement (e.g. women and people of color winning the right to vote), division among the elites, diversification of leadership, and a weakening of repressive structures.',
          button1Title: 'Tea Party',
          button1Link: '/campaigns/tea-party',
          slideIndex: 2
        },
        resourceMobilizationTheory: {
          title: 'Resource Mobilization',
          content: 'Resource mobilization theory (RMT) is considered a breakthrough in the study of social movements. Previously, social movements and the people involved with them were viewed as irrational, emotional, and disorganized. However, RMT considers these players to be rational actors and it tries to understand how social movements can accumulate resources and turn uninvolved bystanders into adherents, then constituents, then active participants. A crucial component of RMT is the “social movement organization,” a group of professionals trying to achieve social change. Any issue-focused interest group could fall into the category of a “social movement organization.”',
          button1Title: 'Health Care',
          button1Link: '/campaigns/healthcare',
          slideIndex: 3
        } 
    }
}

export const CONSTRUCTS = data.Constructs;