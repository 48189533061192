import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

import CardNavigation from './CardNavigation';

const StyledCardNavigationMenu = styled.div`
  position: absolute;
  z-index: ${props => props.show ? '500' : '-1'};
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  opacity: ${props => props.show ? '1' : '0'};
  visibility: ${props => props.show ? 'visible': 'hidden'};
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  background-color: ${props => props.theme.colors.black};
  top: ${props => props.scrollTop}px;

  ${props => props.theme.media.medium`
    top: ${props => props.scrollTop}px;
  `};

  ${props => props.theme.media.large`
    top: ${props => props.scrollTop}px;
  `};
`;

const StyledNavSign = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 2;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;

  ${props => props.theme.media.medium`
    right: 1rem;
    top: 1rem;
  `}

`;

const StyledTitle = styled.p`
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.header};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  margin: 0 1rem;
  font-size: 1rem;

  ${props => props.theme.media.medium`
    margin: 1rem;
    font-size: 1.5rem;
  `}
`;

const StyledCloseIcon = styled.div`
  border-radius: 30px;
  background-color: ${props => props.theme.colors.black};
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: all 0.3s;
  z-index: 2;
  position: relative;

  &:hover,
  &:focus {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  &:active {
    transform: scale3d(1.09, 1.09, 1.09);
  }

  &::before,
  &::after {
    transition: background-color 0.3s;
  }

  &::before {
    content: '';
    height: 20px;
    background-color: ${props => props.theme.colors.white};
    width: 2px;
    transform: rotate(45deg);
    left: 50%;
    margin-top: calc(50% - 10px);
    position: absolute;
  }

  &::after {
    content: '';
    height: 20px;
    background-color: ${props => props.theme.colors.white};
    width: 2px;
    transform: rotate(-45deg);
    left: 50%;
    margin-top: calc(50% - 10px);
    position: absolute;
  }

  &:hover::before,
  &:focus::before,
  &:hover::after,
  &:focus::before {
    background-color: ${props => props.theme.colors.yellow};
  }
`;

const StyledCardNavigation = styled(CardNavigation)`
  height: 100%;
`;

class CardNavigationMenu extends Component {

  render() {
    return ReactDOM.createPortal(
      <StyledCardNavigationMenu show={this.props.show} onClick={this.props.closeNav} scrollTop={this.props.modal.modalOffset}>
        <StyledNavSign>
          <StyledTitle>{this.props.title}</StyledTitle>
          <StyledCloseIcon onClick={this.props.closeNav} />
        </StyledNavSign>
        <StyledCardNavigation
          data={this.props.data}
          fluid={true}
          device={this.props.deviceWindow}
        />
      </StyledCardNavigationMenu>
    , document.getElementById('root'));
  }
};

const mapStateToProps = state => {
  return {
      modal: state.modal
  };
};

export default connect(mapStateToProps)(CardNavigationMenu);