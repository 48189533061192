import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as actionCreators from '../../../store/actions/index';

import styled from 'styled-components';

import Backdrop from '../Backdrop/Backdrop';

const StyledModal = styled.div`
  position: absolute;
  z-index: 500;
  width: 100%;
  padding: 1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 90%;
  box-sizing: border-box;
  transition: all 0.5s ease-out;
  opacity: ${props => props.show ? '1' : '0'};
  visibility: ${props => props.show ? 'visible': 'hidden'};
  top: calc(${props => props.scrollTop}px + 1%);

  ${props => props.theme.media.medium`
    top: calc(${props => props.scrollTop}px + 10%);
    left: 0;
    right: 0;
    margin: 0 auto;
  `};

  ${props => props.theme.media.large`
    max-width: 80%;
    top: calc(${props => props.scrollTop}px + 1.5rem);
  `};
`;

const StyledButton = styled.button`
  border:none;
  color: ${props => props.theme.colors.white};
  background: none;
  font-weight:bold;
  font-size: 2rem;
  cursor:pointer;
  position:absolute;
  z-index: 100;

  ${props => props.theme.media.small`
    top: 0;
    right: 0;
  `};

  ${props => props.theme.media.medium`
    top: 0;
    right: 0;
  `};

  ${props => props.theme.media.large`
    top: 0;
    right: 0;
  `};
`;


class Modal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      scrollTop: 0,
      keys: { 37: 1, 38: 1, 39: 1, 40: 1, 32: 1}

    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
  }

  componentDidUpdate() {
    this.setState({
      scrollTop: window.scrollY,
    });

    if (this.props.modal.isOpen) {
      this.disableScroll()
    } else {
      this.enableScroll()
    }
  }

  preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault)
        e.preventDefault();
    e.returnValue = false;
  }

  preventDefaultForScrollKeys(e) {
      if (this.state.keys[e.keyCode]) {
          this.preventDefault(e);
          return false;
      }
  }

  disableScroll() {
    if (window.addEventListener) // older FF
        window.addEventListener('DOMMouseScroll', this.preventDefault, false);
    document.addEventListener('wheel', this.preventDefault, {passive: false}); // Disable scrolling in Chrome
    window.onwheel = this.preventDefault; // modern standard
    window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
    window.ontouchmove  = this.preventDefault; // mobile
    document.onkeydown  = this.preventDefaultForScrollKeys;
  }

  enableScroll() {
    if (window.removeEventListener)
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    document.removeEventListener('wheel', this.preventDefault, {passive: false}); // Enable scrolling in Chrome
    window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;
  }

  closeModalHandler = () => {
    this.props.onTriggerModal(false);
  }


  render() {
    return ReactDOM.createPortal(
      <>
        <Backdrop
          clicked={this.props.modalClosed}
          show={this.props.show}
          scrollTop={this.props.modal.modalOffset}
        />
        <StyledModal
          show={this.props.show}
          className={this.props.className}
          scrollTop={this.props.modal.modalOffset}
          >
          {this.props.modal.isOpen === 'video' ?
            <StyledButton onClick={this.closeModalHandler}>X</StyledButton>
            : '' }
          {this.props.children}
        </StyledModal>
      </>
    , document.getElementById('root'));
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTriggerModal: (payload) => dispatch(actionCreators.triggerModal(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);