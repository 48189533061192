import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CTAVideo from '../UI/CTA/CTAVideo';
import { useTransition, animated } from 'react-spring'

const StyledVideoPanel = styled.div`
  flex: 1 0 100%;
  position: relative;
  height: 100vh;
  /* If you need to support browser without CSS var support (<= IE11) */
  height: calc(100vh - ${props => props.theme.viewport.value}px);
  display: flex;
  flex-flow: column;
  justify-content: flex-end;

  ${props => props.theme.media.large`
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: ${props => props.id === 0 ? 'calc(50% - 7px)' : '50%'};
    max-height: 100%;
  `}
`;

const StyledPlaceholderImage = styled(animated.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
`;


const videoPanel = React.memo((props) => {
  const [index, set] = useState(0)

  const transitions = useTransition(props.imageSlides[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: props.imageSlideSpeedOffset,
    config: {mass: 1, tension: 140, friction: 120}
  })
  useEffect(() => void setInterval(() => set(state => (state + 1) % props.imageSlides.length), props.imageSlideSpeedOffset), [])

  return (
    <StyledVideoPanel id={props.id}>
      {transitions.map(({ item, props, key }) => (
        <StyledPlaceholderImage
          key={key}
          style={{...props, backgroundImage: `url(${item.url}` }}
        />
      ))}
      <CTAVideo
        title={props.title}
        description={props.description}
        buttonText={props.buttonText}
        buttonLink={props.buttonLink}
        clickedVideo={props.clickedVideo}
      />
    </StyledVideoPanel>
  );
});

export default videoPanel;
