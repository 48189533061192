import React from 'react';
import styled from 'styled-components';

const StyledLink = styled.a`
  color: ${props => props.theme.colors.white};
  margin: 0.5rem;
  text-decoration: none;
`;


const linkExternal = (props) => {
  return (
    <StyledLink
      href={props.path}
      target='_blank'
      className={props.className}>
      {props.children}
    </StyledLink>
  );
}

export default linkExternal;
