import React from 'react';
import styled from 'styled-components';

const StyledContentContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-width: ${props => props.theme.container};
  margin: 0 auto;
`;

const contentContainer = (props) => {
  return(
    <StyledContentContainer
      className={props.className}>
      {props.children}
    </StyledContentContainer>
  );
}

export default contentContainer;
