import React from 'react';
import styled from 'styled-components';

const StyledCampaignContainer = styled.div`
  width: 100%;
  max-width: ${props => props.theme.container};
  display: flex;
  flex-flow: row wrap;
  margin: auto;
`;

const campaignContainer = (props) => {
  return (
    <StyledCampaignContainer>
      {props.children}
    </StyledCampaignContainer>
  );
}

export default campaignContainer;
