// Tea Party
import teaParty1 from '../assets/images/TeaParty/teaparty1.jpg';
import teaParty2 from '../assets/images/TeaParty/teaparty2.jpg';
import teaPartyBackgroundImage from '../assets/images/TeaParty/teaparty3.jpg';
import teaPartyVideo1 from '../assets/images/TeaParty/teapartyvideo1.jpg';
import teaPartyVideo2 from '../assets/images/TeaParty/teapartyvideo2.jpg';
import nesbit1 from '../assets/images/TeaParty/nesbit1.jpg';
import nesbit2 from '../assets/images/TeaParty/nesbit2.jpg';
import nesbit3 from '../assets/images/TeaParty/nesbit3.jpg';
import mcintosh1 from '../assets/images/TeaParty/mcintosh1.jpg';
import mcintosh2 from '../assets/images/TeaParty/mcintosh2.jpg';
import mcintosh3 from '../assets/images/TeaParty/mcintosh3.jpg';
import tpInfographic from '../assets/images/Infographics/TeaParty_Rally_19.jpg';
import tpInfographicThumb from '../assets/images/Infographics/TeaParty_Rally_19_thumb.jpg';

//Health Care
import healthCareBackgroundImage from '../assets/images/HealthCare/healthcare1.jpg';
import healthCare2 from '../assets/images/HealthCare/healthcare2.jpg';
import healthCareVideo1 from '../assets/images/HealthCare/SS_HealthCareForAmericaNow.jpg';
import pitts1 from '../assets/images/HealthCare/pitts1.jpg';
import pitts2 from '../assets/images/HealthCare/pitts2.jpg';
import pitts3 from '../assets/images/HealthCare/pitts3.jpg';
import hcInfographic from '../assets/images/Infographics/Healthcare_Rally_19.jpg';
import hcInfographicThumb from '../assets/images/Infographics/Healthcare_Rally_19_thumb.jpg';

// Keystone
import keyStoneBackgroundImage from '../assets/images/Keystone/keystone1.jpg';
import keystone1 from '../assets/images/Keystone/keystone4.jpg';
import keystone2 from '../assets/images/Keystone/keystone3.jpg';
import bruno1 from '../assets/images/Keystone/bruno1.jpg';
import bruno2 from '../assets/images/Keystone/bruno2.jpg';
import bruno3 from '../assets/images/Keystone/bruno3.jpg';
import swift1 from '../assets/images/Keystone/swift1.jpg';
import swift2 from '../assets/images/Keystone/swift2.jpg';
import ksInfographic from '../assets/images/Infographics/Keystone_Rally_19.jpg';
import ksInfographicThumb from '../assets/images/Infographics/Keystone_Rally_19_thumb.jpg';

// Net Neutrality
import netNeutralityBackgroundImage from '../assets/images/stock/aj-colores-1082414-unsplash.jpg';
import netNeutrality2 from '../assets/images/NetNeutrality/net2.jpg';
import netNeutrality3 from '../assets/images/NetNeutrality/NetNeutrality_Asset1.jpg';
import greer1 from '../assets/images/NetNeutrality/greer1.jpg';
import greer2 from '../assets/images/NetNeutrality/greer2.jpg';
import greer3 from '../assets/images/NetNeutrality/greer3.jpg';
import nnInfographic from '../assets/images/Infographics/NetNeutrality_Rally_19.jpg';
import nnInfographicThumb from '../assets/images/Infographics/NetNeutrality_Rally_19_thumb.jpg';

// Soda Tax
import sodaTaxBackgroundImage from '../assets/images/SodaTax/sodatax1.jpg';
import sodaTax1 from '../assets/images/SodaTax/sodatax3.jpg';
import sodaTax2 from '../assets/images/SodaTax/measured_exemptions.jpg';
import sodaTax3 from '../assets/images/SodaTax/albelection2_web.jpg';
import sodaTaxVideo1 from '../assets/images/SodaTax/SS_BerkeleyVsBigSoda.jpg';
import sodaTaxVideo2 from '../assets/images/SodaTax/SS_LikeCokeOrPepsi.jpg';
import stInfographic from '../assets/images/Infographics/SodaTax_Rally_19.jpg';
import stInfographicThumb from '../assets/images/Infographics/SodaTax_Rally_19_thumb.jpg';

//Brexit
import brexitBackgroundImage from '../assets/images/Brexit/brexit3.jpg';
import brexit5 from '../assets/images/Brexit/brexit4.jpg';
import brexit3 from '../assets/images/Brexit/brexit5.jpg';
import brexit2 from '../assets/images/Brexit/SS_BriefingImmoralUnfair.jpg';

// Charter Cap
import charterCapBackgroundImage from '../assets/images/CharterCap/chartercapbackground.jpg';
import charterCap1 from '../assets/images/CharterCap/chartercap2.jpg';
import charterCapVideo1 from '../assets/images/CharterCap/SS_GovernorCharlieBaker.jpg';
import kerr1 from '../assets/images/CharterCap/kerr1.jpg';
import kerr2 from '../assets/images/CharterCap/kerr2.jpg';
import kerr3 from '../assets/images/CharterCap/kerr3.jpg';
import ccInfographic from '../assets/images/Infographics/CharterCap_Rally_19.jpg';
import ccInfographicThumb from '../assets/images/Infographics/CharterCap_Rally_19_thumb.jpg';

// Fight for $15
import fightFor15BackgroundImage from '../assets/images/Fightfor15/fightfor15-6.jpg';
import fightFor15Five from '../assets/images/Fightfor15/fightfor15-5.jpg';
import fightFor15Img2 from '../assets/images/Fightfor15/omidyar_fightfor15_top one percent ownership.png';
import fightFor15Img3 from '../assets/images/Fightfor15/omidyar_fightfor15_hourly minimum wage to afford rent.png';

// Start Campaign Content
const data = {
    Campaigns: {
        '/campaigns/tea-party': {
            id: 0,
            title: 'Tea Party',
            subTitle: 'How did the Tea Party gain such influence in Washington in a few short months?',
            color: 'green',
            textColor: "white",
            cardDescription: 'The Tea Party was a movement for a moment and the 2008 election was the fuse that lit the powder keg.',
            backgroundImage: teaPartyBackgroundImage,
            contentTop: [
                'The Tea Party was a movement for a moment and the 2008 election was the fuse that lit the powder keg. For five years, this “small government” conservative political movement was a force to be reckoned with in American politics. Its leaders pushed unsexy issues like lowering the national debt, cutting federal spending, and stopping corporate bailouts front and center in American politics – shutting down the government and getting right-leaning conservatives elected to Congress.',
                'From the outside, it looked like the Tea Party movement was a genuine grassroots uprising – a reaction to overspending in Washington, resentment from the financial crisis, and the election of a Democratic African-American president in Barack Obama. But the reality was more complicated.',
                'The groundwork for what would become the Tea Party had been painstakingly laid over decades by energy industry billionaires Charles and David Koch, who aimed to take over a major political party and remake it with Libertarian, free-market principles at the core.',
                'The Koch brothers formed an alliance with the nation’s largest tobacco companies to fund and organize local groups to fight taxes and regulations against the tobacco industry. Their coordination and fundraising, filtered through various organizations that obscured the source, because the support network that allowed the Tea Party movement to seize a political opportunity.'
            ],
            contentBottom: [
                'The movement had a highly effective message frame  that allowed them to couch a wide variety of issues – from immigration and abortion to health care and the debt ceiling – under the same umbrella of ‘Freedom + Patriotism + Liberty’. This message framework enabled the Tea Party to place individuals and policies into very clearly defined “friend or foe” categories, which appealed to supporters’ core emotions and drove high levels of citizen engagement.',
                'These narratives were not developed by accident. The core themes of freedom, patriotism, and liberty had been used for decades by the anti-tax groups funded by tobacco companies, the precursor to the Tea Party, to fight all sorts of taxes and regulations that impacted the tobacco industry.',
                'In 2009, the “foes” were rising taxes and anyone perceived as draining America’s resources – young people, immigrants, and anyone under retirement age benefiting from government social safety nets. Tea Party activists themselves used emotionally charged terms like “freeloaders,” “moochers,” and “take our country back” in order to frame every issue under this umbrella.',
                'While the frame was simple, it still allowed for a wide breadth of issues to fall under it and allowed the Tea Party to mobilize against various initiatives including healthcare reform, government bailouts, rising debt, and immigration. The sharply defined “with us or against us” attitude made the Tea Party activists feel a part of something big and important, and deeply attached and committed to the movement.'
            ],
            contentHighlights: {
                "Dragonfly Effect Model": "dragonflyEffectModel",
                "Framing Theory": "framingTheory",
                "message frame ": "framingTheory",
                "Political Opportunity Theory": "politicalOpportunityTheory",
                "Resource Mobilization Theory": "resourceMobilizationTheory"
            },
            contentLinks: false,
            contentTopMedia: [
            {
                id: 0,
                type: 'image',
                imageUrl: teaParty1,
            },
            {
                id: 1,
                type: 'image',
                imageUrl: teaParty2,
            },
            ],
            contentBottomMedia: [
                {
                    id: 1,
                    type: 'video',
                    backgroundImage: nesbit1,
                    title: 'Tea Party - Origins & Purpose',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334507448/ef624cd870',
                    buttonLabel: 'Watch',
                },
                {
                    id: 4,
                    type: 'video',
                    backgroundImage: mcintosh1,
                    title: 'Tea Party - Organic Messaging',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334716431/3be4628f08',
                    buttonLabel: 'Watch',
                },
                {
                    id: 2,
                    type: 'video',
                    backgroundImage: nesbit2,
                    title: 'Tea Party - Timing & Political Opportunity',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334511079/aa93935225',
                    buttonLabel: 'Watch',
                },
                {
                    id: 5,
                    type: 'video',
                    backgroundImage: mcintosh2,
                    title: 'Tea Party - Decentralized Organizational Structure',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334716552/1c055cf20a',
                    buttonLabel: 'Watch',
                },
                {
                    id: 3,
                    type: 'video',
                    backgroundImage: nesbit3,
                    title: 'Tea Party - Koch Political Network\'s Influence',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334511411/ac4c3d2b87',
                    buttonLabel: 'Watch',
                },
                {
                    id: 6,
                    type: 'video',
                    backgroundImage: mcintosh3,
                    title: 'Tea Party - Improving Structure & Leadership',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334717495/b0aa84a1b2',
                    buttonLabel: 'Watch',
                },
            ],
            componentCTA: {
                title: 'Campaign Component Example - Framing',
                path: '/components/framing',
            },
            contentBottomButton: {
                buttonPage: '19',
                buttonLabel: 'learn more',
            },
            contentBottomVideos: [
                {
                    id: 0,
                    type: 'video',
                    backgroundImage: teaPartyVideo1,
                    title: 'DC Tea Party Rally',
                    description: '',
                    videoUrl: 'https://youtu.be/FPMAQCUBHI0',
                    buttonLabel: 'Watch',
                },
                {
                    id: 2,
                    type: 'video',
                    backgroundImage: teaPartyVideo2,
                    title: 'Tax Day Tea Party: Washington, D.C.',
                    description: '',
                    videoUrl: 'https://youtu.be/HL-QzP_p5gc',
                    buttonLabel: 'Watch',
                },
            ],
            infographic: tpInfographic,
            infographicThumb: tpInfographicThumb,
	    specialContent: false
        }, // End Tea Party

        '/campaigns/healthcare': {
            id: 1,
            title: 'Health Care',
            subTitle: 'How did the HCAN coalition create pressure to help pass the Affordable Care Act?',
            color: 'red',
            textColor: "white",
            cardDescription: 'Advocates waged an impressive campaign from 2009-2010 to pass the Affordable Care Act, the most significant health care legislation of the last fifty years.',
            backgroundImage: healthCareBackgroundImage,
            contentTop: [
                'The Patient Protection and Affordable Care Act, nicknamed ‘Obamacare’, is a United States federal law signed by President Barack Obama in March 2010. Often referred to as the Holy Grail of progressive issues, ‘Obamacare’ greatly expanded access to private health insurance, offered subsidies to make it more affordable, and required insurance companies to cover pre-existing conditions. It represents the most sweeping reform of the American healthcare system since Medicare and Medicaid in the 1960s.',
                'Progressive advocates had tried and failed repeatedly over the last century to enact health care reform. Though no one group or person can take credit for the law’s success, it is widely accepted that Health Care for America Now (HCAN), a grassroots coalition and advocacy organization, played a crucial role in its passage.',
                'The HCAN Steering Committee accurately bet that the best path to victory was getting every single Democrat in Congress on board, instead of courting potential Republican defectors, believing (correctly) that no member of that party would support the legislation. HCAN also recognized that they lacked the financial resources to come close to matching what the insurance industry would spend in opposition. They designed a campaign that would undercut the opposition’s strengths and focus precious resources on moving only those lawmakers in their own party who could be persuaded to support the law. Their decision to invest heavily in field operations is one of the most important takeaways, as it proved that grassroots organizing still had the power to defeat big corporations.'
            ],
            contentBottom: [
                'HCAN was not the first campaign to attempt this model, but it was the first to do it well. HCAN’s coalition efforts were successful because they obtained and maintained buy-in from both partners and members. The coalition delivered weekly updates at the grassroots level to build trust with transparency. The deliberate efforts permitted everyone involved to understand the reasoning behind the coalition’s decisions and the tradeoffs involved. Enabling people to see the big picture helped ensure that they would work together, even in the face of obstacles that could have turned divisive. Organizers across different states checked in with each other regularly and supported a culture where best practices were shared. The calls were results-oriented and even led to friendly competition to see who could get more turnout at events.',
                'This strong coalition and an integrated field campaign allowed HCAN to react rapidly and appropriately to unforeseen circumstances. For example, the August 2010 public “town hall” meetings were disrupted by fired up [Tea Party] protesters. The Tea Party could have potentially derailed reform efforts by terrorizing elected officials with their tactics. Instead, in a matter of days, HCAN and its allies were able to out-organize the Tea Party where it counted: the districts of vulnerable Democrats. Their ability to quickly organize to outnumber the Tea Party members provided cover for supportive Democrats and ensured that they didn’t lose their support.'
            ],
            contentHighlights: {
                "Dragonfly Effect Model": "dragonflyEffectModel",
                "Framing Theory": "framingTheory",
                "Political Opportunity Theory": "politicalOpportunityTheory",
                "Resource Mobilization Theory": "resourceMobilizationTheory",
                "[Tea Party]": {
                    'link': '/campaigns/tea-party',
                    'text': 'Tea Party',
                    'new': false
                  },
            },
            specialContent: {
                text: 'In the hands of most people, HCAN could have become an unwieldy, logistical nightmare. The rough hierarchy was as follows:',
                infographicThumb: hcInfographicThumb,
                infographic: hcInfographic
            },
            contentLinks: {
                '[Tea Party]': {
                    'link': '/campaigns/tea-party',
                    'text': 'Tea Party',
                    'new': false
                }
            },
            contentTopMedia: [
                {
                    id: 0,
                    type: 'image',
                    imageUrl: healthCare2,
                },
            ],
            contentBottomMedia: [
                {
                    id: 1,
                    type: 'video',
                    backgroundImage: pitts1,
                    title: 'Health Care - Positive Messaging',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334684829/adbb60b4eb',
                    buttonLabel: 'Watch',
                },
                {
                    id: 2,
                    type: 'video',
                    backgroundImage: pitts2,
                    title: 'Health Care - Hindsight After Campaigns',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334685790/f47cc3a636',
                    buttonLabel: 'Watch',
                },
                {
                    id: 3,
                    type: 'video',
                    backgroundImage: pitts3,
                    title: 'Health Care - Organizing Campaigns Effectively',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334687908/dcdc6d57be',
                    buttonLabel: 'Watch',
                },

            ],
            componentCTA: {
                title: 'Campaign Component Example - Structure',
                path: '/components/structure',
            },
            contentBottomButton: {
                buttonPage: '29',
                buttonLabel: 'learn more',
            },
            contentBottomVideos: [
                {
                    id: 0,
                    type: 'video',
                    backgroundImage: healthCareVideo1,
                    title: 'Health Care for America Now Ad - Magic Eight Ball',
                                        description: '',

                    videoUrl: 'https://youtu.be/tirU5qpmFK4',
                    buttonLabel: 'Watch',
                },
            ],
            infographic: '',
            infographicThumb: ''
        }, // End Health Care

        '/campaigns/keystone': {
            id: 2,
            title: 'Keystone',
            subTitle: 'How did a coalition of environmental groups, ranchers and Indigenous people take on the oil industry?',
            color: 'yellow',
            textColor: "black",
            cardDescription: 'There was no blueprint for how to stop the fossil fuel industry in the U.S. until the Keystone XL campaign came along.',
            backgroundImage: keyStoneBackgroundImage,
            contentTop: [
                'There was no blueprint for how to stop the fossil fuel industry in the U.S. until the Keystone XL campaign came along. It was the equivalent of an environmental David v. Goliath tale - a global oil and gas company seeking to expand its system of pipelines against an unprecedented coalition fighting to stop them. In 2008, energy company TransCanada proposed the building of an 1,179-mile addition to its existing Keystone oil pipeline network that would carry tar sands oil from the fields in western Canada to processing facilities in Nebraska. For TransCanada, the proposed XL pipeline represented a shorter and higher-capacity route for the oil. For opponents like the Natural Resources Defense Council, Keystone XL “was designed to transport the planet’s dirtiest fossil fuel to market, fast.”',
                'Opposition to the Keystone XL proposal set off seven years of public protests and a well-orchestrated pressure campaign by a coalition of environmental advocacy groups that did the impossible – stop ‘Big Oil’.',
                'The campaign is largely regarded as successful because President Obama denied the permit, but the bigger success was the creation of a twenty-first century climate movement whose impact is evident from the proposed “Green New Deal” and likely will be felt for years to come.'
            ],
            contentBottom: [
                'Beginning in 2011, climate advocates from Indigenous Tribes began organizing tribal councils to pass resolutions opposing Keystone XL in communities across the country. Those local resolutions were eventually delivered to President Obama at a tribal leaders summit. This reinforced the human impacts of the proposed pipeline, particularly for communities deeply connected to the land. By August, the environmental organization 350.org launched the first significant public action in protest of the pipeline in Washington D.C., a typically dead time in the muggy capital.',
                'Over a two-week period, 1,253 people were arrested after sitting down in a public right of way in front of the White House. The action drew large-scale national media attention to the project for the first time, and shifted the focus away from the State Department, and its bureaucratic responsibility for the permitting process, and put the spotlight on one individual: president Obama.  Importantly, the actions at the White House weren’t taken only by young climate activists, but also included stakeholders along the proposed pipeline route, such as ranchers and farmers who had never been to D.C. before, reframing the idea of who a ‘protester’ typically is.',
                'With his re-election campaign in full swing, Obama was now being met at every campaign stop with climate activists opposing Keystone. His field offices were constantly hearing from local climate activists and he was getting an earful from environmentally-aligned donors. When Republicans tried to force pro-Keystone legislation through Congress, the campaign sent over 800,000 messages in opposition to the Senate in just 24 hours.',
            ],
            contentHighlights: {
                "Dragonfly Effect Model": "dragonflyEffectModel",
                "Framing Theory": "framingTheory",
                "Political Opportunity Theory": "politicalOpportunityTheory",
                "Resource Mobilization Theory": "resourceMobilizationTheory"
            },
            contentLinks: false,
            contentTopMedia: [
            {
                id: 0,
                type: 'image',
                imageUrl: keystone1,

            },
            {
                id: 1,
                type: 'image',
                imageUrl: keystone2,
            }
            ],
            contentBottomMedia: [
                {
                    id: 1,
                    type: 'video',
                    backgroundImage: bruno1,
                    title: 'Keystone XL - Keeping Spirits Up',
                                        description: '',

                    videoUrl: 'https://vimeo.com/333622171/8b27c79dce',
                    buttonLabel: 'Watch',
                },
                {
                    id: 4,
                    type: 'video',
                    backgroundImage: swift1,
                    title: 'Keystone XL - Tactical Focus',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334747419/58e82d2bf6',
                    buttonLabel: 'Watch',
                },
                {
                    id: 2,
                    type: 'video',
                    backgroundImage: bruno2,
                    title: 'Keystone XL - Responding to Criticism',
                                        description: '',

                    videoUrl: 'https://vimeo.com/333622735/eeb1c35142',
                    buttonLabel: 'Watch',
                },
                {
                    id: 5,
                    type: 'video',
                    backgroundImage: swift2,
                    title: 'Keystone XL - Success as an Underdog',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334747984/b1d0d68659',
                    buttonLabel: 'Watch',
                },
                {
                    id: 3,
                    type: 'video',
                    backgroundImage: bruno3,
                    title: 'Keystone XL - Having a Clear Goal',
                                        description: '',

                    videoUrl: 'https://vimeo.com/333623852/81e15ddd9e',
                    buttonLabel: 'Watch',
                },
            ],
            componentCTA: {
                title: 'Campaign Component Example - Pressure',
                path: '/components/pressure',
            },
            contentBottomButton: {
                buttonPage: '54',
                buttonLabel: 'learn more',
            },
            contentBottomVideos: [
            ],
            infographic: ksInfographic,
            infographicThumb: ksInfographicThumb,
	    specialContent: false
        }, // End Keystone

        '/campaigns/net-neutrality': {
            id: 3,
            title: 'Net Neutrality',
            subTitle: 'How did Team Internet take a complicated issue and inspire millions to action?',
            color: 'purple',
            textColor: "white",
            cardDescription: 'The campaign to defend Net Neutrality used creative tactics to break down a complicated issue and inspire millions of Americans to take action.',
            backgroundImage: netNeutralityBackgroundImage,
            contentTop: [
                'In 2015, The Federal Communication Commission (FCC) imposed network neutrality (“net neutrality”) regulations on Internet Service Providers (ISPs), barring them from discriminating against and charging more money based on user type, content, or platform. That means ISPs couldn’t play favorites with big, well-funded companies that can pay more for more speed, or to block smaller or independent websites and competitors. That historic FCC ruling, which was widely hailed as a triumph by public interest communities, was short lived. In January 2017, President Trump changed the makeup of the appointed FCC board and chose Ajit Pai, a former commissioner and vocal opponent to net neutrality, as its new chairman. The new FCC Chairman quickly began to undo the net neutrality regulations.',
                '“Team Internet,” a coalition formed by Fight for the Future (FFTF), Free Press Action Fund, and Demand Progress in 2014, played a critical role in generating pressure on the FCC and Congress in 2015 to pass the original regulations and were now faced with the same fight all over again. ',
                'This time around, because of Team Internet’s advocacy, what was once an issue misunderstood by most Americans became one of the most talked about modern issues -- with millions of people across the country taking action to press for net neutrality rules. Their campaign is also considered a blueprint for other digital-based movements such as the Parkland Teens who used social media in the #NeverAgain campaign seeking stronger gun control regulations opposed by the powerful gun lobby.'
            ],
            contentBottom: [
                'First, Team Internet used digital tools to demonstrate widespread public support for net neutrality. Each coalition partner played a unique and critical role. Demand Progress brought together “large and diverse coalitions that transcended political lines” and coordinated the Team Internet events supporting net neutrality. They launched an interactive map to support the organizing of protests and drive advocates to meet with lawmakers in key districts. The map led users to a Facebook group to connect with others who shared their passion about net neutrality. Fight for the Future’s (FFTF) role was “as organizers on a mission to win a campaign,” positioning themselves not as DC insiders but rather as organizers, activists and technologists “with the ability to build online experiences at a mass scale.” Their team educated members to move them up the engagement ladder.',
                'Second, because filing a public comment on the FCC website was extremely complicated and time consuming, FFTF’s team created easy-to-use widgets that made filing comments easy and understandable for ordinary citizens. They also created  form sheets that allowed activists to easily call and email their members of Congress. The site also led users to a micropage for each member of Congress, where voters could see whether their representative supported or opposed the policy. Additionally, they provided resources to help people schedule in-person events, protests, meetings, and canvassing efforts  to mobilize constituencies (like veterans) whom lawmakers would listen to within their own district.',
                'Third, Team Internet created messages designed to go viral so that a simple graphic could trigger 100,000 comments to flood the FCC in one day. Allies generated public and media attention through in-app messages directing users to the coalition website battleforthenet.com, by posting net neutrality explainer videos and graphics, and through sharing site-wide alert GIFs asking their users to contact the FCC and Congress.',
                'Fourth, Team Internet used social media to generate visibility for its “slow loading” icon, symbolizing the implications for ordinary users if net neutrality failed. Websites like Netflix, WordPress, Vimeo, Upworthy, and Reddit displayed loading symbols on their front pages to show their support for equal treatment of all the data flowing through the Internet. While pages didn’t actually load more slowly, they symbolized warnings of what the internet could look like if ISPs were allowed to discriminate between different kinds of content.'
            ],
            contentHighlights: {
                "Dragonfly Effect Model": "dragonflyEffectModel",
                "Framing Theory": "framingTheory",
                "Political Opportunity Theory": "politicalOpportunityTheory",
                "Resource Mobilization Theory": "resourceMobilizationTheory"
            },
            contentLinks: false,
            contentTopMedia: [
            {
                id: 0,
                type: 'image',
                imageUrl: netNeutrality2,
            },
            {
                id: 1,
                type: 'image',
                imageUrl: netNeutrality3,
            },
            ],
            contentBottomMedia: [
                {
                    id: 1,
                    type: 'video',
                    backgroundImage: greer1,
                    title: 'Net Neutrality - Breaking Down Complexities',
                                        description: '',

                    videoUrl: 'https://vimeo.com/333624507/cc595b744e',
                    buttonLabel: 'Watch',
                },
                {
                    id: 2,
                    type: 'video',
                    backgroundImage: greer2,
                    title: 'Net Neutrality - Picking Targets',
                                        description: '',

                    videoUrl: 'https://vimeo.com/333624745/7d3fc745dd',
                    buttonLabel: 'Watch',
                },
                {
                    id: 2,
                    type: 'video',
                    backgroundImage: greer3,
                    title: 'Net Neutrality - How to Make the Right Decisions',
                                        description: '',

                    videoUrl: 'https://vimeo.com/333625101/492eceaa1b',
                    buttonLabel: 'Watch',
                },
            ],
            componentCTA: {
                title: 'Campaign Component Example - Power',
                path: '/components/power',
            },
            contentBottomButton: {
                buttonPage: '66',
                buttonLabel: 'learn more',
            },
            contentBottomVideos: [
            ],
            infographic: nnInfographic,
            infographicThumb: nnInfographicThumb,
	    specialContent: false
        }, // End Net Neutrality

        '/campaigns/soda-tax': {
            id: 4,
            title: 'Soda Tax',
            subTitle: 'How did public health advocates turn years of defeat into a victory?',
            color: 'purple',
            textColor: "white",
            cardDescription: 'For decades, public health advocates tried & failed to pass taxes on sugary drinks. In 2014, Berkeley, CA turned the tide & created a playbook for future victories.',
            backgroundImage: sodaTaxBackgroundImage,
            contentTop: [
                'For decades, public health advocates have sought to reduce the consumption of sugar-sweetened beverages, which many point to as a key contributor to increasing levels of obesity and Type 2 diabetes worldwide. One approach in America has been the soda tax, which is a tax or surcharge on sugar-sweetened beverages meant to reduce public consumption.',
                'Initial attempts to levy soda taxes in cities like New York City, El Monte, CA, and Richmond, CA failed. The American Beverage Association (ABA), the industry’s trade organization, and its partners used their deep pockets and influence to turn the public and legislators against the tax, mobilizing around the themes of personal choice and freedom. For years, the ABA was undefeated in shutting down local soda taxes across the country.',
                'In 2014, that all changed. The city of Berkeley, CA re-energized the movement and passed the first soda tax in the United States. The campaign, which its strategists named “Berkeley vs. Big Soda,” countered the beverage industry with the message that children and consumers needed to be protected from greedy corporations and their unscrupulous marketing tactics. The message resonated and the ballot measure passed with 76% of the vote. The successful campaign served as a blueprint for six other cities that would use Berkeley’s model to pass their own soda taxes, putting the beverage industry on the defensive.'
            ],
            contentBottom: [
                'The victory of the “Yes on D” soda tax campaign in Berkeley, CA followed a series of events that paved the way for success. The first was the relative maturation of the movement to reduce consumption of sugar-sweetened beverages. By the time Berkeley residents headed to the polls in 2014, dozens of soda taxes had been proposed and soundly defeated at every level of government. The American Beverage Association (ABA) was used to winning. Before Berkeley’s success, the ABA boasted that it had defeated no fewer than 40 such efforts. These losses, however, were instructive for soda tax proponents as they thought about how to win in Berkeley.',
                'First, the beverage industry had been forced to show its cards in multiple fights across the country. But what the ABA saw as a playbook on how to beat soda taxes ended up being used against them. Grassroots advocates in Berkeley noticed patterns and recognized that the beverage industry had adopted tactics used by the tobacco industry and began to fight back.',
                'The second was a 2012 decision by Bloomberg Philanthropies decision to begin funding soda tax campaigns, including support of a successful nationwide soda tax in Mexico. That was seen as a huge victory and emboldened Berkeley to put the soda tax on the ballot and gave them critical financial support. The “Yes on D” campaign also took advantage of cuts to funding for the California Nutrition Network, a program aimed at providing access to healthy food for low-income families and students. This provided a community-wide rallying cry to find alternative funding sources to save the beloved program, with the soda tax positioned as the main alternative, giving the campaign a positive message that activated local parents.',
                'The victories in Berkeley and Mexico opened the floodgates for other cities to push their own soda taxes. Six cities, including three Bay Area neighbors, followed in Berkeley’s footsteps.'
            ],
            contentHighlights: {
                "Dragonfly Effect Model": "dragonflyEffectModel",
                "Framing Theory": "framingTheory",
                "Political Opportunity Theory": "politicalOpportunityTheory",
                "Resource Mobilization Theory": "resourceMobilizationTheory"
            },
            contentLinks: false,
            contentTopMedia: [
                {
                    id: 0,
                    type: 'image',
                    imageUrl: sodaTax1,
                },
                {
                    id: 1,
                    type: 'image',
                    imageUrl: sodaTax3,
                },
            ],
            contentBottomMedia: [
                {
                    id: 0,
                    type: 'image',
                    imageUrl: sodaTax2,
                },

            ],
            componentCTA: {
                title: 'Campaign Component Example - Timing',
                path: '/components/timing',
            },
            contentBottomButton: {
                buttonPage: '74',
                buttonLabel: 'learn more',
            },
            contentBottomVideos: [
                {
                    id: 0,
                    type: 'video',
                    backgroundImage: sodaTaxVideo1,
                    title: 'Berkeley vs. Big Soda',
                    videoUrl: 'https://youtu.be/zM3N2Op8Nkg',
                    buttonLabel: 'Watch',
                },
                {
                    id: 1,
                    type: 'video',
                    backgroundImage: sodaTaxVideo2,
                    title: 'Like Coke or Pepsi? Wait Until You Hear What They\'re Doing!',
                    videoUrl: 'https://youtu.be/RIjHQglXC7Y',
                    buttonLabel: 'Watch',
                },
            ],
            infographic: stInfographic,
            infographicThumb: stInfographicThumb,
	    specialContent: false
        }, // End Soda Tax

        '/campaigns/brexit': {
            id: 5,
            title: 'Brexit',
            subTitle: 'How did ‘Vote Leave’ go from a long shot to the winner in a high stakes campaign?',
            color: 'blue',
            textColor: "white",
            cardDescription: 'The 2016 “Vote Leave” campaign was a dark horse that pulled off a surprise win to convince voters to leave the European Union.',
            backgroundImage: brexitBackgroundImage,
            contentTop: [
                'In the summer of 2016, voters in the United Kingdom were asked whether they wanted to remain part of the European Union, or leave the unified bloc of nations, which the UK has been part of since 1973.',
                '“Vote Leave” was the long shot campaign, a dark horse that wasn’t expected to win a race that easily should have gone to “Remain”. This referendum - known as “Brexit” — ran headlong into the sudden rise of populism and nationalism across much of North America and Europe. This shift in the political winds, exacerbated by the economic effects of globalization and rising immigration, was also propelling Donald Trump toward victory in the U.S. presidential race and nearly pushed a far-right candidate in France ahead in the race for president.',
                'By just over 600,000 votes, or slightly more than 1% of all registered UK voters, ‘Leave’ eked out the win. The results took much of the UK – and especially cosmopolitan London – by surprise. “Vote Leave” created the victory against almost impossible odds by seizing the moment and telling a finely crafted story that framed its campaign and values in a way that matched the mood of the country.'
            ],
            specialContent: false,
            contentBottom: [
                'Matthew Elliott, the chief executive of Vote Leave, hired Dominic Cummings to run the campaign early on, identifying in him the qualities needed to run the campaign: “We needed somebody in there who was fearless, who was a warrior, who had a great strategic mind, who frankly had the appetite to take something on at a time when no one else thought it could be done,” Elliot said. “And Dom was the man to do that.”',
                'Conservative MP Steve Baker, who campaigned for Brexit, describes Cummings as being like a political ‘special forces.’ “If you don’t care about what collateral damage you sustain, he’s the weapon of choice. He operates with the minimum of civilised restraint. He is a barbarian. Dominic has undoubted mastery of leadership and strategy and political warfare. But he will not let himself be held to account by anybody.”',
                'Cummings combined good intuition and confidence in his strategy, with a clear understanding of who made up the campaign’s base and an ability to utilize data effectively. This combination, as well as his and his team’s willingness to “risk looking stupid to win,” proved lethal. One clear instance of this intuition was when President Barack Obama came to England in April 2016 and delivered a speech condemning Brexit. Vote Leave initially panicked, but “Cummings walked into the main campaign war room and announced, ‘This will have no effect’.” His instincts were confirmed by focus groups, which found that Obama was “not good” for Remain. Further evidence of Cumming’s marriage of intuition and data was demonstrated during the process of naming Vote Leave. The team had originally settled on “Vote Leave. Get Change” but the next morning, Cummings came in and declared it to be “Vote Leave. Take Control,” because “I thought about it last night. I’ve done focus groups on this for years. I know this works.”',
                'Compared to the structure of the Remain campaign, which had to hold together a wide range of coalitions and stakeholders, Vote Leave was able to have a “small war room … where Cummings, [Paul] Stephenson and [Victoria] Woodcock were able to make most decisions quickly, with little input from others.” Not having to take a broad coalition into account or make decisions by committee, the Vote Leave campaign was in prime position to react quickly and agilely to changing circumstances.'
            ],
            contentHighlights: {
                "Dragonfly Effect Model": "dragonflyEffectModel",
                "Framing Theory": "framingTheory",
                "Political Opportunity Theory": "politicalOpportunityTheory",
                "Resource Mobilization Theory": "resourceMobilizationTheory"
            },
            contentLinks: false,
            contentTopMedia: [
                {
                    id: 0,
                    type: 'image',
                    imageUrl: brexit5,
                },
            ],
            contentBottomMedia: [
                {
                    id: 0,
                    type: 'image',
                    imageUrl: brexit3,
                },
                {
                    id: 1,
                    type: 'image',
                    imageUrl: brexit2,
                },

            ],
            componentCTA: {
                title: 'Campaign Component Example - Leadership',
                path: '/components/leadership',
            },
            contentBottomButton: {
                buttonPage: '38',
                buttonLabel: 'learn more',
            },
            contentBottomVideos: [
            ],
            infographic: '',
            infographicThumb: ''
        }, // End Brexit

        '/campaigns/charter-cap': {
            id: 6,
            title: 'Charter Cap',
            subTitle: 'This campaign seemingly had it all - funding, powerful supporters, good poll numbers. So why did they lose?',
            color: 'green',
            textColor: "white",
            cardDescription: 'The campaign to lift a cap on charter schools in Massachusetts struggled to adapt their strategy when the opposition came out stronger than expected.',
            backgroundImage: charterCapBackgroundImage,
            contentTop: [
                'The growth of charter schools in the U.S. has been a hot-button debate in public education for years. Charters are public schools that are privately operated but publicly funded. Some school districts and states have tried various tactics to limit their growth including placing caps on the number of charter schools or other restrictions on where and how they can operate. In 2016, organizations supporting public charter schools in Massachusetts placed a measure on the November ballot asking voters to approve an increase in the number of charters allowed under state law. The campaign to lift the cap, “Yes on 2” counted on three things they thought would ensure success:',
                '1. a large budget to outspend the opposition',
                '2. early polling that showed voters supported the measure',
                '3. high-profile support from the Governor',
                'When November came around, voters overwhelmingly decided against the measure - 62% to 38%. While the campaign’s leaders thought they had a winning combination of strategies – they didn’t count on a highly-coordinated, well-funded opposition in the form of the national teachers’ union, which came out in force to oppose the measure. In short, they poked the bear and weren’t ready when it roared back.'
            ],
            contentBottom: [
                'The leadership chose to base their messaging on the overall public support for charter schools – about two thirds of Americans say they support school choice -- and to push the message that all students should have the opportunity to attend a high quality school of their choice.',
                'They placed too much confidence in initial polling, without accounting for changes that would happen along the way. They weren’t prepared for the success of opposition messaging that effectively painted advocates as highly partisan Republicans in a political climate that favored Democrats.',
                'Three months before the election, 36% of Democrats and 52% of independents thought charter schools helped the state’s education system. By election day in November, the public became more divided with two polarizing candidates on the ballot – Donald Trump and Hillary Clinton.',
                'While “Yes on 2” relied mainly on TV ads and never varied their message, opponents of the measure slammed them with a combination of digital ads, more effective messaging and messengers, and grassroots mobilization through the state teacher’s union. Even when it was clear these attacks were working, the advocates declined to change their strategy, revise their messaging, get new messengers, or build a grassroots coalition. Campaign leaders stuck to the initial plan, putting them in a very weak position on election night. With nothing to refute opposition messaging – and effective attacks from Democrats and progressives – the campaign saw Democratic support bottom out. In November, 73% of Democrats voted no, compared to the 42% who opposed the measure in the August polls.'
            ],
            contentHighlights: {
                "Dragonfly Effect Model": "dragonflyEffectModel",
                "Framing Theory": "framingTheory",
                "Political Opportunity Theory": "politicalOpportunityTheory",
                "Resource Mobilization Theory": "resourceMobilizationTheory"
            },
            contentLinks: false,
            contentTopMedia: [
                {
                    id: 0,
                    type: 'image',
                    imageUrl: charterCap1,
                },
            ],
            contentBottomMedia: [
                {
                    id: 1,
                    type: 'video',
                    backgroundImage: kerr1,
                    title: 'Charter Cap - Grassroots Campaigns',
                    description: '',
                    videoUrl: 'https://vimeo.com/334487266/05ca366367',
                    buttonLabel: 'Watch',
                },
                {
                    id: 2,
                    type: 'video',
                    backgroundImage: kerr2,
                    title: 'Charter Cap - Maintaining Focus',
                    description: '',
                    videoUrl: 'https://vimeo.com/334487485/a50dcddf21',
                    buttonLabel: 'Watch',
                },
                {
                    id: 3,
                    type: 'video',
                    backgroundImage: kerr3,
                    title: 'Charter Cap - Running Ballot Initiative',
                                        description: '',

                    videoUrl: 'https://vimeo.com/334488383/6c7eaed736',
                    buttonLabel: 'Watch',
                },

            ],
            componentCTA: {
                title: 'Campaign Component Example - Adaptability',
                path: '/components/adaptability',
            },
            contentBottomButton: {
                buttonPage: '60',
                buttonLabel: 'learn more',
            },
            contentBottomVideos: [
                {
                    id: 1,
                    type: 'video',
                    backgroundImage: charterCapVideo1,
                    title: 'Governor Baker - YES on 2',
                    videoUrl: 'https://youtu.be/IRN9JgtewlY',
                    buttonLabel: 'Watch',
                },
            ],
            infographic: ccInfographic,
            infographicThumb: ccInfographicThumb,
	    specialContent: false
        }, // End Charter Cap

        '/campaigns/fight-for-15': {
            id: 7,
            title: 'Fight For $15',
            subTitle: 'How did retail and fast food workers change the way Americans think about minimum wage?',
            color: 'red',
            textColor: "white",
            cardDescription: 'The biggest national labor story in the US in a generation was an effort started by low-wage workers in the retail and fast food industries.',
            backgroundImage: fightFor15BackgroundImage,
            contentTop: [
                'The biggest national labor story in the U.S. in a generation was an effort started by low-wage workers in the retail and fast food industries to advocate for an increase of the federal minimum wage to $15 per hour. The federal minimum wage serves as the legal “floor” for most non-salaried workers in the private sector, and had remained steady at $7.25 an hour since immediately after the beginning of the recession in 2009. The Service Employees International Union (SEIU), which represents around two million workers, became the main organizer and funder of Fight for a Fair Economy – the precursor to the public Fight for $15 campaign. The SEIU saw the opportunity to use a visible issue that affected millions of workers across the country to create a campaign that would also support the union’s longer term goals:',

                'First, the campaign sought to change the national narrative around income inequality and stagnant wages. Second, it sought to change policy and actually establish a living wage. Third, at a time when unions were in decline, the campaign tried to establish a new style of labor movement to drive more recruitment and signifying the rise of “alt-labor.”',

                'The campaign not only achieved a $15 per hour minimum wage in Seattle within one year, but also reframed how low-wage work and workers are viewed in America. The third goal - rebuild dwindling union power - is still a work in progress and it remains to be seen whether their strategy is successful.'

            ],
            contentBottom: [
                'The Fight for $15’s vision was to confront income inequality by changing the policy and narrative around a living wage. Long term, the leaders behind the campaign sought to revive the bargaining power of unions after decades of declining membership and new legislation weakened their power.',
                'The SEIU began with massive neighborhood canvasses that fed into protests outside banks and inside shareholder meetings. The Fight for a Fair Economy, the predecessor to Fight for $15, blossomed in cities throughout the US in early 2011, and workers from low wage retail, fast food, airport, health care, child care, and other industries merged into the movement. In Seattle, Sea-Tac airport workers organized a local campaign, and in New York City, fast food workers rallied around the call for "$15 and a union," launching what became known as the Fight for $15 campaign.',
                'Initially, the demand for $15 an hour seemed like a pipe dream. At the launch of the campaign, New York fast food workers only earned between $7.50 to $8.50 an hour, which put them well below the poverty line. The bold demand of $15 an hour helped the campaign resonate with workers who were excited to advocate for a living wage. Keeshan Harley, a member of Make the Road New York, a nonprofit that focuses on justice issues, said “In New York City, it\'s kind of impossible to work on $8 an hour when you have $1,900 to $2,000 rents. So we need $15 an hour. That\'s the bare minimum. That is just moving toward sustainability in the city.” A vision for a real livable wage engaged low wage workers and captured the attention of the public in a way that a more incremental change wouldn’t.',
                'Over time, Fight for $15 shifted away from its initial goal of increasing union membership and focused all of its resources on the push for a living wage - believing that achieving a bold policy goal would be its own best recruitment tool.'
            ],
            contentHighlights: {
                "Dragonfly Effect Model": "dragonflyEffectModel",
                "Framing Theory": "framingTheory",
                "Political Opportunity Theory": "politicalOpportunityTheory",
                "Resource Mobilization Theory": "resourceMobilizationTheory"
            },
            contentLinks: false,
            contentTopMedia: [

                {
                    id: 1,
                    type: 'image',
                    imageUrl: fightFor15Img2,
                },
            ],
           specialContent: false,
           contentBottomMedia: [
                {
                    id: 0,
                    type: 'image',
                    imageUrl: fightFor15Img3,
                },
                {
                    id: 1,
                    type: 'image',
                    imageUrl: fightFor15Five,
                },

            ],
            componentCTA: {
                title: 'Campaign Component Example - Vision',
                path: '/components/vision',
            },
            contentBottomButton: {
                buttonPage: '48',
                buttonLabel: 'learn more',
            },
            contentBottomVideos: [
            ],
            infographic: '',
            infographicThumb: ''
        } // End Fight for $15
    }
} // End Camapaign Content
export const CAMPAIGNS = data.Campaigns;
