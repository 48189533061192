import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';


const initialState = {
  isOpen: false,
  modalOffset: 0
};

const modalReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.TRIGGER_MODAL:
      return updateObject(state, { isOpen: action.payload });

    case actionTypes.MODAL_OFFSET:
      return updateObject(state, { modalOffset: action.payload });

    default:
      return state;
  }
};

export default modalReducer;
