import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    menuToggle: "closed"
};

const menuToggleReducer = (state = initialState, action) => {
    
    switch(action.type) {
        case actionTypes.MENU_TOGGLE:
          return updateObject(state, 
            {
                menuToggle: action.payload
            });

        default:
          return state;
      }
};

export default menuToggleReducer;



