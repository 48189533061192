import React from 'react';
import styled from 'styled-components';

const StyledCampaignBackgroundTile = styled.div`
  position: absolute;
  top: 0;
  ${props => {
    if (props.position === 'left') {
      return 'left: 0;';
    } else {
      return 'right: 0;';
    }
    }};
  width: 100%;
  height: 100%;
  z-index: -1;
  border: 0;

  ${props => props.theme.media.large`
    width: 50%;

    ${props => {
      if (props.position === 'right') {
          return `
            left: calc(50% - 3rem);
            top: -3rem;
            width: 60%;
            height: calc(100% + 3rem);
          `;
      }
    }};
  `}
`;


const StyledBackgroundColor = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${props => {
    switch(props.backgroundColor) {
      case 'green':
        return props.image ? props.theme.colors.greenTransparent : props.theme.colors.green;
      case 'yellow':
        return props.image ? props.theme.colors.yellowTransparent : props.theme.colors.yellow;
      case 'purple':
        return props.image ? props.theme.colors.purpleTransparent : props.theme.colors.purple;
      case 'blue':
        return props.image ? props.theme.colors.blueTransparent : props.theme.colors.blue;
      default:
        return props.image ? props.theme.colors.redTransparent : props.theme.colors.red;
    }
    }};
`;

const StyledBackgroundImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const campaignBackgroundTile = (props) => {
  return (
    <StyledCampaignBackgroundTile position={props.position}>
      <StyledBackgroundImage src={props.imageURL} />
      <StyledBackgroundColor backgroundColor={props.backgroundColor} image={props.imageURL} />
    </StyledCampaignBackgroundTile>
  );
}

export default campaignBackgroundTile;
