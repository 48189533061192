import * as actionTypes from './actionTypes';

export const highlight = (payload) => {
  return {
    type: actionTypes.HIGHLIGHT,
    payload: payload,
  };
};

export const triggerDrawer = (payload) => {
  return {
    type: actionTypes.TRIGGER_DRAWER,
    payload: payload,
  };
};

export const setSlideIndex = (payload) => {
  return {
    type: actionTypes.SET_SLIDE_INDEX,
    payload: payload,
  };
};
