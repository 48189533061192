import React from 'react';

export default class Test extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const userway = document.createElement("script");
        userway.type = 'text/javascript';
        userway.src = "https://cdn.userway.org/widget.js";
        userway.setAttribute('data-color', '#E5404E');
        userway.setAttribute('data-account', 'bIxS4GTcnP');
        userway.async = true;

        this.instance.appendChild(userway);
    };

    render() {
        return <div ref={el => (this.instance = el)} />;
      }
}