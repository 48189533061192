import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';


const StyledVideoPlayerWrapper = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  padding-top: 25px;
`;

const StyledVideoPlayer = styled(ReactPlayer)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const videoPlayer = (props) => {
  const vimeoConfig = {
    byline: false,
    portrait: false,
    playsinline: true,
  };

  return (
    <StyledVideoPlayerWrapper>
      <StyledVideoPlayer
        url={props.videoUrl}
        config={{
          vimeo: {
            vimeoConfig
          }
        }}
        width='100%'
        height='100%'
        playing={props.playing} />
    </StyledVideoPlayerWrapper>
  );
}

export default videoPlayer;
