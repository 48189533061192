/*
 * Campaigns Stateful Component
 * This Component controls the home page and passes it's state down to the
 * components rendered on the page.
 */

import React, { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { animated, Spring } from 'react-spring/renderprops';

import { CAMPAIGNS } from '../../data/data';
import * as actionCreators from '../../store/actions';
import LinkReplacements from '../../components/RallyComponents/LinkReplacements.js';
import CampaignSection from '../../components/Campaigns/CampaignSection';
import CampaignHalfMedia from '../../components/Campaigns/CampaignHalfMedia';
import CampaignHalfText from '../../components/Campaigns/CampaignHalfText';
import CampaignContainer from '../../components/Campaigns/CampaignContainer';
import CampaignBackgroundTile from '../../components/Campaigns/CampaignBackgroundTile';
import CampaignTitle from '../../components/Campaigns/CampaignTitle';
import CampaignSubtitle from '../../components/Campaigns/CampaignSubtitle';
import CampaignOffsetWrapper from '../../components/Campaigns/CampaignOffsetWrapper';
import CampaignMediaGenerator from '../../components/Campaigns/CampaignMediaGenerator';
import CampaignLink from '../../components/Campaigns/CampaignLink';
import CampaignBottomVideo from '../../components/Campaigns/CampaignBottomVideo';
import CampaignInfographic from '../../components/Campaigns/CampaignInfographic';
import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import VideoPlayer from '../../components/Video/VideoPlayer';
import pdf from '../../assets/report.pdf';


const StyledContainer = styled.div`
  ${props => {
    if (props.animationState === 'leave') {
      return `
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `;
    }
  }};
`;

const StyledButton = styled.div`
  margin-left: 0.7rem;
`;

const StyledCloseButton = styled.button`
  border:none;
  color: ${props => props.theme.colors.white};
  background: none;
  font-weight:bold;
  font-size: 2rem;
  right:2rem;
  cursor:pointer;
  position:absolute;
`;


const StyledAnimatedCampaignInner = styled(animated.div)`
  position: relative;
`;

const CampaignBottomVideoContainer = styled.div`
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const StyledInfographicModal = styled.img`
  max-height: 90vh;
  max-width: 100%;
  margin: 0 auto;
  display:block;
`;

class Campaigns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoUrl: null,
      campaignData: CAMPAIGNS[window.location.pathname],
      campaignLinkData: CAMPAIGNS[window.location.pathname].componentCTA,
      campaignBottomButton: CAMPAIGNS[window.location.pathname].contentBottomButton,
      topMediaOffsets: [
        {
          left: '-1rem',
          maxWidth: '100%',
        },
        {
          bottom: '0',
          left: '-3.5rem',
          maxWidth: '70%',
        },
        {
          left: '50%',
          bottom: '0',
          maxWidth: '50%',
        },
      ],
      bottomMediaOffsets: [
        {
          right: '-2rem',
          maxWidth: '100%',
        },
        {
          right: '-3rem',
          maxWidth: '100%',
        }
      ],
    }
  }

  watchVideoHandler = (video) => {
    this.setState({
      videoUrl: video,
    });
    this.props.onTriggerModal("video");
  }

  showInfographicHandler = () => {
    this.props.onTriggerModal("infographic");
  }

  closeModalHandler = (e) => {
    e.preventDefault();
    this.props.onTriggerModal(false);
  }

  handlePDFClick = (pdfLink) => {
    window.open(
      pdfLink,
      '_blank'
    );
  }

  render() {
    const infographicSource = this.state.campaignData.infographic ? this.state.campaignData.infographic : this.state.campaignData.specialContent.infographic;

    let i = 0;
    let pdfLink = pdf;

    if(this.state.campaignBottomButton.buttonPage) {
      pdfLink = pdfLink + '#page=' + this.state.campaignBottomButton.buttonPage;
    }

    return (
      <StyledContainer animationState={this.props.animationState}>
        <Helmet title={`Campaign | ${this.state.campaignData.title}`} />
        <Modal show={this.props.modal.isOpen !== false} modalClosed={this.closeModalHandler}>
          {
              this.props.modal.isOpen === "video" ?
                <VideoPlayer
                videoUrl={this.state.videoUrl}
                playing={this.props.modal.isOpen === "video"} />
              : this.props.modal.isOpen === "infographic" ?
                  <>
                    <StyledCloseButton onClick={this.closeModalHandler}>X</StyledCloseButton>
                    <StyledInfographicModal src={infographicSource} alt='Infographic' />
                  </>
                : ''
          }
        </Modal>
        <Spring
          native
          from={{ transform: 'translate3d(0, 100px, 0)', opacity: 0 }}
          to={{ transform: 'translate3d(0, 0px, 0)', opacity: 1 }}
          delay={500}>
          {styles => (
            <StyledAnimatedCampaignInner style={styles}>
              <CampaignSection
                animationState={this.props.animationState}
                textColor={this.state.campaignData.textColor}>
                <CampaignBackgroundTile
                  position={'left'}
                  imageURL={this.state.campaignData.backgroundImage}
                  backgroundColor={this.state.campaignData.color} />
                <CampaignContainer>
                  <CampaignHalfText position={'left'}>
                  <CampaignTitle
                      title={this.state.campaignData.title}
                      color={this.state.campaignData.textColor} />
                  <CampaignSubtitle
                    title={this.state.campaignData.subTitle}
                    color={this.state.campaignData.textColor} />
                      {
                        this.state.campaignData.contentTop.map((item) => {
                          return <p key={i++}><LinkReplacements text={item} highlights={this.state.campaignData.contentHighlights} replacements={this.state.campaignData.contentLinks} textcolor={this.state.campaignData.textColor} /></p>
                        })
                      }
                  </CampaignHalfText>
                  <CampaignHalfMedia position={'right'}>
                    {this.state.campaignData.contentTopMedia.map((media, index) => {
                      return <CampaignOffsetWrapper key={media.id} top="true" offsets={this.state.topMediaOffsets[index]}>
                        <CampaignMediaGenerator
                          type={media.type}
                          data={media}
                          clickedVideo={this.watchVideoHandler}/>
                      </CampaignOffsetWrapper>;
                    })}
                  </CampaignHalfMedia>
                </CampaignContainer>
              </CampaignSection>
              <CampaignSection
                animationState={this.props.animationState}
                textColor={this.state.campaignData.textColor}>
                <CampaignBackgroundTile
                  position={'right'}
                  backgroundColor={this.state.campaignData.color} />
                <CampaignContainer>
                  <CampaignHalfMedia position={'left'}>
                    {this.state.campaignData.infographic ?
                        <CampaignInfographic clickedInfographic={this.showInfographicHandler} source={this.state.campaignData.infographic} thumbnail={this.state.campaignData.infographicThumb} />
                      : ''
                    }
                    {this.state.campaignData.contentBottomMedia.map((media, index) => (
                      <CampaignOffsetWrapper
                        top="false"
                        key={media.id}
                        offsets={this.state.bottomMediaOffsets[Math.floor(Math.random() * 2)]}
                        height="auto"
                        margin="2rem"
                        rloffset={index}>
                        <CampaignMediaGenerator
                          type={media.type}
                          data={media}
                          height="auto"
                          clickedVideo={this.watchVideoHandler}/>
                      </CampaignOffsetWrapper>
                    ))}
                  </CampaignHalfMedia>
                  <CampaignHalfText position={'right'}>
                    <CampaignLink
                      title={this.state.campaignLinkData.title}
                      description={this.state.campaignLinkData.description}
                      path={this.state.campaignLinkData.path}
                      textColor={this.state.campaignData.textColor}
                      />
                      {
                        this.state.campaignData.specialContent !== false ?
                          <>
                            <p>{this.state.campaignData.specialContent.text}</p>
                            <CampaignInfographic clickedInfographic={this.showInfographicHandler} source={this.state.campaignData.specialContent.infographic} thumbnail={this.state.campaignData.specialContent.infographicThumb} />
                          </>
                          : ''
                      }
                      {
                        this.state.campaignData.contentBottom.map((item) => {
                          return <p key={i++}><LinkReplacements text={item} highlights={this.state.campaignData.contentHighlights} replacements={this.state.campaignData.contentLinks} textcolor={this.state.campaignData.textColor} /></p>
                        })
                      }
                      <StyledButton>
                        <Button
                          clicked={() => this.handlePDFClick(pdfLink)}
                          type={'ghost'}
                          size={'large'}
                          color={this.state.campaignData.textColor}
                          label={this.state.campaignBottomButton.buttonLabel}/>
                      </StyledButton>
                    <CampaignBottomVideoContainer>
                      {this.state.campaignData.contentBottomVideos.map((media, index) => (
                        <CampaignBottomVideo
                          key={index}
                          data={media}
                          height="auto"
                          clickedVideo={this.watchVideoHandler} />
                      ))}
                    </CampaignBottomVideoContainer>
                  </CampaignHalfText>

                </CampaignContainer>
              </CampaignSection>
            </StyledAnimatedCampaignInner>
          )}
        </Spring>
      </StyledContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTriggerModal: (payload) => dispatch(actionCreators.triggerModal(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns);
