/*
 * SliderArrow Functional Component
 * This component displays the SVG arrow for the slider.
 */

import React, { Fragment } from 'react';
import styled from 'styled-components';

const StyledSliderArrow = styled.svg`
 stroke: ${props => props.theme.colors.white};
 transition: all 0.3s ease-in-out;
 fill: ${props => props.theme.colors.black};
 
 &:hover,
 &:focus {
   fill: ${props => props.theme.colors.red};
 }

 &:active {
   fill: ${props => props.theme.colors.redLight};
 }
`;

const sliderArrow = ( props ) => {
  let content = (
    <Fragment>
        <g transform="translate(-251 -111)">
            <g transform="translate(300 111) rotate(90)" stroke="#fff" strokeWidth="1">
                <circle cx="24.5" cy="24.5" r="24.5" stroke="none"/>
                <circle cx="24.5" cy="24.5" r="24" fill="none"/>
            </g>
            <path d="M0,0,13.582,13.582,27.165,0" transform="translate(268.783 148.883) rotate(-90)" fill="none" stroke="#fff" strokeWidth="4"/>
        </g>
    </Fragment>
  );

  if (props.direction === 'prev') {
    content = (
      <Fragment>
        <g transform="translate(300 160) rotate(180)">
            <g transform="translate(300 111) rotate(90)" stroke="#fff" strokeWidth="1">
                <circle cx="24.5" cy="24.5" r="24.5" stroke="none"/>
                <circle cx="24.5" cy="24.5" r="24" fill="none"/>
            </g>
            <path d="M0,0,13.582,13.582,27.165,0" transform="translate(268.783 148.883) rotate(-90)" fill="none" stroke="#fff" strokeWidth="4"/>
        </g> 
      </Fragment>
    );
  }

  return(
    <StyledSliderArrow
      viewBox='0 0 49 49'
      className={props.className}
      onClick={props.clicked}>
      {content}
    </StyledSliderArrow>
  );
}

export default sliderArrow;
