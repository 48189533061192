import React from 'react';
import styled from 'styled-components';

import Button from '../Button/Button';

const StyledCTA = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 2rem auto;
  text-align: center;
  height: 100vh;
  max-height: 450px;
  width: 86%;
  position: relative;
  overflow: hidden;

  button {
    color: ${props => props.theme.colors.white};
  }

`;

const StyledBackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

const StyledContent = styled.div`
  background-color: ${props => props.theme.colors.blackOverlay};
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
`;

const StyledTitle = styled.h3`
  color: ${props => props.theme.colors.white};
  width: 100%;
  margin: 0 0 2rem 0;
`;

const StyledDescription = styled.p`
  color: ${props => props.theme.colors.white};
  width: 100%;
  margin: 0 0 2rem 0;
`;

const CTA = (props) => {
  return (
    <StyledCTA>
      <StyledBackgroundImage src={props.imageURL} />
      <StyledContent>
        <StyledTitle>{props.title}</StyledTitle>
          {props.description ? 
              <StyledDescription>{props.description}</StyledDescription>
            : ''
          }
        <Button
          label={props.buttonLabel}
          clicked={props.clickedVideo} />
      </StyledContent>
    </StyledCTA>
  );
}

export default CTA;
