/*
 * ConstructsCard Functional Component
 * This component contains the Construct Card.
 */
import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions';
import { Link } from 'react-router-dom';

const StyledConstructsCard = styled.div`
    background-color: ${props => props.isHighlighted ? props.theme.colors.red : props.theme.colors.black};
    color: ${props => props.theme.colors.white};
    display:flex;
    flex-direction:column;
    padding: 2rem 1rem 0 1rem;
    align-items: center;
    justify-content:flex-start;
    height: 100vh;
    max-height: 530px;
    
    p {
        line-height: 1.5;
        font-size: .85rem;

        ${props => props.theme.media.medium`
            font-size: .9rem;
        `}
        ${props => props.theme.media.large`
            font-size: .9rem;
        `}
    }
`;

const StyledHeader = styled.h3`
    margin-top: 0;
    margin-bottom: 0;
`;

const ButtonContainer = styled.div`
    width: 100%;
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 2rem;

    a {
        margin: 1rem 1rem 0;
        text-align: center;1
    }
`;

const StyledSpan = styled.span`
  backface-visibility: hidden;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-family: ${props => props.theme.fonts.header};
  display: block;
  transition: letter-spacing 0.3s, color 0.3s;
  text-rendering: optimizeLegibility;
`;

const StyledButton = styled(Link)`
  width: 100%;
  max-width: ${props => {
    if (props.size === 'large') {
      return '200px';
    } else {
      return '150px';
    }
    }};
  display: block;
  padding: 0.5rem 2rem;
  background: none;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  backface-visibility: hidden;
  color: ${props => props.theme.colors.white};
  transition: background-color 0.3s, color 0.3s;
  text-transform: uppercase;
  outline: none;
  letter-spacing: 0.1rem;
  cursor: pointer;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  border: 0;
  text-decoration: none;

  span {
    top: 2px;
    position: relative; 
  }

  &:hover,
  &:focus {
    background: none;
    color: ${props => props.theme.colors.white};
  }

  &:active span {
    letter-spacing: 0.25rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: inherit;
    z-index: -1;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1)
  }

  &::before {
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    opacity: 0.2;
    transform: scale3d(0.7, 1, 1);
    transition: transform 0.3s, opacity 0.3s, border 0.3s;
    background-color: ${props => props.color ? props.theme.colors[`${props.color}Light`] : props.theme.colors.redLight};
  }

  &::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale3d(1.1, 1, 1);
    transition: transform 0.3s, background-color 0.3s;
    background-color: ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.red};
  }

  &:hover::before {
    transform: scale3d(1, 1, 1);
    opacity: 1;
    background: none;
    border: 2px solid ${props => props.color ? props.theme.colors[props.color] : props.theme.colors.red};
  }

  &:hover::after {
    transform: scale3d(1, 1, 1);
    background: none;
  }

  ${props => {
    if (props.type === 'ghost') {
      return `
      &::before {
        transform: scale3d(1, 1, 1);
        opacity: 0.2;
        background: none;
        transition: transform 0.3s, opacity 0.3s, border 0.3s;
        background-color: transparent;
      }

      &::after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale3d(1.1, 1, 1);
        transition: transform 0.3s, background-color 0.3s;
        background-color: transparent;
        border: 2px solid ${props.color ? props.theme.colors[props.color] : props.theme.colors.red};

      }

      &:hover::after
      &:focus::after {
        background-color: ${props.color ? props.theme.colors[props.color] : props.theme.colors.red};
      }

      &:hover::before,
      &:focus::before {
        top: -4px;
        bottom: -4px;
        left: -4px;
        right: -4px;
        opacity: 0.2;
        transform: scale3d(0.7, 1, 1);
        background-color: ${props.color ? props.theme.colors[`${props.color}Light`] : props.theme.colors.redLight};
        border: 0;
      }
      `;
    }
  }};

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      background: none;
      color: ${props => props.theme.colors.white};
    }

    &:hover::before,
    &:hover::after {
      content: none;
    }
  }
`;

class ConstructsCard extends Component {

    handleClick = () => {
        this.props.onTriggerDrawer(this.props.drawer.isOpen);
    }
    render() {
        return (
            <StyledConstructsCard isHighlighted={this.props.isHighlighted}>
                <StyledHeader>
                {this.props.title}
                </StyledHeader>

                <p>{this.props.content}</p>
                <ButtonContainer>
                    {
                        (this.props.button1Link ?
                            <StyledButton
                                to={this.props.button1Link}
                                onClick={()=> this.props.onTriggerDrawer(this.props.drawer.isOpen)}
                                type="ghost"
                                size="normal"
                                color="white"
                            >
                                <StyledSpan>
                                    {this.props.button1Title}
                                </StyledSpan>
                            </StyledButton>                      
                        : '')
                    }
                    {
                        (this.props.button2Link ?
                            <StyledButton
                                to={this.props.button2Link}
                                onClick={()=> this.props.onTriggerDrawer(this.props.drawer.isOpen)}
                                type="ghost"
                                size="normal"
                                color="white"
                            >
                                <StyledSpan>
                                    {this.props.button2Title}
                                </StyledSpan>
                            </StyledButton>                      
                        : '')
                    }
                </ButtonContainer>
            </StyledConstructsCard>
        );
    }
}
const mapStateToProps = state => {
    return {
        drawer: state.drawer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTriggerDrawer: (payload) => dispatch(actionCreators.triggerDrawer(!payload)),
    }
}  
  
export default connect(mapStateToProps, mapDispatchToProps)(ConstructsCard);