import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const StyledCampaignOffsetWrapper = styled(animated.div)`
  position: relative;
  display: flex;
  margin: 2rem auto;
  transition: opacity .5s ease-in-out;

  ${props => {
    if (props.rloffset % 2 === 0) {
      return `
        right: 0 !important;
      `;
    } else {
      return `
        right: -5% !important;
      `;
    }
  }};

  ${props => props.theme.media.large`
    ${props => {
        const propertyValues = Object.values(props.offsets);
        let styles = '';

        Object.keys(props.offsets).forEach((property, index) => {
          if (property === 'maxWidth') property = 'max-width';
          styles += `${property}: ${propertyValues[index]};`;
        });

        return styles;
    }};

    ${props => {
      if (props.rloffset % 2 === 0) {
        return `
          right: 0 !important;
        `;
      } else {
        return `
          right: -10% !important;
        `;
      }
    }};

    ${props => {
      if(props.top === "true") {
        return `
          &:hover {
            z-index: 1000;
          }
          &:hover + div {
            opacity: 0;
          }
        `
      }
    }};
    height: ${props => props.height ? props.height : '200px'};

    margin: ${props => props.marginDesktop ? props.marginDesktop : 0};
  `}
`;

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 15}px,${y / 15}px,0)`;


const campaignOffsetWrapper = (props) => {
  const [offsetAnimation, set] = useSpring(() => ({
    xy: [0, 0],
    config: {
      mass: 10,
      tension: 550,
      friction: 140 }
    }));

  return (
    <StyledCampaignOffsetWrapper
      to={props.path}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      onMouseOut={() => set({xy: [0, 0]})}
      style={{ transform: offsetAnimation.xy.interpolate(trans1) }}
      offsets={props.offsets}
      height={props.height}
      margin={props.margin}
      rloffset={props.rloffset}
      top={props.top}
    >
      {props.children}
    </StyledCampaignOffsetWrapper>
  );
}

export default campaignOffsetWrapper;
