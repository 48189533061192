import * as actionTypes from './actionTypes';

export const triggerModal = (payload) => {
  return {
    type: actionTypes.TRIGGER_MODAL,
    payload: payload,
  }
}

export const modalOffset = (payload) => {
  return {
    type: actionTypes.MODAL_OFFSET,
    payload: payload,
  }
}
