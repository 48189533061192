/*
/*
 * Main App Container
 */

import React, { Component } from 'react';
import { detect } from 'detect-browser';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { Transition, animated, config, Spring } from 'react-spring/renderprops';
import Measure from 'react-measure';
import Modal from './components/UI/Modal/Modal';

import BrowserWarning from './components/BrowserWarning/BrowserWarning';
import BROWSER_LIST from './data/constants/BROWSER_LIST';
import { main } from './styles/Main';
import MainMenu from './containers/MainMenu/MainMenu.js';
import GlobalResponsiveTypography from './styles/ResponsiveTypography';
import Home from './containers/Home/Home.js';
import Campaigns from './containers/Campaigns/Campaigns.js';
import RallyComponents from './containers/RallyComponents/RallyComponents.js';
import About from './containers/About/About';
import * as actionCreators from './store/actions/index';
import Footer from './components/Footer/Footer';
import FourOhFour from './containers/FourOhFour/FourOhFour.js';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import Cookies from 'universal-cookie';

library.add(fas)

const StyledApp = styled.main`
  overflow: hidden;
  ${props => props.blurContent ? 'transition: filter 0.3s; filter: blur(5px); overflow: hidden; height: 100vh;' : ''}
  ${props => props.lockScroll ? 'height: 100vh;': ''}
`;

const StyledAppInner = styled(animated.div)`
`;

const StyledAnimatedTransition = styled(animated.div)`
  position: relative;
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      browserList: BROWSER_LIST,
      browserFlag: false,
      browserName: null,
    }
    this.props.history.listen(() => {
      this.props.onTriggerModal(false);
    });
  }

  componentDidMount() {
    const browser = detect();
    this.checkBrowser(browser);
    this.resizeHandler();

    const cookies = new Cookies();

    const checkPopup = cookies.get('popup');

    if(!checkPopup) {
      const current = new Date();
      const nextYear = new Date();

      nextYear.setFullYear(current.getFullYear() + 1);
      cookies.set('popup', true, {path: '/', expires: nextYear});

      setTimeout(() => {
        this.props.onTriggerModal('about');
      }, 1500);
    }

    window.addEventListener("scroll", this.handleScroll);

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.resizeHandler);
      if(window.location.pathname === '/campaigns' ||
        window.location.pathname === '/campaigns/') {
          this.props.history.push('/');
      }

      if(window.location.pathname === '/components' ||
        window.location.pathname === '/components/') {
          this.props.history.push('/');
      }
    }


  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);

    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.resizeHandler);
    }
  }

  handleScroll = () => {
    if (!this.ticking) requestAnimationFrame(this.publishScrollPosition);

    this.ticking = true;
  }

  publishScrollPosition = () => {
    this.props.onModalOffset(window.scrollY);
    this.ticking = false;
  }

  resizeHandler = () => {

    let deviceWindowSize = parseInt(window.innerWidth);
    let deviceWindow = "desktop";

    if(deviceWindowSize < 768) {
      deviceWindow = "mobile";
    }
    if(deviceWindowSize >= 768 && deviceWindowSize <= 1023) {
      deviceWindow = "tablet";
    }

    this.props.onDetectDevice(deviceWindow);
  }

  checkBrowser(browser) {
    const browserVersion = browser.version.split('.');
    const browserNum = browserVersion[0];
    this.setState({browserName: browser.name});
    let setBrowserFlag = true;

    switch (browser && browser.name) {
      case 'chrome':
        if (browserNum < '118') { setBrowserFlag = true; } else {
          setBrowserFlag = false
        }
        break;
      case 'edge':
        if (browserNum < '110') { setBrowserFlag = true; } else {
          setBrowserFlag = false
        }
        break;
      case 'firefox':
        if (browserNum < '121') { setBrowserFlag = true; } else {
          setBrowserFlag = false
        }
        break;
      case 'safari':
        if (browserNum < '16') { setBrowserFlag = true; } else {
          setBrowserFlag = false
        }
        break;
      case 'opera':
        if (browserNum < '90') { setBrowserFlag = true; } else {
          setBrowserFlag = false
        }
        break;
      case 'ios':
        setBrowserFlag = false;
        break;
      case 'android':
        setBrowserFlag = false;
        break;
      case 'crios':
        setBrowserFlag = false;
        break;
      case 'fban':
        setBrowserFlag = false;
        break;
      case 'fbav':
        setBrowserFlag = false;
        break;
      case 'facebook':
        setBrowserFlag = false;
        break;
      case 'fxios':
        setBrowserFlag = false;
        break;
      case 'instagram':
        setBrowserFlag = false;
        break;
      default:
        setBrowserFlag = true;
        break;
    }
    this.setState({ browserFlag: setBrowserFlag })
  }

  closeModalHandler = () => {
    this.props.onTriggerModal(false);
  }

  render() {
    let content = null;
    const { location } = this.props;

    if (!this.state.browserFlag) {
      content = (
        <>
          <MainMenu deviceWindow={this.props.device.deviceWindow} />
          <Measure bounds>
            {({ measureRef, contentRect }) => (
              <Spring
                native
                from={{ height: contentRect.bounds.height || -1 }}
                to={{ height: contentRect.bounds.height || -1 }}>
                {styles => (
                  <StyledAppInner style={styles}>
                    <div className="content" ref={measureRef}>
                      <Transition
                        native
                        config={config.slow}
                        items={location}
                        keys={location.pathname}
                        from={{ transform: 'translate3d(0, 100px, 0)', opacity: 0 }}
                        enter={{ transform: 'translate3d(0, 0px, 0)', opacity: 1 }}
                        leave={{ transform: 'translate3d(0, -50px, 0)', opacity: 0 }}>
                        {(loc, state) => style => (
                          <StyledAnimatedTransition style={style}>
                            <Switch location={state === 'update' ? location : loc}>
                              <Route exact path="/campaigns/:name" render={(props) => <Campaigns
                                {...props}
                                animationState={state} />} />
                              <Route exact path="/components/:name" render={(props) => <RallyComponents
                                {...props}
                                animationState={state} />} />
                              <Route path="/" exact render={(props) => {
                                return <Home
                                  {...props}
                                  deviceWindow={this.props.device.deviceWindow}
                                  animationState={state} />
                              }}
                              />
                              <Route render={(props) => <FourOhFour
                                {...props}
                                animationState={state} />}
                                />
                            </Switch>
                          </StyledAnimatedTransition>
                        )}
                      </Transition>
                    </div>
                  </StyledAppInner>
                )}
              </Spring>
            )}
          </Measure>
          <Footer />
          <Modal show={this.props.modal.isOpen === 'about'} modalClosed={this.closeModalHandler}>
            <About />
          </Modal>
        </>
      );
    }
    return (
      <>
      <ThemeProvider theme={main}>
          {this.state.browserFlag === false ?
          <>
            <GlobalResponsiveTypography />
            <StyledApp
              blurContent={this.props.modal.isOpen}
              lockScroll={this.props.nav.isOpen}>
              {content}
            </StyledApp>
          </>
            :
            <BrowserWarning
              browserItems={this.state.browserList}
            />
          }
      </ThemeProvider>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
      device: state.device,
      modal: state.modal,
      nav: state.nav,
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onTriggerModal: (payload) => dispatch(actionCreators.triggerModal(payload)),
      onModalOffset: (payload) => dispatch(actionCreators.modalOffset(payload)),
      onDetectDevice: (payload) => dispatch(actionCreators.detect(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
