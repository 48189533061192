import NYSlide1 from '../assets/images/HomeNY/RallyNY_BTS_KennyBruno_11.jpg';
import NYSlide2 from '../assets/images/HomeNY/RallyNY_BTS_JeffNesbit_2.jpg';
import NYSlide3 from '../assets/images/HomeNY/RallyNY_BTS_LiamKerr_1.jpg';
import DCSlide1 from '../assets/images/HomeDC/RallyDC_BTS_AnthonySwift_4_2.jpg';
import DCSlide2 from '../assets/images/HomeDC/RallyDC_BTS_DavidMcIntosh_12_2.jpg';
import DCSlide3 from '../assets/images/HomeDC/RallyDC_BTS_Panel_10_2.jpg';

const data = {
    Home: [
        {
            id: 0,
            title: 'Conversations on Change: Part 1',
            description: 'Advocates from the Keystone XL, Tea Party, Massachusetts\' Charter School Cap Ballot Measure, and Net Neutrality campaigns discuss best practices in campaign and issue advocacy.',
            buttonText: 'WATCH',
            buttonLink: '/video1',
            videoUrl: 'https://vimeo.com/333436371/633e25b6d5',
            imageSlideSpeedOffset: 6362,
            imageSlides: [
                {
                    id: 0,
                    url: NYSlide1
                },
                {
                    id: 1,
                    url: NYSlide2
                },
                {
                    id: 2,
                    url: NYSlide3
                }
            ]
        },
        {
            id: 1,
            title: 'Conversations on Change: Part 2',
            description: 'Advocates from the Health Care reform, Tea Party, and Keystone XL campaigns discuss the changing environment for issue advocacy and how campaigns can adapt and learn from each other.',
            buttonText: 'WATCH',
            buttonLink: '/video2',
            videoUrl: 'https://vimeo.com/334072252/3e12f11dae',
            imageSlideSpeedOffset: 8102,
            imageSlides: [
                {
                    id: 0,
                    url: DCSlide1
                },
                {
                    id: 1,
                    url: DCSlide2
                },
                {
                    id: 2,
                    url: DCSlide3
                }
            ]
        }
    ],
}

export const HOME = data.Home;