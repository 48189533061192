/*
 * FourOhFour Stateful Component
 * This component contains the 404.
 */

import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import ButtonLink from '../../components/UI/Button/ButtonLink.js';
import { animated, Spring } from 'react-spring/renderprops';

const StyledFourOhFour = styled.div`
  ${props => {
    if (props.animationState === 'leave') {
      return `
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      `;
    }
  }};
`;

const StyledFourOhFourContainer = styled(animated.div)`
  position:relative;
`

const StyledOuterDiv = styled.div`
  height: calc(100vh - 60px);
  min-height: 100%;
  background-color: ${props => props.theme.colors.red};
  width: 65%;
  position:relative;
`;

const StyledInnerDiv = styled.div`
  top: 30%;
  position:absolute;
  display:flex;
  flex-direction: row;
  align-content: flex-end;
  flex-wrap: wrap;
  width: 70%;
  right: 0;

  h2 {
    font-size: 5rem;
    font-weight:bold;
    margin: 0;
    color: ${props => props.theme.colors.white};
    width: 100%;
  }
  p {
    color: ${props => props.theme.colors.white};
    width: 100%;
    margin: 0 0 4rem 0;
  }
  a {
    text-align:center;
    width: 100%;
    max-width: 300px;
  }
`;

class FourOhFour extends Component {

  render() {
    return (
      <StyledFourOhFour animationState={this.props.animationState}>
        <Helmet title={'Oh No! Page Not Found'} />
        <Spring
          native
          from={{ transform: 'translate3d(0, 100px, 0)', opacity: 0 }}
          to={{ transform: 'translate3d(0, 0px, 0)', opacity: 1 }}
          delay={500}>
          {styles => (
            <StyledFourOhFourContainer style={styles}>
              <StyledOuterDiv>
                <StyledInnerDiv>
                  <h2>404: Page Not Found</h2>
                  <p>Keep pushing through the noise! There's tons more to explore here.</p>
                  <ButtonLink label="GET BACK ON TRACK" to="/" type="ghost" color="white" />
                </StyledInnerDiv>
              </StyledOuterDiv>
            </StyledFourOhFourContainer>
          )}
        </Spring>

      </StyledFourOhFour>
    )  
  }
}

export default FourOhFour;