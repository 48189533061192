import React from 'react';
import styled from 'styled-components';

const StyledLogoHorizontal = styled.svg`
  fill: ${props => props.theme.colors.white};
  width: 100%;
  max-width: 200px;
  height: 100%;
  max-height: 20px;
  transition: fill 0.3s;
`;


const logoHorizontal = ( props ) => {
  return (
    <StyledLogoHorizontal viewbox='0 0 192.2 19'>
      <path d='M10.3 10.2c0 .8-.6 1.5-1.5 1.5-.8 0-1.4-.6-1.5-1.4V1.5C7.4.7 8 0 8.8 0s1.5.7 1.5 1.5v8.7z'/>
      <path d='M8.8 19c4.9 0 8.8-3.9 8.8-8.8 0-2.3-.9-4.6-2.6-6.3-.6-.6-1.5-.6-2.1 0-.6.6-.6 1.5 0 2.1 2.3 2.3 2.3 6 0 8.3-2.3 2.3-6 2.3-8.3 0-2.3-2.3-2.3-6 0-8.3.6-.6.6-1.5 0-2.1-.5-.4-1.4-.4-2 .1-3.4 3.4-3.5 9 0 12.4C4.2 18.1 6.5 19 8.8 19'/>
      <path d='M34.8 1.8c-.8 0-1.4.6-1.5 1.4v10L24.1 2.4c-.2-.4-.7-.6-1.1-.6-.8 0-1.4.6-1.5 1.4v14c0 .8.7 1.5 1.5 1.5s1.5-.7 1.5-1.5V7.3l9.2 10.9c.3.3.7.5 1.1.5.8 0 1.4-.6 1.5-1.4v-14c0-.8-.6-1.4-1.5-1.5.1 0 .1 0 0 0'/>
      <path d='M56.2 10.2c.1-1.6-1.2-3-2.8-3h-.1c-1.6 0-2.9 1.3-2.8 2.9v.1c-.1 1.6 1.2 3 2.8 3h.1c1.5 0 2.8-1.2 2.8-2.8v-.2m-7.3 0c0-2.4 1.9-4.4 4.3-4.4h.1c2.4-.1 4.4 1.8 4.4 4.2v.2c0 2.4-1.9 4.4-4.3 4.4h-.1c-2.3.1-4.3-1.7-4.4-4v-.4'/>
      <path d='M60.7 6h1.6l2.6 4 2.6-4h1.6v8.5h-1.5V8.4l-2.7 4h-.1l-2.6-3.9v6.1h-1.5V6z'/>
      <path d='M72.6 6h1.5v8.5h-1.5z'/>
      <path d='M80.7 13.1c1.5.1 2.9-1 3-2.5v-.3c.1-1.6-1.2-2.9-2.7-2.9h-2v5.7h1.7zM77.5 6h3.2c2.3-.1 4.3 1.6 4.4 4v.3c0 2.3-1.9 4.2-4.2 4.2h-3.5V6z'/>
      <path d='M89.9 11.1L86.5 6h1.7l2.4 3.7L93 6h1.7l-3.4 5.1v3.4h-1.5l.1-3.4z'/>
      <path d='M100.9 11.1l-1.5-3.4-1.4 3.4h2.9zM98.7 6h1.4l3.7 8.6h-1.6l-.8-2.1h-4l-.9 2.1H95L98.7 6z'/>
      <path d='M110.1 10.2c1 0 1.7-.6 1.7-1.5s-.7-1.4-1.7-1.4H108v2.8h2.1zM106.4 6h3.8c.9 0 1.7.3 2.4.8.5.5.7 1.2.7 1.9 0 1.2-.8 2.3-2 2.6l2.3 3.3h-1.7l-2.1-3h-1.9v3h-1.5V6z'/>
      <path d='M120.5 6h1.4l4.6 5.9V6h1.4v8.5h-1.2L122 8.4v6.1h-1.4V6z'/>
      <path d='M131 6h6.3v1.3h-4.8v2.2h4.3v1.3h-4.3v2.3h4.9v1.4H131V6z'/>
      <path d='M142.5 7.4h-2.7V6h6.9v1.4H144v7.1h-1.5V7.4z'/>
      <path d='M148.5 6h1.7l2 6.3 2.1-6.4h1.3l2.1 6.4 2-6.3h1.6l-3 8.5H157l-2.1-6.1-2.1 6.1h-1.3l-3-8.5z'/>
      <path d='M170.2 10.2c.1-1.6-1.2-3-2.8-3h-.1c-1.6 0-2.8 1.3-2.8 2.8v.1c-.1 1.6 1.2 3 2.8 3h.1c1.5 0 2.8-1.2 2.8-2.8v-.1m-7.3 0c0-2.4 1.9-4.4 4.3-4.4h.1c2.4-.1 4.4 1.8 4.4 4.2v.2c0 2.4-1.9 4.4-4.3 4.4h-.1c-2.3.1-4.3-1.7-4.4-4v-.4'/>
      <path d='M178.2 10.2c1 0 1.7-.6 1.7-1.5s-.6-1.4-1.7-1.4H176v2.8h2.2v.1zM174.5 6h3.8c.9 0 1.7.3 2.4.8.5.5.7 1.2.7 1.9 0 1.2-.8 2.3-2 2.6l2.3 3.3H180l-2.1-3H176v3h-1.5V6z'/>
      <path d='M184.7 6h1.5v4.2l4.1-4.2h1.8l-3.5 3.6 3.7 4.9h-1.8l-2.9-3.9-1.4 1.4v2.5h-1.5V6z'/>
    </StyledLogoHorizontal>
  );
}

export default logoHorizontal;
