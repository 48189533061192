import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.white};
  margin: 0.5rem;
  text-decoration: none;
  transition: color 0.3s;

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.yellow};
  }

  &:active {
    color: ${props => props.theme.colors.yellowActive};
  }
`;


const link = (props) => {
  return (
    <StyledLink to={props.path}>
      {props.label}
    </StyledLink>
  );
}

export default link;
