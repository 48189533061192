/*
 * App Fonts
 * This object provides an easy way to reference the body and header font
 * families.
 */

 const fonts = {
   header: '"BenchNine", sans-serif',
   body: '"Helvetica Neue", -apples-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif',
 }

 export default fonts;
