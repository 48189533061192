import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledCampaignLink = styled(Link)`
  border: 1px solid ${props => props.textcolor};
  width: 100%;
  display: flex;
  flex-flow: column;
  padding: 1rem;
  max-width: 400px;
  margin: auto;
  box-sizing: border-box;
  color: ${props => props.textcolor};
  text-decoration: none;
  transition: color 0.3s, transform 0.3s;
  backface-visibility: hidden;
  text-align:center;

  &:hover,
  &:focus {
    transform: scale3d(1.05, 1.05, 1.05);
    color: ${props => props.textcolor};
  }

  &:hover h3,
  &:focus h3 {
    letter-spacing: 0.2rem;
  }

  &:active {
    transform: scale3d(1.08, 1.08, 1.08);
  }
`;

const StyledTitle = styled.h3`
  margin: 0.5rem;
  transition: letter-spacing 0.3s;
`;

const campaignLink = (props) => {
  return (
    <StyledCampaignLink to={props.path} textcolor={props.textColor}>
      <StyledTitle>{props.title}</StyledTitle>
    </StyledCampaignLink>
  );
}

export default campaignLink;
