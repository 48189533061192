import React from 'react';
import styled from 'styled-components';

import { FOOTER } from '../../data/data';
import Link from '../UI/Link/Link';
import pdf from '../../assets/report.pdf';

const StyledFooterLinks = styled.div`
  a {
    color: ${props => props.theme.colors.white};
    margin: 0.5rem;
    text-decoration: none;
    transition: color 0.3s;

    &:hover {
      color: ${props => props.theme.colors.yellow};
    }
    &:focus, &:active {
      color: ${props => props.theme.colors.yellowActive};
    }
  }
  a:nth-child(1) {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: -0.5rem;
      top: 0.1rem;
      height: 100%;
      width: 1px;
      background-color: ${props => props.theme.colors.white};
    }
  }

  a:nth-child(2) {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: -0.5rem;
      top: 0.1rem;
      height: 100%;
      width: 1px;
      background-color: ${props => props.theme.colors.white};
    }
  }
`;

const footerLinks = (props) => {
  return (
    <StyledFooterLinks>
      {FOOTER.links.map((item) => (
        item.external === true ?
          <a href={ item.label === 'Download' ? pdf : item.path } 
             key={item.id}
             target={item.new ? "_BLANK" : "_SELF"}
          >
            {item.label}
          </a>
          :
          <Link
            key={item.id}
            path={item.path}
            label={item.label} 
            target={item.new ? "_BLANK" : "_SELF"} 
          />
                  
      ))}

    </StyledFooterLinks>
  );
}

export default footerLinks;
