import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import './index.css';
import App from './App';
import ScrollToTop from './ScrollToTop';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import drawerReducer from './store/reducers/drawerReducer';
import deviceDetectReducer from './store/reducers/deviceDetectReducer';
import modalReducer from './store/reducers/modalReducer';
import navReducer from './store/reducers/navReducer';
import menuToggleReducer from './store/reducers/menuToggleReducer';
import BrowserWarning from './components/BrowserWarning/BrowserWarning';
import BROWSER_LIST from './data/constants/BROWSER_LIST';

const WebFontConfig = {
  google: {
    families: ['BenchNine:700'],
  }
};

const rootReducer = combineReducers({
  drawer: drawerReducer,
  device: deviceDetectReducer,
  modal: modalReducer,
  nav: navReducer,
  menuToggle: menuToggleReducer,
});

// @TODO: Remove the dev tools extension when moving to production
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const ie = navigator.userAgent.indexOf('MSIE')!==-1 || navigator.appVersion.indexOf('Trident/') > -1;

const app = (
  ie === false ?
      <WebfontLoader config={WebFontConfig}>
        <Provider store={store}>
          <BrowserRouter>
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </BrowserRouter>
        </Provider>
      </WebfontLoader>
    : <BrowserWarning browserList={BROWSER_LIST} />
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
