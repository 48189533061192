import ms from 'modularscale-js';
import { createGlobalStyle, css } from 'styled-components';

import fonts from './Fonts';

const scaleA = {
  base: [16],
  ratio: 1.5,
};

const scaleB = {
  base: [16],
  ratio: [1.333]
};

const scaleC = {
  base: [16],
  ratio: [1.2]
}


const desktopType = {
  h1: ms(4, scaleA),
  h2: ms(3, scaleA),
  h3: ms(2, scaleA),
  h4: ms(1, scaleA),
};

const tabletType = {
  h1: ms(4, scaleB),
  h2: ms(3, scaleB),
  h3: ms(2, scaleB),
  h4: ms(1, scaleB),
};


const mobileType = {
  h1: ms(4, scaleC),
  h2: ms(3, scaleC),
  h3: ms(2, scaleC),
  h4: ms(1, scaleC),
};

function generateHeaderCSS(typeObject = desktopType) {
  let styles = '';
  for (let i = 1; i < 5; i++) {
    styles += `
      h${i} {
      font-size: ${typeObject[`h${i}`]/16}rem;
      letter-spacing: 0.1rem;
      font-weight: bold;
      font-style: normal;
      text-rendering: optimizeLegibility;
      line-height: 1.2;
      font-family: ${fonts.header};
      text-transform: uppercase;
      transition: font-size 0.3s;
    }
    `
  }
  return css`${styles}`;
}

const GlobalResponsiveTypography = createGlobalStyle`
  ${generateHeaderCSS(mobileType)};

  ${props => props.theme.media.medium`
    ${generateHeaderCSS(tabletType)}
  `}

  ${props => props.theme.media.large`
    ${generateHeaderCSS(desktopType)}
  `}
`;

export default GlobalResponsiveTypography;
