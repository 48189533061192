/*
 * Importing all style objects and exporting them so the ThemeProvider can
 * access them.
 */
import colors from './Colors';
import fonts from './Fonts';
import { media, orientation, height } from './Breakpoints';
import container from './Container';
import { viewportCheck } from './Viewport';

export const main = {
  colors: colors,
  fonts: fonts,
  media: media,
  orientation: orientation,
  height: height,
  container: container,
  viewport: viewportCheck,
};
