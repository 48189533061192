import vhCheck from 'vh-check';


export const viewportCheck = (typeof document !== 'undefined') ?  vhCheck({
  cssVarName: 'vh-offset',
  force: false,
  bind: true,
  redefineVh: false,
  updateOnTouch: false,
  onUpdate: function noop() {},
}) : {
  isNeeded: false,
  offset: 0,
  recompute: null,
  unbind: null,
  value: 0,
  vh: 0,
  windowHeight: 0,
  };
