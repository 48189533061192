import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    deviceWindow: false
};

const deviceDetectReducer = (state = initialState, action) => {
    
    switch(action.type) {
        case actionTypes.DETECT_DEVICE:
          return updateObject(state, 
            {
                deviceWindow: action.payload
            });

        default:
          return state;
      }
};

export default deviceDetectReducer;



