import React from 'react';
import styled from 'styled-components';

const StyledCampaignImageWrapper = styled.div`
  position: relative;
  margin: 0 auto;

  ${props => props.theme.media.medium`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 0;
    margin: 0 auto;
  `}

  ${props => props.theme.media.large`
    position: ${props => props.height ? 'relative' : 'absolute'};
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: ${props => props.height ? 0 : '56.25%'};
  `}
`;

const StyledCampaignImage = styled.img`
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
  max-height: 100%;

  ${props => props.theme.media.medium`
    max-height: 400px;
  `}

  ${props => props.theme.media.large`
    max-height: 100%;
  `}
`;

const campaignImage = (props) => {
  return (
    <StyledCampaignImageWrapper height={props.height}>
      <StyledCampaignImage src={props.imageURL} />
    </StyledCampaignImageWrapper>

  );
}

export default campaignImage;
