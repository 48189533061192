
/*
 * MenuItem Functional Component
 * This component parses the actual Menu Item Links.
 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(NavLink)`
  font-family: ${props => props.theme.fonts.header};
  font-size: 1.2rem;
  margin: 0.2rem 1.5rem 0.2rem 0.2rem;
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  position: relative;
  display:inline-block;

  &::before {
    border-style: solid;
    border-width: 0.15rem 0.15rem 0 0;
    content: '';
    display: inline-block;
    height: 0.45rem;
    right: -1rem;
    position: absolute;
    vertical-align: top;
    width: 0.45rem;
    top: calc(50% - 0.45rem);
    transform: rotate(135deg);
    color: ${props => props.theme.colors.yellowActive};
  }
  &:hover:before {
    color: ${props => props.theme.colors.yellowActive};
  }

  span {
    position: relative;
    transition: color 0.4s;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0.1rem;
      width: 0px;
      height: 2px;
      margin: 5px 0 0;
      transition: all 0.4s ease-in-out;
      opacity: 0;
    }

    &::before {
      left: calc(50%);
      background-color: ${props => props.theme.colors.yellowActive};
    }

    &::after {
      right: calc(50%);
      background-color: ${props => props.theme.colors.yellowActive};
    }
  }


  &:hover span,
  &:focus span {
    color: ${props => props.theme.colors.yellow};
  }

  &:hover span::before,
  &:focus span::before,
  &:hover span::after,
  &:focus span::after {
    width: 50%;
    opacity: 1;
  }

  &:active span {
    color: ${props => props.theme.colors.yellowActive};
  }

  &:active span::before,
  &:active span::after {
    background-color: ${props => props.theme.colors.yellowActive};
  }

  &.active {
    color: ${props => props.theme.colors.yellowActive};

    &:hover span,
    &:focus span {
      color: ${props => props.theme.colors.grayHover};
    }

    &:active span {
      color: ${props => props.theme.colors.grayActive};
    }

    & span::before,
    & span::after {
      background-color: ${props => props.theme.colors.yellowActive};
      opacity: 1;
      width: 50%;
    }

    &:hover span::before,
    &:focus span::before,
    &:hover span::after,
    &:focus span::after {
      background-color: ${props => props.theme.colors.grayHover};
    }

    & span:active::before,
    & span:active::after {
      background-color: ${props => props.theme.colors.grayActive};
    }
  }
  ${props => props.theme.media.small`
    font-size: 1.2rem;
  `};

  ${props => props.theme.media.medium`
    font-size: 1.2rem;
    margin: 0.2rem 2rem 0.2rem 1rem;

    &::before {
      border-style: solid;
      border-width: 0.15rem 0.15rem 0 0;
      content: '';
      display: inline-block;
      height: 0.45rem;
      right: -1rem;
      position: absolute;
      vertical-align: top;
      width: 0.45rem;
      top: calc(50% - 0.45rem);
      transform: rotate(135deg);
      color: ${props => props.theme.colors.yellowActive};
    }
  `}
`;

const menuItemDrawer = React.memo(( props ) => {
  return (
    <StyledLink
      to={props.path}
      onClick={props.clicked}
      exact={true}>
      <span>{props.text}</span>
    </StyledLink>
  )
});

export default menuItemDrawer;
