import React from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

const StyledCTA = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 2rem 1rem 0 0;
  text-align: center;
  position: relative;
  overflow: hidden;
  padding: 0 1rem;

  img {
    max-width: 80%;
    cursor:pointer;
  }
`;

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 15}px,${y / 15}px,0)`;

const campaignInfographic = (props) => {
    const [offsetAnimation, set] = useSpring(() => ({
        xy: [0, 0],
        config: {
          mass: 10,
          tension: 550,
          friction: 140 }
        }));
        return(
        <StyledCTA
            onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
            onMouseOut={() => set({xy: [0, 0]})}
            style={{ transform: offsetAnimation.xy.interpolate(trans1) }}
        >
            <img src={props.thumbnail} alt="Infographic" onClick={() => props.clickedInfographic(props.source)} />
        </StyledCTA>
    );
}

export default campaignInfographic;
