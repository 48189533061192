import React from 'react';
import styled from 'styled-components';

import Button from '../Button/Button';


const StyledCTAVideo = styled.div`
  background-color: ${props => props.theme.colors.blackOverlay};
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  max-width: 550px;
  margin: 0 auto 2rem auto;
  text-align: center;
  z-index: 1;

  ${props => props.theme.media.medium`
    max-width: 450px;
  `}

  ${props => props.theme.media.large`
    max-width: 450px;
  `}

  ${props => props.theme.media.xlarge`
    max-width: 80%;
  `}
  
  button {
    color: ${props => props.theme.colors.white};
  }
`;

const StyledTitle = styled.h2`
  color: ${props => props.theme.colors.white};
  width: 100%;
  margin: 0 0 2rem 0;
`;

const StyledDescription = styled.p`
  color: ${props => props.theme.colors.white};
  width: 100%;
  margin: 0 0 2rem 0;
`;

const CTAVideo = (props) => {

  return (
    <StyledCTAVideo>
      <StyledTitle>{props.title}</StyledTitle>
      {props.description ? 
          <StyledDescription>{props.description}</StyledDescription>
        : ''
      }
      <Button
        label={props.buttonText}
        clicked={props.clickedVideo} />
    </StyledCTAVideo>
  );
}

export default CTAVideo;
