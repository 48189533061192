import React from 'react';
import styled from 'styled-components';

const StyledCampaignHalfText = styled.div`
  margin: 1rem 0.5rem;
  box-sizing: border-box;
  width: 100%;
  order: 1;

  ${props => props.theme.media.large`
    margin: 2rem;
    order: ${props => props.position === 'left' ? '1' : '2'};
    width: calc(50% - 4rem);
  `}
`;

const campaignHalfText = (props) => {
  return (
    <StyledCampaignHalfText position={props.position}>
      {props.children}
    </StyledCampaignHalfText>
  );
}

export default campaignHalfText;
