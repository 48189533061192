import React from 'react';
import styled from 'styled-components';

const StyledCampaignSection = styled.section`
  display: flex;
  flex-flow: row wrap;
  box-sizing: border-box;
  position: relative;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  margin: auto;
  padding: 2rem 1rem;

  ${props => props.theme.media.large`
    padding: 1rem;
  `}
`;

const campaignSection = (props) => {
  return (
    <StyledCampaignSection
      textColor={props.textColor}
      backgroundColor={props.backgroundColor}
      style={props.style}>
      {props.children}
    </StyledCampaignSection>
  );
}

export default campaignSection;
