
/*
 * CardNavigationItem Functional Component
 * This component parses the actual Card Navigation Item Links.
 */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';


const StyledCardNavigationItem = styled(Link)`
  text-decoration: none;
  flex: 1 0 50%;
  position: relative;
  height: 100vh;
  max-height: 250px;
  min-height: 150px;
  color: ${props => props.theme.colors.white};

  ${props => props.theme.media.medium`
    max-height: 450px;
    min-height: 350px;
  `}

  ${props => props.theme.media.large`
    flex: 1 0 25%;
    max-height: ${props => props.fluid ? '50%' : '350px'};
    overflow: hidden;
  `}
`;

const StyledBackgroundImage = styled.img`
  object-fit: cover;
  object-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const StyledColorTile = styled(animated.div)`
  width: 101%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  background-color: ${props => {
    switch(props.color) {
      case 'green':
        return props.theme.colors.greenTransparent;
      case 'yellow':
        return props.theme.colors.yellowTransparent;
      case 'purple':
        return props.theme.colors.purpleTransparent;
      case 'blue':
        return props.theme.colors.blueTransparent;
      default:
        return props.theme.colors.redTransparent;
    }
  }};
`;

const StyledCardContent = styled.div`
  position: relative;
  padding: 1rem 3rem;
  max-width: 80%;
  top: 40%;

  ${props => props.theme.media.medium`
    text-align:left;
    top: 20%;
  `}

  ${props => props.theme.media.large`
    text-align:left;
    top: 20%;
  `}

`;

const StyledCardTitle = styled.h3`
  margin: 0;
  text-align:center;

  ${props => props.theme.media.medium`
    text-align:left;
    font-size: 1.75rem;
  `}

  ${props => props.theme.media.large`
    text-align:left;
  `}
`;

const StyledCardDescription = styled.p`
  display: ${props => props.device === 'mobile' ? 'none': 'block'};
  margin-bottom: 0;
`;

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 15}px,${y / 15}px,0)`;

const CardNavigationItem = (props) => {
  const [tileAnimation, set] = useSpring(() => ({
    xy: [0, 0],
    config: {
      mass: 10,
      tension: 550,
      friction: 140 }
    }));


  return (
    <StyledCardNavigationItem
      to={props.path}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      onMouseOut={() => set({xy: [0, 0]})}
      fluid={props.fluid ? 1 : 0}>
      <StyledBackgroundImage src={props.image} />
      <StyledColorTile color={props.color} style={{ transform: tileAnimation.xy.interpolate(trans1) }}>
        <StyledCardContent>
          <StyledCardTitle>{props.title}</StyledCardTitle>
          <StyledCardDescription device={props.device}>{props.description}</StyledCardDescription>
        </StyledCardContent>
      </StyledColorTile>
    </StyledCardNavigationItem>
  );
}

export default CardNavigationItem;
