import React from 'react';
import styled from 'styled-components';

const StyledSubtitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-items: flex-end;
`;

const StyledCampaignSubtitle = styled.h3`
  width: auto;
  margin: 1rem;
  position: relative;
`;


const campaignSubtitle = (props) => {
  return (
    <StyledSubtitleContainer>
      <StyledCampaignSubtitle color={props.color}>{props.title}</StyledCampaignSubtitle>
    </StyledSubtitleContainer>
  );
}

export default campaignSubtitle;
