const data = {
    Footer: {
        logo: {
          path: 'https://www.omidyar.com/',
        },
        links: [
          {
            id: 0,
            label: 'Privacy',
            path: 'https://www.omidyar.com/privacy-policy',
            new: true,
            external: true
          },
          {
            id: 1,
            label: 'Contact',
            path: 'https://www.omidyar.com/contact',
            new: true,
            external: true
          },
          {
            id: 2,
            label: 'Download',
            path: '',
            new: true,
            external: true
          }
        ],
      }
}

export const FOOTER = data.Footer;
