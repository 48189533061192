const data = {
    MainMenu: {
        home: {
            id: 0,
            path: '/',
            text: 'Home',
        },
        about: {
            id: 1,
            path: '/about',
            text: 'About'
        },
        pdf: {
            id: 2,
            path: 'report',
            text: 'Report'
        },
        campaigns: {
            id: 3,
            path: '/campaigns',
            text: 'Campaigns'
        },
        components: {
            id: 4,
            path: '/components',
            text: 'Components'
        },
        constructs: {
            id: 5,
            path: '/constructs',
            text: 'Constructs'
        }
    }
}

export const MAIN_MENU = data.MainMenu;
