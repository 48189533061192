import React from 'react';
import ButtonLink from '../../components/UI/Button/ButtonLink.js';
import styled from 'styled-components';
import LinkReplacements from '../../components/RallyComponents/LinkReplacements.js';

const StyledHeader = styled.h3`
  color: ${props => props.theme.colors.white};
`;

const StyledParagraph = styled.p`
  color: ${props => props.theme.colors.white};
`;

const drawercontent = (props) => {
    return (
      <>
        <StyledHeader>{props.data.title}</StyledHeader>
        {Object.values(props.data.content).map((item, index) => (
          <StyledParagraph key={index}>
            <LinkReplacements key={index} text={item} highlights={props.highlights} replacements={props.replacements} textcolor="white" wrap={false} />
          </StyledParagraph>
        ))}
        <ButtonLink id={props.data.id} label={props.data.buttonTitle} to={props.data.buttonLink} type="ghost" color="white" />
      </>
    );
}
export default drawercontent;
