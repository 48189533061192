/*
 * CardNavigation Functional Component
 * This component contains the Card Navigation Menu.
 */
import React from 'react';
import styled from 'styled-components';

import CardNavigationItem from './CardNavigationItem';

const StyledCardNavigationItem = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
`;

const cardNavigation = ( props ) => {
  return (
    <StyledCardNavigationItem className={props.className}>
      {props.data.map((item) => (
        <CardNavigationItem
          key={`${item.id}_${item.title}`}
          title={item.title}
          color={item.color}
          description={item.description}
          image={item.image}
          path={item.path}
          device={props.device}
          fluid={props.fluid}/>
      ))}
    </StyledCardNavigationItem>
  );
};

export default cardNavigation;
