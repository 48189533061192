import React from 'react';
import PropTypes from 'prop-types';

import CampaignImage from './CampaignImage';
import CTA from '../UI/CTA/CTA';


const campaignMediaGenerator = (props) => {
  switch(props.type) {
    case ('image'):
      return <CampaignImage
        imageURL={props.data.imageUrl}
        height={props.height} />;
    case ('video'):
      return <CTA
        title={props.data.title}
        clickedVideo={() => props.clickedVideo(props.data.videoUrl)}
        imageURL={props.data.backgroundImage}
        description={props.data.description}
        buttonLabel={props.data.buttonLabel} />;
    default:
      return '';
  }
}

campaignMediaGenerator.propTypes = {
  type: PropTypes.string.isRequired,
}

export default campaignMediaGenerator;
