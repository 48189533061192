import React from 'react';
import styled from 'styled-components';

import VideoPanel from './VideoPanel';

const StyledVideoPanels = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
`;


const videoPanels = React.memo((props) => {
  return (
    <StyledVideoPanels>
      {props.data.map(videoPanel => (
        <VideoPanel
          key={videoPanel.id}
          id={videoPanel.id}
          title={videoPanel.title}
          imageSlideSpeedOffset={videoPanel.imageSlideSpeedOffset}
          description={videoPanel.description}
          buttonText={videoPanel.buttonText}
          buttonLink={videoPanel.buttonLink}
          videoUrl={videoPanel.videoUrl}
          imageSlides={videoPanel.imageSlides}
          clickedVideo={() => props.clickedVideo(videoPanel.videoUrl)} />
      ))}

    </StyledVideoPanels>
  );
})

export default videoPanels;
