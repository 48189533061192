/*
 * MainMenu Functional Component
 * This component contains the Main Menu.
 */
import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { MAIN_MENU, CAMPAIGNS, RALLY_COMPONENTS } from '../../data/data';
import MenuItem from '../../components/MenuItems/MenuItem.js';
import MenuLink from '../../components/MenuItems/MenuLink.js';
import MenuItemDrawer from '../../components/MenuItems/MenuItemDrawer';
import MenuToggle from '../../containers/MainMenu/MenuToggle';
import ContentContainer from '../../components/ContentContainer/ContentContainer';
import Constructs from '../Constructs/Constructs';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import CardNavigationMenu from '../../components/CardNavigation/CardNavigationMenu';
import pdf from '../../assets/report.pdf';


const CAMPAIGN_CARDS = Object.keys(CAMPAIGNS).map((key) => {
    return {
        id: CAMPAIGNS[key].id,
        title: CAMPAIGNS[key].title,
        color: CAMPAIGNS[key].color,
        description: CAMPAIGNS[key].cardDescription,
        image: CAMPAIGNS[key].backgroundImage,
        path: key,
    };
});

const RALLY_COMPONENTS_CARDS = Object.keys(RALLY_COMPONENTS).map((key) => {
    return {
        id: RALLY_COMPONENTS[key].id,
        title: RALLY_COMPONENTS[key].title,
        color: RALLY_COMPONENTS[key].color,
        description: RALLY_COMPONENTS[key].cardDescription,
        image: RALLY_COMPONENTS[key].backgroundImage,
        path: key,
    };
});

const StyledMainMenu = styled.nav`
  background-color: ${props => props.theme.colors.red};
  display: flex;
  flex-flow: row wrap;
  margin: 0 0 0 auto;
  justify-content: flex-end;
  padding-right: 1rem;
  height: 40px;
  width: ${props => props.devicewindow === 'desktop' ? '570px' : '205px'};

  ${props => props.theme.media.medium`
    margin: 0 0 0 auto;
    padding-right: 1rem;
    justify-content: flex-end;
  `}
`;

const StyledMenuHeading = styled.h1`
  margin: 0;
  padding: 0;
  outline: 0;
  font-size: 1.7rem;
`;

const StyledContentContainer = styled(ContentContainer)`
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: auto;

  ${props => props.theme.media.medium`
    left: auto;
    max-width: ${props => props.theme.container};
    margin: auto;
  `}

  ${props => props.theme.media.xlarge`
    left: 0;
    right: 0;
  `}
`;

const StyledMenuContainer = styled.div`
  width: auto;
  display: inline-block;
  right: 0;
  background: ${props => props.theme.colors.red};
  position: ${props => props.devicewindow === 'desktop' ? 'relative' : 'absolute'};
  top: ${props => props.devicewindow === 'desktop' ? '0' : '33px'};
  right: ${props => props.devicewindow === 'desktop' ? '0' : 'absolute'};
  width: ${props => props.devicewindow === 'desktop' ? 'auto' : '221px'};
  padding-top: ${props => props.devicewindow === 'desktop' ? '0' : '.5rem'};
`;

class MainMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: MAIN_MENU,
      navData: CAMPAIGN_CARDS,
      navTitle: null,
      keys: { 37: 1, 38: 1, 39: 1, 40: 1, 32: 1}
    };
  }

  preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault)
        e.preventDefault();
    e.returnValue = false;
  }

  preventDefaultForScrollKeys(e) {
      if (this.state.keys[e.keyCode]) {
          this.preventDefault(e);
          return false;
      }
  }

  disableScroll() {
    if (window.addEventListener) // older FF
        window.addEventListener('DOMMouseScroll', this.preventDefault, false);
    document.addEventListener('wheel', this.preventDefault, {passive: false}); // Disable scrolling in Chrome
    window.onwheel = this.preventDefault; // modern standard
    window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
    window.ontouchmove  = this.preventDefault; // mobile
    document.onkeydown  = this.preventDefaultForScrollKeys;
  }

  enableScroll() {
    if (window.removeEventListener)
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    document.removeEventListener('wheel', this.preventDefault, {passive: false}); // Enable scrolling in Chrome
    window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;
  }

  componentDidMount() {
    this.triggerCampaignsNav();
    this.triggerComponentsMenu();
  }

  shouldComponentUpdate(nextProps, nextState) {
    //return nextState.menu !== this.state.menu || nextState.showNav !== this.state.showNav;
    return true;
  }

  closeNavHandler = () => {
    this.enableScroll();
    this.props.onTriggerNav(this.props.nav.isOpen);
  }

  //TRIGGER Opening of Drawer.
  clickedDrawerMenuHandler = (event) => {
    event.preventDefault();
    this.props.onTriggerDrawer(this.props.drawer.isOpen);
    this.props.onHighlight({isHighlighted: false, highlightItem: false});
  }

  setCampaigns(itemName = null) {
    this.setState({
      navData: CAMPAIGN_CARDS,
      navTitle: itemName,
    });

    if(this.props.device.deviceWindow !== "desktop") {
      window.scrollTo(0, 0)
    }

    this.disableScroll();
    this.props.onTriggerNav(this.props.nav.isOpen);
  }

  setRallyComponents(itemName = null) {
    this.setState({
      navData: RALLY_COMPONENTS_CARDS,
      navTitle: itemName,
    });
    if(this.props.device.deviceWindow !== "desktop") {
      window.scrollTo(0, 0)
    }
    this.disableScroll();
    this.props.onTriggerNav(this.props.nav.isOpen);
  }

  clickedMenuItemHandler = (event, itemName) => {
    event.preventDefault();
    // Catch campaign menu click and trigger actions.
    if (itemName === 'campaigns') {
      this.setCampaigns(itemName);
    }
    // Catch components menu click and trigger actions.
    else if (itemName === 'components') {
      this.setRallyComponents(itemName);
    }
    this.closeMobile();
  }

  triggerAboutNav(event) {
    event.preventDefault();
    this.props.onTriggerAbout('about');
    this.closeMobile();
  }

  triggerCampaignsNav() {
    if (this.props.location.pathname === '/campaigns' ||
        this.props.location.pathname === '/campaigns/') {
          this.setCampaigns();
    }
  }

  triggerComponentsMenu() {
    if (this.props.location.pathname === '/components' ||
        this.props.location.pathname === '/components/') {
          this.setRallyComponents();
    }
  }
  closeMobile() {
    this.props.onMenuToggle("closed")
  }

  render() {
    let content = [];
    let constructsNav;

    Object.values(this.state.menu).forEach(item => {
      switch(item.path) {
        case ('/constructs'):
          constructsNav =
            <MenuItemDrawer
              key={item.id}
              path={item.path}
              text={item.text}
              devicewindow={this.props.deviceWindow}
              clicked={
                (event) => {
                  this.clickedDrawerMenuHandler(event)
                  this.props.onSetSlideIndex({slideIndex: false})
                  this.closeMobile()
                }
              }
            />;
          break;
        case ('/about'):
          content.push(
            <MenuItem
              key={item.id}
              path={item.path}
              text={item.text}
              devicewindow={this.props.deviceWindow}
              clicked={(event) => this.triggerAboutNav(event)}/>
          );
          break;
        case ('report'):
          content.push(
            <MenuLink
              clicked={() => this.closeMobile()}
              key={item.id}
              path={pdf}
              text={item.text}
              devicewindow={this.props.deviceWindow}
              target="_blank"
              />
          );
          break;
        case ('/campaigns'):
        case ('/components'):
          content.push(
            <MenuItem
              key={item.id}
              path={item.path}
              text={item.text}
              devicewindow={this.props.deviceWindow}
              clicked={(event) => this.clickedMenuItemHandler(event, Object.keys(this.state.menu)[item.id])}/>
          );
          break;
        default:
          content.push(
            <MenuItem
              key={item.id}
              path={item.path}
              devicewindow={this.props.deviceWindow}
              text={item.text}
              clicked={() => this.closeMobile()}
              />
            );
          }
    });
    return (
      <>
        <CardNavigationMenu
          show={this.props.nav.isOpen}
          closeNav={this.closeNavHandler}
          data={this.state.navData}
          title={this.state.navTitle}
          deviceWindow={this.props.deviceWindow} />
        <Constructs show={this.props.drawer.isOpen}>
          {constructsNav}
        </Constructs>
        <StyledContentContainer>
          <StyledMainMenu
            devicewindow={this.props.deviceWindow}
          >
            <StyledMenuHeading>
              <StyledMenuContainer
                devicewindow={this.props.deviceWindow}
              >
                {this.props.device.deviceWindow === "desktop" ?
                  content
                : this.props.menuToggle.menuToggle !== "closed" ?
                  content
                  :
                  ""
                }
              </StyledMenuContainer>
              {constructsNav}
              {this.props.device.deviceWindow !== "desktop" ?
                <MenuToggle />
              : ""
              }
            </StyledMenuHeading>
          </StyledMainMenu>
        </StyledContentContainer>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
      drawer: state.drawer,
      nav: state.nav,
      modal: state.modal,
      menuToggle: state.menuToggle,
      device: state.device
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onTriggerAbout: (payload) => dispatch(actionCreators.triggerModal(payload)),
      onTriggerDrawer: (payload) => dispatch(actionCreators.triggerDrawer(!payload)),
      onHighlight: (payload) => dispatch(actionCreators.highlight(payload)),
      onTriggerNav: (payload) => dispatch(actionCreators.triggerNav(!payload)),
      onSetSlideIndex: (payload) => dispatch(actionCreators.setSlideIndex(payload)),
      onMenuToggle: (payload) => dispatch(actionCreators.menuToggle(payload))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainMenu));