
/*
 * MenuItem Functional Component
 * This component parses the actual Menu Item Links.
 */
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledNavLink = styled(NavLink)`
  font-family: ${props => props.theme.fonts.header};
  font-size: 0.8rem;
  margin: 0.75rem;
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  span {
    position: relative;
    transition: color 0.4s;

    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0.1rem;
      width: 0px;
      height: 2px;
      margin: 5px 0 0;
      transition: all 0.4s ease-in-out;
      opacity: 0;
    }

    &::before {
      left: calc(50%);
      background-color: ${props => props.theme.colors.yellow};
    }

    &::after {
      right: calc(50%);
      background-color: ${props => props.theme.colors.yellow};
    }
  }


  &:hover span,
  &:focus span {
    color: ${props => props.theme.colors.yellow};
  }

  &:hover span::before,
  &:focus span::before,
  &:hover span::after,
  &:focus span::after {
    width: 50%;
    opacity: 1;
  }

  &:active span {
    color: ${props => props.theme.colors.yellowActive};
  }

  &:active span::before,
  &:active span::after {
    background-color: ${props => props.theme.colors.yellowActive};
  }

  &.active {
    color: ${props => props.theme.colors.yellowActive};

    &:hover span,
    &:focus span {
      color: ${props => props.theme.colors.grayHover};
    }

    &:active span {
      color: ${props => props.theme.colors.grayActive};
    }

    & span::before,
    & span::after {
      background-color: ${props => props.theme.colors.yellowActive};
      opacity: 1;
      width: 50%;
    }

    &:hover span::before,
    &:focus span::before,
    &:hover span::after,
    &:focus span::after {
      background-color: ${props => props.theme.colors.grayHover};
    }

    & span:active::before,
    & span:active::after {
      background-color: ${props => props.theme.colors.grayActive};
    }
  }
  position: ${props => props.devicewindow === 'desktop' ? '0.2rem 1rem' : '0.2rem 1rem 0.8rem 2.2rem'};
  display: ${props => props.devicewindow === 'desktop' ? 'inline-block' : 'block'};

  ${props => props.theme.media.small`
    font-size: 1.2rem;
  `}

  ${props => props.theme.media.medium`
    font-size: 1.2rem;
    margin: 0.75rem;
  `}
  ${props => props.theme.media.large`
    margin: 0.2rem 0.5rem;
  `}
`;

const menuItem = React.memo(( props ) => {
  const pageName = props.text.toLowerCase();
  return (
    <StyledNavLink
      className={window.location.href.indexOf(pageName) > -1  ? 'active' : ''}
      to={props.path}
      onClick={props.clicked}
      exact={true}
      devicewindow={props.devicewindow}
    >
      <span>{props.text}</span>
    </StyledNavLink>
  );
});

export default menuItem;
