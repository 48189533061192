/*
 * Browser List Constants Object
 */

const browserList = [
  {
    id: 1,
    browserVersion: 'Edge: 11+',
  },
  {
    id: 2,
    browserVersion: 'Firefox: 62+',
  },
  {
    id: 3,
    browserVersion: 'Safari: 11+',
  },
  {
    id: 4,
    browserVersion: 'Chrome: 68+',
  },
];

export default browserList;
