import React from 'react';
import styled from 'styled-components';

const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  align-items: flex-end;
`;

const StyledCampaignTitle = styled.h2`
  width: auto;
  margin: 1rem;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: ${props => props.color ? props.color : props.theme.colors.white};

    ${props => props.theme.media.medium`
      height: 5px;
    `}
    ${props => props.theme.media.large`
      height: 11px;
    `}

  }
`;


const campaignTitle = (props) => {
  return (
    <StyledTitleContainer>
      <StyledCampaignTitle color={props.color}>{props.title}</StyledCampaignTitle>
    </StyledTitleContainer>
  );
}

export default campaignTitle;
