import React from 'react';
import styled from 'styled-components';

import { FOOTER } from '../../data/data';
import LogoHorizontal from '../Logo/LogoHorizontal';
import FooterLinks from './FooterLinks';
import LinkExternal from '../UI/Link/LinkExternal';
import Userway from '../Footer/Userway';

const StyledFooter = styled.footer`
  background: ${props => props.theme.colors.grayDark};
  width: 100%;
  position: relative;
  z-index: 1;
  min-height: 65px;
  max-height: auto;

  ${props => props.theme.media.medium`
    max-height: 65px;
  `}

  ${props => props.theme.media.large`
    max-height: 65px;
  `}
`;

const StyledFooterInner = styled.div`
  max-width: ${props => props.theme.container};
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  width: 100%;
  box-sizing: border-box;

  ${props => props.theme.media.medium`
    justify-content: space-between;
    padding: 0.5rem 2rem;
  `}
  ${props => props.theme.media.large`
    justify-content: space-between;
    padding: 0.5rem 2rem;
  `}
`;

const StyledLogoSection = styled.div`
  display: inline;
  padding-bottom: 1rem;
`;

const StyledLinksSection = styled.div`
  display: inline;
  padding-bottom: 1rem;
`;

const StyledLinkExternal = styled(LinkExternal)`
  display: flex;
  flex-direction:column;
  margin-top: 0;
  margin-bottom: 0;
  &:hover svg,
  &:focus svg {
    fill: ${props => props.theme.colors.yellow};
  }

  &:active svg {
    fill: ${props => props.theme.colors.yellowActive};
  }
`;

const StyledP = styled.p`
  margin: 0 0 .25rem 0;
  font-size: .9rem;
  font-weight:bold;
`;

const footer = (props) => {
  return (
    <StyledFooter>
      <StyledFooterInner>
        <StyledLogoSection>
          <StyledLinkExternal path={FOOTER.logo.path}>
            <StyledP>Supported By:</StyledP>
            <LogoHorizontal />
          </StyledLinkExternal>
        </StyledLogoSection>
        <StyledLinksSection>
          <FooterLinks />
        </StyledLinksSection>
      </StyledFooterInner>
      <Userway>
      </Userway>
    </StyledFooter>
  );
}

export default footer;
