import visionBackgroundImage from '../assets/images/components/Vision_1.jpg';
import leadershipBackgroundImage from '../assets/images/components/lynnea-tan-1416804-unsplash.jpg';
import structureBackgroundImage from '../assets/images/components/campaign-creators-771723-unsplash.jpg';
import timingBackgroundImage from '../assets/images/components/Timing_1.jpg';
import framingBackgroundImage from '../assets/images/components/chris-hartung-1347150-unsplash.jpg';
import adaptabilityBackgroundImage from '../assets/images/components/Adapatability_1.jpg';
import powerBackgroundImage from '../assets/images/components/Power_4.jpg';
import pressureBackgroundImage from '../assets/images/components/Pressure_1.jpg';

// Start Rally Components Content
const data = {
    RallyComponents: {
        '/components/vision': {
          id: 0,
          title: 'Vision',
          color: 'purple',
          textColor: 'white',
          cardDescription: 'A bold vision can excite a political base and encourage supportive bystanders to take action.',
          backgroundImage: visionBackgroundImage,
          content:[            
            'The saying that “fortune favors the bold" neatly sums up the approaches that succeeded in halting the [Keystone XL] pipeline and in passing [health care reform] in America. Activists understood that a strategy of choosing a transformative goal that challenges the status quo can be more effective than one that focuses on incremental change. A bold vision can excite a political base and encourage supportive bystanders to take action. This is particularly true when it comes to motivating young people and first-time activists. History also has shown that a big, bold idea can cause organizations to put aside their differences and work together towards a common goal. In contentious campaigns, audacious goals can act as a lightning rod that diverts the opposition’s attention and resources, while activists secure concessions that strengthen their cause.',
          ],
            sideContent: [
            {
              id: 0,
              title: 'Keystone',
              content: [
                'At the beginning of the campaign, the outlook was bleak. Most comparable infrastructure projects received “rubber-stamp” approvals and former Secretary of State Hillary Clinton had already indicated she was “inclined” to approve the pipeline. A previous pipeline from Alberta to Illinois had been greenlit, and analysts noted that “the lineup promoting TransCanada’s interests was a textbook study in modern, bipartisan corporate influence peddling.”',
                'Despite facing a daunting uphill battle, the campaign declared a bold and concrete goal: to stop the approval of Keystone XL. This was the goal from the beginning, with no tolerance for those who argued for a more incremental approach, such as making the proposed pipeline safer. This vision also supported a much bigger goal:  Keystone strategists and climate activists “want[ed] Obama to use Keystone as a symbolic opportunity to move America away from fossil fuels.” And, it was successful. This bold vision helped mobilize an active climate movement the U.S. had never seen before, showing it actually was possible to confront the fossil fuel industry and win.',
                'The campaign earned support among those who self-reported that they were “alarmed” by climate change and who believed action needed to be taken to solve the crisis. By refusing to compromise on their goal and setting a tone that was intentionally alarmist, yet motivating, the Keystone campaign captured the energies of those wanting to make a difference on climate issues.'
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/keystone'
            },
            {
              id: 1,
              title: 'Health Care',
              content: [
                'The importance of a bold and visionary goal in the health care movement cannot be overstated. For Health Care for America Now (HCAN), the ultimate goal was universal public health care - which many considered an incredible reach. It was, as leader Richard Kirsch described, at the very edge of the possible. Khalid Pitts, the co-founder of HCAN, also stressed the importance of a transformative goal. No one gets excited about baby steps, he noted, while acknowledging that it is challenging to sustain the energy for bold and sweeping campaigns. ',
                'In a hyper-partisan world, a campaign that strives for incremental change and a middle ground moves few people. HCAN operated on a purely partisan strategy - though there were some attempts to sway wavering Republicans, strategists knew that their only chance at success was winning every single Democratic vote in the Senate. The leaders of HCAN and its funders bet that a passionate constituency that would be willing to mobilize for the Affordable Care Act was more valuable than a bipartisan agreement among the elites.',
                'The public option, where the government provides health coverage in competition with private insurers, had many benefits even if it was unlikely to pass. It’s boldness helped keep the progressive coalition together by drawing a line in the sand for members that defined where they stood. It also helped generate grassroots and grasstops support in individual states. At the same time, it distracted opponents who poured their time and resources into fighting it. While the public option ultimately wasn’t included in the final law, that diversion gave lawmakers the breathing room to include many other provisions that might not have passed otherwise.',
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/healthcare'
    
            }
          ],
          contentLinks: {
            "[Keystone XL]": {
              'link': '/campaigns/keystone',
              'text': 'Keystone XL',
              'new': false
            },
            "[health care reform]" : {
              'link': '/campaigns/healthcare',
              'text': 'health care reform',
              'new': false
            }
          },
        }, // End Vision

        '/components/leadership': {
          id: 0,
          title: 'Leadership',
          color: 'yellow',
          textColor: 'black',
          cardDescription: 'Powerful leaders have always been able to make or break campaigns.',
          backgroundImage: leadershipBackgroundImage,
          content: [
            'Powerful leaders have always been able to make or break campaigns. Charismatic leaders who are exceptional strategic thinkers, have a clear vision, are adaptable, and possess high emotional intelligence have the power to rally and empower supporters and keep campaigns on track in spite of setbacks. Concentrating vast amounts of power in one leader, however, also can be risky for campaigns. As shown with Brexit’s ["Vote Leave"] campaign, powerful leaders can be polarizing, which can create volatile factions that prevent long-term stability or sustainability. On the other hand, leadership teams with diverse backgrounds, like [Health Care for America Now], tend to make better decisions than homogenous teams because they have access to more resources and benefit from a diversity of views.',
            'Scholars underscore the need for leaders in the advocacy space to develop their strategic capacity. Some funders have even begun experimenting with investing in leaders, rather than organizations or issues, arguing that nurturing leaders and their strategic capacity will strengthen a movement’s ecosystem and lead to greater success.',
            'The “Vote Leave” campaign in the UK and the campaign to reform health care in the U.S. represent two drastically different styles of leadership yet they both led to success. Though their methods differed, leaders from both campaigns were gifted strategists with a shrewd understanding of the space and the issue, who were able to craft effective strategies, adapt to changing circumstances, and navigate complex political landscapes. '
          ],
          sideContent: [
            {
              id: 0,
              title: 'Brexit',
              content: [
              'Almost all scholarship and analysis about the "Vote Leave" campaign cites Dominic Cummings\' leadership as the primary reason why the campaign succeeded. Cummings\' iron grip on strategy, willingness to do whatever it takes, and his nimble strategic mind helped propel Vote Leave to an unanticipated victory.',
              'Cummings\' approach - which was once called "periodically benevolent despotism" - did not sit well with some leaders in the movement, who attempted an unsuccessful coup to take control over the campaign. He ultimately survived because key staff sided with him. Cummings combined good intuition and confidence in his strategy with a clear understanding of the base and an ability to utilize data effectively. This combination, as well as his and his team\'s willingness to "risk looking stupid to win," proved lethal. For example, when President Obama came to England in April 2016 and slammed Brexit in a speech, the Leave campaign initially panicked but Cummings marched into the war room and announced that it wouldn’t be an issue. He knew his voters and he was right. Cumming’s marriage of intuition and data was also critical during the process of naming Vote Leave. The team had originally settled on "Vote Leave. Get Change" but the next morning, Cummings came in and declared it to be "Vote Leave. Take Control," because "I thought about it last night. I\'ve done focus groups on this for years. I know this works."',
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/brexit'
            },
            {
              id: 1,
              title: 'Health Care',
              content: [  
                'Health Care for America Now (HCAN) benefitted from a leadership team that brought extensive organizing experience and a commitment to working hand in hand with their partners. On top of his background in organizing, HCAN’s Executive Director, Richard Kirsch, was unique among other nonprofit executives in that he had gone to business school and was trained in management and building organizations. Kirsch was also involved in the early efforts to reform health care during the Clinton years as the head of the New York State Health Care Campaign. The experience taught him some valuable lessons that he would use in 2008: the need to take advantage of a new president’s short honeymoon phase and let Congress work out the details, rather than having the President present a fully baked plan. ',
                'Margarida Jorge, a seasoned organizer who had worked with most of the major national unions, was tapped for the Field Director position, a critical role in a field-heavy campaign. Jorge received strong praise from state partners who worked with her for her willingness to listen to those on the ground and teach unseasoned activists. She helped partners learn how to think strategically, direct a statewide coalition, build a base of supporters, and organize events and actions.',
                'In any other hands, the sprawling national HCAN coalition might have proved unwieldy, and competition between groups could have derailed their efforts. However, instead of relying on top-down leadership, Kirsh and Jorge emphasized building trust among the groups. By keeping them informed, partners were able to see the big picture and understand the reasoning behind decisions. Jorge also connected organizers across different states, which led to a culture of sharing best practices. Under her leadership, the competition between groups was friendly, not divisive.'
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/healthcare'
    
            }
          ],
          contentLinks: {
            "[\"Vote Leave\"]": {
              'link': '/campaigns/brexit',
              'text': '"Vote Leave"',
              'new': false
            },
            "[Health Care for America Now]" : {
              'link': '/campaigns/healthcare',
              'text': 'Health Care for America Now',
              'new': false
            }
          },
        }, // End Leadership

        '/components/structure': {
          id: 0,
          title: 'Structure',
          color: 'blue',
          textColor: 'white',
          cardDescription: 'Organizational structure isn’t the flashiest of campaign components but, when done right, can be the key to success.',
          backgroundImage: structureBackgroundImage,
          content: [
            'Organizational structure isn’t the flashiest of campaign components, but when done right it can be the key to success. Those that combined strong leadership, a large grassroots network, clear information flow and a feedback loop, and strong mutual trust were particularly successful.',
            'While large coalitions can enjoy both power and capacity, they also can be unwieldy. Factions can form and information can become distorted as it travels around the various parts of the network. When organized well, however, coalitions can be unstoppable forces that make use of “people power” and can spread a message widely. Clear communication from the top and buy-in on strategy at all levels are essential features of success. An intentional effort to build trust between campaign leaders and on-the-ground activists can help ensure that everyone understands the reasoning behind decisions and sees the big picture. Allowing local groups to build distinct identities and assemble a wide base of grassroots supporters assists in spreading the campaign’s messages to a wider audience.',
            'The strong lines of communication between [Health Care for America Now]\'s leaders and its grassroots activists and mutual trust helped the coalition work effectively and avoid the splintering of groups. The [Net Neutrality] coalition played to each member’s strengths, divided and conquered, and worked together to expand their reach and effectiveness.'
          ],
          sideContent: [
            {
              id: 0,
              title: 'Health Care',
              content: [
              'There’s a reason why foundations tend to shy away from organizing work, compared to more communications-based advocacy. In the hands of most people, HCAN could have become an unwieldy, logistical nightmare.The rough hierarchy was as follows:', 
              'The Steering Committee -> Campaign Manager -> Executive Director -> Field Director -> Field Managers(15) -> Contracted Affiliates within States',
              'HCAN was not the first campaign to attempt this model, but it was the first to do it well. HCAN’s coalition efforts were successful because they obtained and maintained buy-in from both partners and members. The coalition delivered weekly updates at the grassroots level to build trust with transparency. The deliberate efforts permitted everyone involved to understand the reasoning behind the coalition’s decisions and the tradeoffs involved. Enabling people to see the big picture helped ensure that they would work together, even in the face of obstacles that could have turned divisive. Organizers across different states checked in with each other regularly and supported a culture where best practices were shared. The calls were results-oriented and even led to friendly competition to see who could get more turnout at events.',
              'This strong coalition and an integrated field campaign allowed HCAN to react rapidly and appropriately to unforeseen circumstances. For example, the August 2010 public “town hall” meetings were disrupted by fired up Tea Party protesters. The Tea Party could have potentially derailed reform efforts by terrorizing elected officials with their tactics. Instead, in a matter of days, HCAN and its allies were able to out-organize the [Tea Party] where it counted: the districts of vulnerable Democrats. Their ability to quickly organize to outnumber the Tea Party members provided cover for supportive Democrats and ensured that they didn’t lose their support.'
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/healthcare'
            },
            {
              id: 1,
              title: 'Net Neutrality',
              content: [
                '“Team Internet,” a coalition formed by Fight for the Future (FFTF), Free Press Action Fund, and Demand Progress, worked together and made the most of each partner’s strengths. The coalition took a decentralized approach to decision-making with each organization leading their own campaign that fell under the overarching goal of protecting net neutrality.',
                'Free Press Action Fund was created by lobby experts in 2003 to give people a voice in key decisions that shape the internet and brought issue expertise. Demand Progress and FFTF formed in late 2010 to early 2011 and organized an alliance-building campaign and internet-wide strike against the web censorship bill in Congress known as the Stop Online Piracy Act (SOPA).',
                'Free Press Action Fund handled the press, media relations, and public protests while FFTF led the mass online mobilization efforts. Because leading members worked in tandem, the groups were agile and flexible. “We were consensus based. When our organization wanted to go a certain way to address some new challenge, we would change our tactics and just give the others a heads up,” said Evan Greer, Executive Director of Fight for the Future.',
                'Demand Progress’ leaders led campaigning efforts and guided the coalition to narrow their focus on mobilizing key voices to influence and pressure representatives who could be swayed if they understood their seats were at risk.',
                'Every group that came to the table excelled at their specialization whether lobbying, policy construction, or online mobilization - creating a strong network and collaborative spirit.' 
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/net-neutrality'
            }
          ],
          contentLinks: {
            "[Health Care for America Now]": {
              'link': '/campaigns/healthcare',
              'text': 'Health Care for America Now',
              'new': false
            },
            "[Net Neutrality]": {
              'link': '/campaigns/net-neutrality',
              'text': 'Net Neutrality',
              'new': false
            },
            "[Tea Party]": {
              'link': '/campaigns/tea-party',
              'text': 'Tea Party',
              'new': false
            }  
          },
        }, // End Health Care

        '/components/timing': {
          id: 0,
          title: 'Timing',
          color: 'green',
          textColor: 'white',
          cardDescription: 'Recognizing a moment of opportunity and being ready to act can lead to success.',
          backgroundImage: timingBackgroundImage,
          content:[
            'The ancient philosopher Seneca once mused that “luck is where preparation meets opportunity.” Both the [Tea Party] and [soda tax campaigns] show how recognizing a moment of opportunity and being ready to act can lead to success. Both campaigns capitalized on opportunities created by political turbulence and harnessed sentiments around changing economic norms or growing social anxiety.',
            'According to [[political opportunity theory]], there are certain elements that create a window of opportunity for social movements to succeed. Events and social processes such as wars, demographic changes, and structural changes can weaken political systems, allowing for organizers and activists to capitalize on uncertainty. Other vulnerabilities such as protests, where large groups participate in increasingly confrontational tactics, can serve as catalysts for changemaking.',
            'Both the Tea Party and the soda tax campaigns took advantage of political opportunities as social and cultural norms shifted. Leaders of both movements had tried - and failed - for years to get traction on their issues before their moment came. When it did, they took it and ran.'
          ],
          sideContent: [
            {
              id: 0,
              title: 'Tea Party',
              content: [
              'Timing was crucial to the runway that lifted the Tea Party into national prominence. In 2008 and 2009, the Republicans were the “out” party in Washington, having suffered election crushing losses that saw their opponents control the White House and both chambers of Congress. The Tea Party saw a political opportunity in the national anger brewing from the financial crisis, corporate bailouts, as well as those who saw the election of an African-American president confirming their fears about the decline of white America. They took advantage of a gap in the political discourse between progressive Democrats and mainstream Republicans and effectively drove a wedge into the Republican party in order to build leverage for their own political agenda.',
              'The traditional origin story of the Tea Party is fairly well known. In February 2009, CNBC host Rick Santelli yelled “I\'m mad as hell and I\'m not going to take it anymore" on the floor of the Chicago Mercantile Exchange and called for a Tea Party protest against the government’s funneling of trillions of dollars to bail out private industries. But before the Tea Party broke through the public consciousness, its roots had grown underground for more than a decade.',
              'The groundwork for what would become the Tea Party had been painstakingly laid over decades by energy industry billionaires Charles and David Koch, who aimed to take over a major political party and remake it with Libertarian, free-market principles at the core.  In 1984, the brothers launched Citizens for a Sound Economy (CSE) as a grassroots political organization pushing Libertarian causes. CSE formed an early alliance with two of the biggest tobacco companies to jointly fund and organize local groups to fight taxes and regulations gains the tobacco industry. This network was an early testing  ground for the messaging and grassroots organizing tactics used by the Tea Party. By 2009, this grassroots network was primed and ready when Barack Obama moved into the White House and gave fed up conservatives a common enemy.'
             ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/tea-party'
            },
            {
              id: 1,
              title: 'Soda Tax',
              content: [
                'The victory of the “Yes on D” soda tax campaign in Berkeley, CA followed a series of events that paved the way for success. The first was the relative maturation of the movement to reduce consumption of sugar-sweetened beverages. By the time Berkeley residents headed to the polls in 2014, dozens of soda taxes had been proposed and soundly defeated at every level of government. The American Beverage Association (ABA) was used to winning. Before Berkeley’s success, the ABA boasted that it had defeated no fewer than 40 such efforts. These losses, however, were instructive for soda tax proponents as they thought about how to win in Berkeley.',
                'First, the beverage industry had been forced to show its cards in multiple fights across the country. But what the ABA saw as a playbook on how to beat soda taxes ended up being used against them. Grassroots advocates in Berkeley noticed patterns and recognized that the beverage industry had adopted tactics used by the tobacco industry and began to fight back.',
                'The second was a 2012 decision by Bloomberg Philanthropies decision to begin funding soda tax campaigns, including support of a successful nationwide soda tax in Mexico. That was seen as a huge victory and emboldened Berkeley to put the soda tax on the ballot and gave them critical financial support. The “Yes on D” campaign also took advantage of cuts to funding for the California Nutrition Network, a program aimed at providing access to healthy food for low-income families and students. This provided a community-wide rallying cry to find alternative funding sources to save the beloved program, with the soda tax positioned as the main alternative, giving the campaign a positive message that activated local parents.',
                'The victories in Berkeley and Mexico opened the floodgates for other cities to push their own soda taxes. Six cities, including three Bay Area neighbors, followed in Berkeley’s footsteps.'
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/soda-tax'
            }
          ],
          contentLinks: {
            "[Tea Party]": {
              'link': '/campaigns/tea-party',
              'text': 'Tea Party',
              'new': false
            },
            "[soda tax campaigns]": {
              'link': '/campaigns/soda-tax',
              'text': 'soda tax campaigns',
              'new': false
            }
          },
          contentHighlights: {
            "[[political opportunity theory]]": "politicalOpportunityTheory",
          },
        }, // End Timing

        '/components/framing': {
          id: 0,
          title: 'Framing',
          color: 'green',
          textColor: 'white',
          cardDescription: [
            'Framing is how we understand the world & our place in it. It puts thoughts into context & puts elements together into a cohesive narrative.'
          ],
          backgroundImage: framingBackgroundImage,
          content: [
           'Framing, simply put, is how we understand the world and our place in it. [[Framing]] is the act of communicating about an event or issue by presenting it in a specific cultural context that seeks to make people think about it differently and become motivated to act. Good framing identifies a problem, suggests a solution, and motivates people to demand change. Effective framing also will identify the “good guys” and the villains and will appeal to emotion, logic, or both. Compelling framing can inspire a complete change in outlook, as it did in the case of the [soda tax] by shifting the blame from individual choice (obesity is a result of poor self-control) to poor public policy (obesity is driven by the uncontrolled availability and marketing of sugar-laden beverages).',
           'Framing an issue broadly and connecting it with other issues is also key to attracting more allies and supporters. The Executive Director of [Health Care for America Now], Richard Kirsch, wrote that had the health care reformers expanded their frame to include more than the healthcare system to tell a larger story about the broader attack on the American middle class and unequal distribution of wealth, they might have won the support of more Americans.',
           'Both “Vote Leave” [(Brexit)] and the [Tea Party] movement created compelling frames by painting clear villains and promoting understandable messages that resonated with their target audiences and inspired them to take action.'
          ],
          sideContent: [
            {
              id: 0,
              title: 'Tea Party',
              content: [
                'One of the main ingredients for the Tea Party’s success was their relentless message discipline. The movement had a highly effective [[message frame]] that allowed them to couch a wide variety of issues – from immigration and abortion to health care and the debt ceiling – under the same umbrella of ‘Freedom + Patriotism + Liberty’. This message framework enabled the Tea Party to place individuals and policies into very clearly defined “friend or foe” categories, which appealed to supporters’ core emotions and drove high levels of citizen engagement.',
                'These narratives were not developed by accident. The core themes of freedom, patriotism, and liberty had been used for decades by the anti-tax groups funded by tobacco companies, the precursor to the Tea Party, to fight all sorts of taxes and regulations that impacted the tobacco industry.',
                'In 2009, the “foes” were rising taxes and anyone perceived as draining America’s resources – young people, immigrants, and anyone under retirement age benefiting from government social safety nets. Tea Party activists themselves used emotionally charged terms like “freeloaders,” “moochers,” and “take our country back” in order to frame every issue under this umbrella.',
                'While the frame was simple, it still allowed for a wide breadth of issues to fall under it and allowed the Tea Party to mobilize against various initiatives including healthcare reform, government bailouts, rising debt, and immigration. The sharply defined “with us or against us” attitude made the Tea Party activists feel a part of something big and important, and deeply attached and committed to the movement.'
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/tea-party'
            },
            {
              id: 1,
              title: 'Brexit',
              content: [
                'The "Vote Leave" campaign in the UK channeled anti-establishment sentiment throughout Britain by framing the European Union as the enemy of independence and control. In 2016, populism and nationalism were on the rise globally, nativist fears were boiling to the surface, and many British citizens distrusted the European Union. Vote Leave convinced voters that leaving the EU would allow them to take back control of Britain to get the country back on track and return it to its glorious past.',
                'Vote Leave [[not only framed]], but explicitly named, global forces as the enemy of British citizens -- the reason behind their economic uncertainty -- and argued that leaving the EU and taking back control was the only solution to the problem. This was clearly expressed through their campaign slogan “Vote Leave. Take Control,” which explicitly articulated both the call to action and the campaign’s solution, while implicitly pointing to the problem: a lack of control. This demonstrates the power in having a foil against which you can rally. A clear adversary creates tension in framing, and in turn bolsters a compelling narrative.',
                'The slogan was a two-pronged attack, appealing to emotions, fears, and uncertainties, while also putting forward a seemingly logical answer. These arguments were framed as being not just possible but imperative, connecting deeply with voters’ livelihoods and dignity.'
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/brexit'
            }
          ],
          contentLinks: {
            "[soda tax]": {
              'link': '/campaigns/soda-tax',
              'text': 'soda tax',
              'new': false
            },
            "[Health Care for America Now]": {
              'link': '/campaigns/healthcare',
              'text': 'Health Care for America Now',
              'new': false
            },
            "[(Brexit)]": {
              'link': '/campaigns/brexit',
              'text': 'Brexit',
              'new': false
            },
            "[Tea Party]": {
              'link': '/campaigns/tea-party',
              'text': 'Tea Party',
              'new': false
            },
            "\"Vote Leave\"": {
              'link': '/campaigns/brexit',
              'text': '"Vote Leave"',
              'new': false
            }
          },
          contentHighlights: {
            "[[Framing]]": "framingTheory",
            "[[message frame]]": "framingTheory",
            "[[not only framed]]": "framingTheory",
          },
        }, // End Framing

        '/components/adaptability': {
          id: 0,
          title: 'Adaptability',
          color: 'red',
          textColor: 'white',
          cardDescription: 'Campaigns never exist in a vacuum, & even the best-planned campaigns have to deal with unexpected challenges & changing environments.',
          backgroundImage: adaptabilityBackgroundImage,
          content: [
            'Campaigns never exist in a vacuum and even the best-planned campaigns have to deal with unexpected challenges and changing environments. How campaigns handle the unexpected - pivoting strategy and taking advantage of new opportunities - can mean the difference between success and failure. Shifts that affect campaigns can be big or small. An election can flip the levers of power from one party to another, public opinion can shift, or the opposition can try a new line of messaging that’s starting to stick.',
            'One of the biggest factors in a campaign’s ability to adapt is whether they have the willingness and the method to receive information. Campaigns that do a lot of listening, put ego aside, and are willing to try new things can overcome a changing environment.',
            'In the case of [Brexit\'s "Vote Leave" campaign], the issue of immigration wasn’t part of the initial message. The campaign soon recognized that it had an opening to engage a broader audience by tying their campaign to national conversations on immigration. The ability to recalibrate and pivot the messaging helped propel them to victory. In contrast, the leaders of the ["Yes on 2"] charter school campaign in Massachusetts failed to change their strategy and tactics, despite warning signs that they were going to lose the referendum. They overly relied on initial polling data that showed that they would easily win and didn’t respond to powerful attacks from their opponents.'
          ],
          sideContent: [
            {
              id: 0,
              title: 'Charter Cap',
              content: [
                'When charter advocates began studying how to conduct their campaign to raise the cap on charter schools, they hired a ballot consulting firm that concluded that the measure would likely pass.',
                'The leadership chose to base their messaging on the overall public support for charter schools – about two thirds of Americans say they support school choice -- and to push the message that all students should have the opportunity to attend a high quality school of their choice.',
                'They placed too much confidence in initial polling, without accounting for changes that would happen along the way. They weren’t prepared for the success of opposition messaging that effectively painted advocates as highly partisan Republicans in a political climate that favored Democrats.',
                'Three months before the election, 36% of Democrats and 52% of independents thought charter schools helped the state’s education system. By election day in November, the public became more divided with two polarizing candidates on the ballot – Donald Trump and Hillary Clinton.',
                'While “Yes on 2” relied mainly on TV ads and never varied their message, opponents of the measure slammed them with a combination of digital ads, more effective messaging and messengers, and grassroots mobilization through the state teacher’s union. Even when it was clear these attacks were working, the advocates declined to change their strategy, revise their messaging, get new messengers, or build a grassroots coalition. Campaign leaders stuck to the initial plan, putting them in a very weak position on election night. With nothing to refute opposition messaging – and effective attacks from Democrats and progressives – the campaign saw Democratic support bottom out. In November, 73% of Democrats voted no, compared to the 42% who opposed the measure in the August polls.'
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/charter-cap'
            },
            {
              id: 1,
              title: 'Brexit',
              content: [
                'While Vote Leave’s core message of taking back control was firm, leader Dominic Cummings proved agile and willing to adapt tactics and the applications of the message. The core of it was to hammer home taking back control, while being able to adjust to societal forces and realities. As he sums up himself, instead of trying to “eradicate” errors, he believed it was more important to ask “how quickly errors are adapted to and learned from.”',
                'Cummings initially believed that using immigration as a key message would turn off undecided voters, but changed course when he understood that immigration was an extremely salient issue to his target audience.',
                'Additionally, with just a few months left before the vote, Vote Leave’s digital operation was failing. At odds with fellow Leavers and with donors growing wary, Cummings made key decisions to transform the campaign. He appointed a new digital director, hired social media experts who had just run Ted Cruz’s primary bid, and hired three American academics to do data analysis and modelling.  Finally, he reserved much of their £7 million budget to spend in the final weeks on a web-based GOTV effort.'
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/brexit'
            }
          ],
          contentLinks: {
            "[Brexit's \"Vote Leave\" campaign]": {
              'link': '/campaigns/brexit',
              'text': 'Brexit\'s "Vote Leave" campaign',
              'new': false
            },
            "[\"Yes on 2\"]": {
              'link': '/campaigns/charter-cap',
              'text': '"Yes on 2"',
              'new': false
            }
          },
        }, // End Adaptability

        '/components/power': {
          id: 0,
          title: 'Power',
          color: 'yellow',
          textColor: 'black',
          cardDescription: 'Resources allow campaigns to transform bystanders into adherents, then constituents, then activists.',
          backgroundImage: powerBackgroundImage,
          content: [
            '[[Access to resources]], both human and material, can make or break campaigns. Examples include sufficient funding, extensive mobilization capacity, skilled and experienced leaders, inside champions, strong allies, and the ability to attract public attention. Resources allow campaigns to transform bystanders into adherents, then constituents, then activists. The more resources a campaign has, the more likely they are to achieve their goals. But being strong in one resource area can make up for weaknesses in others. A campaign that lacks funds can still be successful if they have access to volunteers willing to give their time and labor to the movement.',
            'While both the Fight for $15 and the campaign for Net Neutrality were outspent by their opponents, they wielded incredible people power. Both campaigns had a strong mobilizing capacity, aiding their ability to create large and committed coalitions of supporters. What sustained the Fight for $15 movement and led to their ultimate victory was a large base of diverse labor activists with unique mobilizing abilities. ‘Team Internet’, the coalition fighting for net neutrality also mobilized large groups of people and harnessed the skills of their activists. A committed and digitally-savvy core group of activists leveraged the wide-reach of the internet to disseminate information about the issue and galvanized previously passive internet users to fight for the cause.'
          ],
          sideContent: [
            {
              id: 0,
              title: 'Fight for $15',
              content: [
                'The Fight for $15 campaign succeeded largely due to the unprecedented merging of coalitions across different industries, harnessing people power to generate an innovative and never-before-seen 21st-century labor movement. The leaders of Fight for $15 recognized the need for a movement made up of as many low-wage workers as possible committed to a clear set of demands, regardless of whether they were affiliated with a union or not.',
                'The campaign counted on the support of unions, community groups, and religious organizations -- all allies that participated in the campaign’s organized strikes and rallies. The Fight for $15 campaign mobilized a coalition of underpaid workers frustrated by economic inequality in cities all over the country. Fast-food workers, big box chain workers, janitors, and other low-wage employees came together to demand better pay. The fast food campaigns connected local workers with experienced union and community organizers. The walkouts by fast food workers were “the largest single set of mobilizations by low-wage workers in contemporary history.”',
                'This campaign exemplified a new type of labor organizing with the melding of traditional union activity and grassroots participation from non-union activists. It is important to note that money from large unions like the Service Employees International Union, the coalition’s main backer, was a key material resource but their victory was due to strong mobilization and cross-sector partnerships.'  
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/fight-for-15'
            },
            {
              id: 1,
              title: 'Net Neutrality',
              content: [
                'Team Internet, a coalition that included Fight for the Future, Demand Progress, and Free Press Action Fund, used digital tools to bring together diverse coalitions across political lines and help people easily transition from bystanders to campaign participants. They chose a digitally native target audience and moved them up the engagement ladder by meeting them where they are and tapping into their skills and strengths. The campaign often posted about Net Neutrality on popular online forums such as Reddit, answering common questions and debunking myths. These tactics helped recruit new members and equipped constituents with the knowledge they needed to become validators on other websites or offline in their communities.',
                'Because a fair and free internet is existential to many online companies - Team Internet had high profile allies who rallied to the cause. Sites like WordPress, Vimeo, Etsy, Upworthy, and Reddit displayed “slow-loading” symbols on their front pages to show their support of net neutrality. While pages didn’t actually load more slowly, they symbolized warnings of what the internet could look like if ISPs were allowed to discriminate between different kinds of content. This kind of high-profile activism didn’t cost the campaign money and had a huge effect on the visibility and public’s understanding of what net neutrality is.',
                'Once people had a deeper understanding on why net neutrality was important, Battle for the Net leveraged the minimal commitment required of individuals for online participation and parlayed wide scale participation into a large-scale impact by encouraging people to change their social media avatars, creating shareable videos about net neutrality, and posting banner ads to websites and blogs.'
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/net-neutrality'
            }
          ],
          contentLinks: [false],
          contentHighlights: {
            "[[Access to resources]]": "resourceMobilizationTheory",
          },
        }, // End Power

        '/components/pressure': {
          id: 0,
          title: 'Pressure',
          color: 'purple',
          textColor: 'white',
          cardDescription: 'Savvy campaigns generate pressure and sustain momentum through smart tactics that remind key audiences of what is at stake.',
          backgroundImage: pressureBackgroundImage,
          content: [
            'Savvy campaigns generate pressure and sustain momentum through smart tactics that remind key audiences of what is at stake. Whether targeting corporate CEOs through protests or flooding lawmakers offices with letters -- successful campaigns are unrelenting when it comes to applying constant pressure, both online and offline, to decision-makers who need to be pushed. Often, tactics that generate pressure do so by gaining media attention and forcing high profile decision makers to take a stance. Campaigns earn media attention through orchestrated unique events, large protests, demonstrations, or acts of civil disobedience. Pressure can also be applied online through social media advocacy including tactics such as encouraging people to change a profile picture or collecting signatures.',
            'In the case of the [Keystone XL] pipeline, activists were persistent in generating awareness to make it known to key elected officials that this was a topic that constituents were passionate about. Mass protests in D.C. and sustained in-person events made the issue front and center and forced lawmakers to pay attention.',
            'The [Fight for $15] campaign used unusual tactics like one-day strikes and civil disobedience arrests to gain media attention and used a series of small wins to build momentum and buoy the movement.'
          ],
          sideContent: [
            {
              id: 0,
              title: 'Keystone',
              content: [
                'Few campaigns are able to generate and sustain pressure over long periods of time as successfully as the coalition opposing the Keystone XL project. Because the bigger goal was to build a movement, the key strategists created a tone that invited participation and embraced more of an “open-source” organizing model that allowed for improvisation. A senior member of the 350.org team described the realization as follows: “We learned from the civil rights and LGBT groups [who] were beginning to make headway pressuring the Administration. Environmentalists were probably the last ones to learn that to make progress on this Administration, you had to push them hard.”',
                'And so they pushed. Beginning in 2011, climate advocates from Indigenous Tribes began organizing tribal councils to pass resolutions opposing Keystone XL in communities across the country. Those local resolutions were eventually delivered to President Obama at a tribal leaders summit. This reinforced the human impacts of the proposed pipeline, particularly for communities deeply connected to the land. By August, the environmental organization 350.org launched the first significant public action in protest of the pipeline in Washington D.C., a typically dead time in the muggy capital. Over a two-week period, 1,253 people were arrested after sitting down in a public right of way in front of the White House. The action drew large-scale national media attention to the project for the first time, and shifted the focus away from the State Department, and its bureaucratic responsibility for the permitting process, and put the spotlight on one individual: President Obama.  Importantly, the actions at the White House weren’t taken only by young climate activists, but also included stakeholders along the proposed pipeline route, such as ranchers and farmers who had never been to DC before, reframing the idea of who a ‘protester’ typically is.',
                'With his re-election campaign in full swing, Obama was now being met at every campaign stop with climate activists opposing Keystone. His field offices were constantly hearing from local climate activists and he was getting an earful from environmentally-aligned donors. When Republicans tried to force pro-Keystone legislation through Congress, the campaign sent over 800,000 messages in opposition to the Senate in just 24 hours.',
                'By the end of 2015, Keystone became synonymous with the Republican Party as oil and gas prices dropped. Also by this time, high profile supporters like the Dalai Lama, Nobel laureates, musicians and actors were joining the effort to keep the issue in the news and bring in new supporters.'
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/keystone'
            },
            {
              id: 1,
              title: 'Fight for $15',
              content: [
                'Fight for $15 used tactics that differed from traditional union organizing campaigns. Instead of open-ended strikes that are riskier and require more commitment from participants, the campaign used one-day strikes. This approach was more effective and better for workers because employers had to allow strikers to return to work the next day. Additionally, labor attorney Robert M. Schwartz argues that one-day strikes send a strong message to management and the public, and dramatizes workers anger and determination.',
                'One-day strikes also attracted media attention, which helped reposition the $15 per hour minimum wage from an extreme demand to a reasonable and attainable goal. The media also shared workers’ stories with the public, personalizing the Fight for $15 campaign and recognizing workers for their activism.',
                'Fight for $15’s leadership also didn’t shy away from using other methods of civil disobedience, a bold and unusual approach for workers’ movements, as a way to generate pressure.  An estimated 900 to 1,000 workers have been arrested nationally over the course of the campaign.',
                'While the federal government hasn’t yet raised the minimum wage, there have been many high profile wins in cities including San Francisco, Los Angeles and Seattle that have buoyed the movement and kept the campaign in the spotlight.'  
              ],
              buttonTitle: 'Go To Campaign',
              buttonLink: '/campaigns/fight-for-15'
            }
          ],
          contentLinks: {
            "[Keystone XL]": {
              'link': '/campaigns/keystone',
              'text': 'Keystone XL',
              'new': false
            },
            "[Fight for $15]": {
              'link': '/campaigns/fight-for-15',
              'text': 'Fight for $15',
              'new': false
            }
          },
        } // End Pressure

    },
} // End Rally Components Content

export const RALLY_COMPONENTS = data.RallyComponents;